import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Switch: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {
    md: {
      track: {
        w: '36px',
        h: '20px',
        px: '4px',
        alignItems: 'center',
      },
      thumb: {
        w: '18px',
        h: '18px',
        mb: '1px',
        _checked: {
          transform: 'translateX(18px)',
        },
      },
    },
  },
};

export default Switch;
