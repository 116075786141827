import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { ViewRole, ScreenInfo } from '@/pages/CentralMain/types';

import { withStorageDOMEvents } from '../utils/withStorageDOMEvents';

type State = {
  [key in ViewRole]: ScreenInfo | null;
};

interface Actions {
  registerScreen: (viewRole: ViewRole, info: Partial<ScreenInfo>) => void;
}

const initialState: State = {
  main: null,
  viewOnly: null,
};

const useScreenInfoStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        registerScreen: (viewRole, info) => {
          set((state) => ({ ...state, [viewRole]: { ...state[viewRole], ...info } }));
        },
      }),
      { name: 'view_screen_store', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useScreenInfoStore);
export default useScreenInfoStore;
