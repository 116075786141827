export function decreaseSaturation(colorCode: string, ratio: number) {
  // 색상코드를 RGB 값으로 변환
  const r = parseInt(colorCode.slice(1, 3), 16);
  const g = parseInt(colorCode.slice(3, 5), 16);
  const b = parseInt(colorCode.slice(5, 7), 16);

  // 각 색상 요소에 대해 채도를 감소시킴
  const mixedR = Math.round(r * ratio);
  const mixedG = Math.round(g * ratio);
  const mixedB = Math.round(b * ratio);

  // RGB 값을 HEX 문자열로 변환
  const mixedColorCode = `#${mixedR.toString(16).padStart(2, '0')}${mixedG.toString(16).padStart(2, '0')}${mixedB
    .toString(16)
    .padStart(2, '0')}`;

  return mixedColorCode;
}
