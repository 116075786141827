import { addMinutes, isAfter, isBefore, isEqual, parseISO, toDate } from 'date-fns';

export const getTimeDifferenceFromNow = (baseTime: Date) => {
  const now = new Date();
  const baseDate = new Date(baseTime);
  return baseDate.getTime() - now.getTime();
};

const UTC0_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export const parseUTC0String = (utc0String: string) => {
  if (!utc0String.match(UTC0_REGEX)) {
    throw new Error('Invalid DateString from server');
  }
  const date = parseISO(utc0String);
  return date;
};

export const ceilSeconds = (date: Date): Date => {
  const original = toDate(date);
  if (original.getSeconds() === 0) {
    return original;
  }
  const result = addMinutes(original, 1).setSeconds(0, 0);
  return toDate(result);
};

export const floorSeconds = (date: Date): Date => {
  const original = toDate(date);
  if (original.getSeconds() === 0) {
    return original;
  }
  const result = original.setSeconds(0, 0);
  return toDate(result);
};

export const sliceUtc0StringToMinute = (utc0: string): string => {
  return utc0.slice(0, 17);
};

export const isEqualByMinutes = (left: Date, right: Date) => {
  const minuteInMiliSec = 60 * 1000;
  return Math.floor(left.getTime() / minuteInMiliSec) === Math.floor(right.getTime() / minuteInMiliSec);
};
export const isEqualBySeconds = (left: Date, right: Date) => {
  const minuteInMiliSec = 1000;
  return Math.floor(left.getTime() / minuteInMiliSec) === Math.floor(right.getTime() / minuteInMiliSec);
};

export const isEqualBefore = (dateLeft: number | Date, dateRight: number | Date) => {
  return isEqual(dateLeft, dateRight) || isBefore(dateLeft, dateRight);
};
export const isEqualAfter = (dateLeft: number | Date, dateRight: number | Date) => {
  return isEqual(dateLeft, dateRight) || isAfter(dateLeft, dateRight);
};

export const isInPeriod = (date: number | Date, { from, to }: { from: number | Date; to: number | Date }) => {
  return isEqualAfter(date, from) && isEqualBefore(date, to);
};
