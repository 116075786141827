import { useCallback, useMemo, useContext, useEffect } from 'react';

import { shallow } from 'zustand/shallow';

import { WindowContext } from '@/contexts/dualMonitorProvider';
import { useDualMonitorStore } from '@/contexts/dualMonitorProvider/useDualMonitorStore';
import useResetStore from '@/contexts/resetStore/useResetStore';
import useViewControlStore from '@/contexts/viewControlStore/useViewControlStore';
import useViewIdStore from '@/contexts/viewControlStore/useViewIdStore';
import useViewSessionStore from '@/contexts/viewControlStore/useViewSessionStore';
import { routes } from '@/router/routes';

import useCurrentViewInfo from './useCurrentViewInfo';
import useUserScreenDetails from './useUserScreenDetails';
import { ViewRole, VIEW_ROLES } from '../types';

const useDualMonitor = () => {
  const context = useContext(WindowContext);

  if (!context) {
    throw new Error('useWindowOpener must be used within a WindowProvider');
  }

  const { windowInstance, setWindowInstance, closeWindow } = context;

  const { viewData, registerLastWindowSetting } = useViewControlStore(
    (state) => ({
      viewData: state.viewData,
      lastWindowSetting: state.lastWindowSetting,
      registerLastWindowSetting: state.registerLastWindowSetting,
    }),
    shallow,
  );
  const deregistViewId = useViewIdStore((state) => state.deregistViewId, shallow);

  const { viewRole } = useCurrentViewInfo();
  const { getLatestSavedWindowPosition } = useUserScreenDetails();

  const isDualMonitor = useDualMonitorStore((state) => state.isDualMonitor, shallow);
  // // (1) monitor status
  // const [isDualMonitor, setTemp] = useState(windowInstance?.closed === false || false);
  // useInterval(() => {
  //   setTemp(windowInstance?.closed === false || false);
  // }, 500);
  // // (2) update dual monitor status
  // const { isDualMonitor, setIsDualMonitor } = useDualMonitorStore();
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const newStatus = windowInstance?.closed === false || false;
  //     if (isDualMonitor !== newStatus) {
  //       setIsDualMonitor(newStatus);
  //     }
  //   }, 500);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [isDualMonitor, setIsDualMonitor, windowInstance?.closed]);

  const { viewId } = useViewSessionStore();
  const { resetViewId } = useResetStore(
    (state) => ({
      resetViewId: state.resetViewId,
    }),
    shallow,
  );

  // close viewOnly window
  const closeDualMonitor = useCallback(() => {
    closeWindow();
  }, [closeWindow]);

  // close window => deregister window
  const deleteView = useCallback(
    (viewRole: ViewRole | null, isDualMonitor: boolean) => {
      if (!viewRole) return;
      if (viewRole !== 'main') return;

      registerLastWindowSetting({ main: 'open', viewOnly: isDualMonitor ? 'open' : 'close' });
      if (isDualMonitor) {
        closeDualMonitor();
      }
      if (resetViewId !== null && resetViewId !== viewId) {
        // note: 계속 진행하기 당한 화면인 경우
        return;
      }
      deregistViewId();
    },
    [registerLastWindowSetting, resetViewId, viewId, closeDualMonitor, deregistViewId],
  );

  useEffect(() => {
    const handleDeleteView = () => deleteView(viewRole, isDualMonitor);
    window.addEventListener('beforeunload', handleDeleteView);
    return () => {
      window.removeEventListener('beforeunload', handleDeleteView);
    };
  }, [deleteView, isDualMonitor, viewRole]);

  // open viewOnly window
  const openViewOnlyMonitor = useCallback(async () => {
    if (windowInstance && !windowInstance.closed) {
      return undefined;
    }

    const viewOnlyPageRouteInfo = routes.CENTRAL_MAIN.build({ query: { viewRole: VIEW_ROLES.viewOnly } });
    const popupPosition = await getLatestSavedWindowPosition('viewOnly');
    // Amy : 작아지는 부분 수정 (1씩 줄어들게 open 되어서 1 증가해서 열기)
    const updatedStr = popupPosition.replace(/height=(\d+)/, function plus(match, p1) {
      return `height=${parseInt(p1, 10) + 1}`;
    });

    const newWindow = window.open(
      viewOnlyPageRouteInfo.pathname + viewOnlyPageRouteInfo.search,
      // * note: new window
      '_blank',
      // * note: for popup
      updatedStr,
    );
    setWindowInstance(newWindow);
    // registSecondaryView(viewId);

    return undefined;
  }, [getLatestSavedWindowPosition, setWindowInstance, windowInstance]);

  const toggleDualMonitor = useCallback(async () => {
    if (isDualMonitor) {
      closeDualMonitor();
    } else {
      openViewOnlyMonitor();
    }
  }, [closeDualMonitor, isDualMonitor, openViewOnlyMonitor]);

  const viewOnlyDataCount = useMemo(() => viewData.viewOnly.length, [viewData.viewOnly.length]);

  return {
    toggleDualMonitor,
    viewOnlyDataCount,
    openViewOnlyMonitor,
    closeDualMonitor,
    isDualMonitor,
  };
};

export default useDualMonitor;
