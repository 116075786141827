import type { ComponentStyleConfig } from '@chakra-ui/theme';

const FormLabel: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => ({
    mb: 0,
    color: colorMode === 'dark' ? 'white' : 'black',
    fontStyle: 'normal',
  }),
};

export default FormLabel;
