export function generateRoxSimulation() {
  const roxData = [];
  const initialRespiratoryRate = 16; // 초기 호흡 속도
  const initialOxygenSaturation = 98; // 초기 산소 포화도

  // 초기 ROX 값을 계산합니다.
  const initialRox = initialOxygenSaturation / initialRespiratoryRate + 11;

  // 시뮬레이션 시간 설정 (12시간 동안, 1분 간격)
  const simulationDuration = 7 * 60 + 29; // 분 단위로 계산
  const interval = 1; // 1분 간격

  // 초기 ROX 값을 배열에 추가합니다.
  roxData.push(initialRox);

  // ROX 값을 시뮬레이션합니다.
  for (let t = interval; t <= simulationDuration; t += interval) {
    // 호흡 속도와 산소 포화도에 대한 부드러운 변화를 시뮬레이션합니다.
    const respiratoryRate = initialRespiratoryRate + Math.sin(t / 50) * 3; // sin 함수의 매개변수 조정
    const oxygenSaturation = initialOxygenSaturation - Math.cos(t / 40) * 2; // cos 함수의 매개변수 조정

    // ROX 값을 계산하고 배열에 추가합니다.
    const rox = oxygenSaturation / respiratoryRate + t * 0.0001 + 11;
    roxData.push(rox);
  }

  return roxData;
}
