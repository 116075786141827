import { TDevice } from '@/constants/types';
import { WaveKeys, WAVE_KEYS } from '@/pages/CentralMain/types';

import { waveData } from './waveData';
import { waveProperty } from '../wave-property';

function generateWaveSimulation({ wave, startIndex, device }: { wave: WaveKeys; startIndex: number; device: TDevice }) {
  const thisWaveData = waveData[device][wave] || [];
  const samplingRate = Number(waveProperty[device].find((property) => property.signal_name === wave)?.hz) || 1; // 해당 sampling rate
  const currentPosition = (startIndex * samplingRate) % thisWaveData.length;

  // Amy : 잘리는 경우 대비
  const slicedData = thisWaveData.concat(thisWaveData).slice(currentPosition, currentPosition + samplingRate);
  return slicedData;
}
export function makeWaves({ startIndex, device }: { startIndex: number; device: TDevice }) {
  const obj: { [waveKey: string]: number[] } = {};

  WAVE_KEYS.forEach((key) => {
    obj[key] = generateWaveSimulation({ wave: key, startIndex, device });
  });

  return obj;
}
