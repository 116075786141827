import { Mutate, StoreApi } from 'zustand';

type StoreWithPersist = Mutate<StoreApi<any>, [['zustand/persist', any]]>;

export const withStorageDOMEvents = (store: StoreWithPersist) => {
  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      const currentVersion = store.getState()?.version;
      const newVersion = JSON.parse(e.newValue)?.state?.version;

      if ((currentVersion == null && newVersion == null) || currentVersion <= newVersion) {
        if (e.key === 'controller-view-id') {
          console.debug(e.newValue, currentVersion, '->', newVersion);
        }
        store.persist.rehydrate();
      }
    }
  };

  window.addEventListener('storage', storageEventCallback);

  return () => {
    window.removeEventListener('storage', storageEventCallback);
  };
};
