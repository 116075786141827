import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Popover: ComponentStyleConfig = {
  parts: ['content'],
  variants: {
    basic: {
      popover: {
        pos: 'relative',
      },
      arrow: {
        w: '30px !important',
        h: '30px !important',
        left: '-12px !important',
        rounded: '4px',
        bg: 'red',
      },
      content: {
        w: 'auto',
        py: 5,
        px: 6,
        rounded: '24px',
        border: 0,
        shadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        _focus: {
          shadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        },
      },
      header: {
        p: 0,
        mb: 2,
        fontWeight: '700',
        fontSize: 'lg',
        borderBottom: 0,
      },
      body: {
        p: 0,
      },
    },
  },
};

export default Popover;
