import localStorageKeys from './localStorageKeys';

export const appendAuthToken = (jwt: string, refresh: string) => {
  localStorage.setItem(localStorageKeys.accessToken, jwt);
  localStorage.setItem(localStorageKeys.refreshToken, refresh);
};

export const getAuthToken = () => localStorage.getItem(localStorageKeys.accessToken);
export const getRefreshToken = () => localStorage.getItem(localStorageKeys.refreshToken);

export const removeAuthToken = () => {
  localStorage.removeItem(localStorageKeys.accessToken);
  localStorage.removeItem(localStorageKeys.refreshToken);
};
