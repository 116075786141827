import { useEffect, useId, useMemo } from 'react';

import { Box, Text, CircularProgress } from '@chakra-ui/react';
import { addMinutes, differenceInMinutes } from 'date-fns';

import useCustomColorMode from '@/hooks/useCustomColorMode';
import useLanguage from '@/hooks/useLanguage';
import { usePdf } from '@/hooks/usePdf';
import { HeaderData } from '@/reports/common/ReportHeader';
import TrendChartWithTablePage, { DATA_PER_PAGE } from '@/reports/pages/TrendChartWithTablePage';
import { filenameGenerator } from '@/reports/utils/filename';

import Indicator from '../common/Indicator';

interface Props {
  dataId: string;
  from: Date;
  to: Date;
  headerData: HeaderData;
}

const TrendReport = ({ dataId, from, to, headerData }: Props) => {
  const { translate } = useLanguage();
  const { color } = useCustomColorMode();
  const pageId = useId();

  const pageCount = useMemo(() => {
    const result = differenceInMinutes(to, from);
    return Math.ceil(result / DATA_PER_PAGE);
  }, [from, to]);

  const { openFileInNewWindow, isProcessing, isComplete } = usePdf(
    Array.from({ length: pageCount }, (_, index) => `${pageId}-${index.toString()}`),
    { filename: filenameGenerator({ prefix: 'trend_report' }) },
  );

  useEffect(() => {
    const handlePdf = () => {
      if (!isProcessing && !isComplete) {
        openFileInNewWindow();
      }
      if (isComplete) {
        window.close();
      }
    };
    const timer = setTimeout(handlePdf, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isComplete, isProcessing, openFileInNewWindow]);

  return (
    <>
      <Indicator>
        <Text color={color} fontWeight={600}>
          {translate('processPdf')}
        </Text>
        <CircularProgress isIndeterminate color="blue.300" size="100px" />
      </Indicator>
      <Box>
        {Array.from({ length: pageCount }, (_, index) => {
          const startDate = addMinutes(from, index * DATA_PER_PAGE);
          const endDate = addMinutes(from, (index + 1) * DATA_PER_PAGE);
          return (
            <TrendChartWithTablePage
              key={`${pageId}-${index.toString()}`}
              pageId={`${pageId}-${index.toString()}`}
              id={dataId}
              from={startDate}
              page={index + 1}
              to={endDate.getTime() > to.getTime() ? to : endDate}
              headerData={headerData}
            />
          );
        })}
      </Box>
    </>
  );
};

export default TrendReport;
