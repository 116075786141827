import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tabs: ComponentStyleConfig = {
  parts: ['tabpanel', 'tablist', 'tab'],
  baseStyle: {
    tabpanel: {
      padding: 0,
    },
    tablist: {
      fontSize: '12px',
      borderBottom: '2px solid',
      borderColor: 'inherit',
    },
    tab: {
      color: 'gray.700',
      mb: '-2px',
      borderBottom: '2px solid',
      borderColor: 'transparent',
      _selected: {
        borderColor: 'brand.500',
        color: 'brand.500',
      },
      _hover: {
        bg: 'brand.50',
      },
    },
  },
  sizes: {
    md: {
      tab: {
        fontSize: 'sm',
        pb: '12px',
      },
    },
  },
};

export default Tabs;
