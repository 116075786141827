import { Sort } from '../PatientModal/type';

export const CELL_SORTING_OPTION = {
  'sort-disabled': 'sort-disabled',
  'patient-name-asc': 'patient-name-asc',
  'patient-name-desc': 'patient-name-desc',
  'patient-id-asc': 'patient-id-asc',
  'patient-id-desc': 'patient-id-desc',
  'device-alias-asc': 'device-alias-asc',
  'device-alias-desc': 'device-alias-desc',
  'bed-id-asc': 'bed-id-asc',
  'bed-id-desc': 'bed-id-desc',
} as const;

export type CellSortingOption = (typeof CELL_SORTING_OPTION)[keyof typeof CELL_SORTING_OPTION];
export type CellSortingMenu = { value: CellSortingOption; sortingRule: Sort | null };
