import { DEFAULT_PARAM_SETTING } from '@/constants/data';
import { disconnectDeviceKey } from '@/contexts/mockDataStore';

import { DemoSetting } from './type';

export const HYOSUNG_DEFAULT_SETTING: DemoSetting = {
  viewData: {
    main: [
      '01//MP1300//DS00_01',
      '01//MP1300//DS00_13',
      '01//MP1300//DS00_20',
      disconnectDeviceKey,
      '01//MP1300//DS00_25',
      '01//MP1300//DS00_26',
    ],
    viewOnly: [],
  },
  displayLayout: {
    row: 2,
    column: 3,
    cellHeaderSize: 1,
  },
  displayMode: 'wave',
  sortOrder: 'sort-disabled',
  headerTitle: { first: 'deviceAlias', second: undefined, third: undefined },
  paramSetting: {
    ...DEFAULT_PARAM_SETTING,
    MP1300: {
      default: {
        simple: [
          { type: 'params', property: 'hr' },
          { type: 'params', property: 'spo2' },
          { type: 'custom', property: 'nibp' },
        ],
        all: [
          {
            type: 'params',
            property: 'hr',
          },
          {
            type: 'params',
            property: 'spo2',
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'custom',
            property: 'nibp',
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
        ],
        wave: [
          {
            type: 'params',
            property: 'hr',
          },
          {
            type: 'params',
            property: 'spo2',
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'custom',
            property: 'nibp',
          },
          {
            type: 'params',
            property: null,
          },
          {
            type: 'params',
            property: null,
          },
          { type: 'waves', property: 'ecg_wave_ii' },
          { type: 'waves', property: 'spo2_wave' },
          { type: 'waves', property: null },
        ],
      },
    },
  },
  patientInfoSetting: {
    '01//MP1300//DS00_01': {
      bedId: 'ER_01',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_01',
      deviceAlias: 'BED 1',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_01',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//MP1300//DS00_13': {
      bedId: 'ER_13',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_13',
      deviceAlias: 'BED 13',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_13',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//MP1300//DS00_20': {
      bedId: 'ER_20',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_20',
      deviceAlias: 'BED 20',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_20',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//MP1300//DS00_25': {
      bedId: 'ER_25',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_25',
      deviceAlias: 'BED 25',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_25',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//MP1300//DS00_26': {
      bedId: 'ER_26',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_26',
      deviceAlias: 'BED 26',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_26',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//MP1300//DS00_33': {
      bedId: 'ER-33',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'ABC1234',
      deviceAlias: 'BED 33',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: '',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
  },
};
