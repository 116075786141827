import { useCallback, useMemo } from 'react';

import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';

import { WAVE_PARAMS } from '@/constants/data';
import { TLayoutProperty } from '@/constants/types';
import { useSettingStore } from '@/contexts/settingStore/useSettingStore';
import { COLOR_GROUP } from '@/pages/CentralMain/config/colors';
import { decreaseSaturation } from '@/utils/decreaseSaturation';
import mixWithWhite from '@/utils/mixWithWhite';

import meerkatLogoColor from '@images/meerkat_logo.png';
import meerkatLogoWhite from '@images/meerkat_logo_white.png';

export default function useCustomColorMode() {
  const { colorTheme, colorSetting, monoColor } = useSettingStore(
    (state) => ({
      colorTheme: state.colorTheme,
      colorSetting: state.colorSetting,
      monoColor: state.monoColorMode,
    }),
    shallow,
  );

  const { colorMode } = useColorMode();

  const color = decreaseSaturation(colorTheme, 0.9);

  const mixedColor = useCallback(
    (ratio: number) => {
      return mixWithWhite(color, ratio);
    },
    [color],
  );

  const bgGridColor = useCallback(
    (hasAlarm: boolean, ratio: number) => {
      const mixedWithWhite = mixWithWhite(color, ratio);
      if (hasAlarm) return '#324893';
      return mixedWithWhite;
    },
    [color],
  );

  const modalHeader = useColorModeValue('blackAlpha.600', '#5C6870');
  const modalHeaderColor = useMemo(() => {
    return modalHeader;
  }, [modalHeader]);

  const modalBg = useColorModeValue(mixedColor(0.88), '#1e1e1e');
  const modalBgColor = useMemo(() => {
    return modalBg;
  }, [modalBg]);

  const defaultColor = useColorModeValue('#000000', '#FFFFFF');
  const paramColor = useCallback(
    (param: TLayoutProperty) => {
      // Amy : wave 의 경우 set param 이 있으면 그것의 color
      const waveSetParam = param.type === 'waves' && param.property && WAVE_PARAMS[param.property].setParam;
      let property: string | null = waveSetParam || param.property;
      // Amy: group color 가 있는 경우
      const groupProperty = () => {
        let groupKey: string | undefined;
        Object.keys(COLOR_GROUP).forEach((key) => {
          if (COLOR_GROUP[key].params.includes(property as any)) {
            groupKey = key;
          }
        });
        return groupKey;
      };
      property = groupProperty() || property;
      // Amy: color setting 에 해당 파라미터의 color 가 정의 되어 있을 경우
      const colorSettingColor =
        property && Object.prototype.hasOwnProperty.call(colorSetting, property)
          ? colorSetting[`${property}`]
          : undefined;

      let color = monoColor || colorSettingColor || defaultColor;

      if (colorMode === 'light') {
        color = decreaseSaturation(color, 0.9);
      }
      if (param.type === 'waves') color = mixWithWhite(color, 0.2);
      return color;
    },
    [colorMode, colorSetting, defaultColor, monoColor],
  );
  const textColor = useColorModeValue('#000000', '#FFFFFF');
  const hoverColor = useColorModeValue('white', 'gray');
  const bgHeaderColor = useColorModeValue(mixedColor(0.88), '#1E1E1E');
  const tableHeaderBg = useColorModeValue('blackAlpha.300', '#E8EBED');

  const inputBgColor = useColorModeValue('white', '#1e1e1e');
  const inputTextColor = useColorModeValue('black', 'white');

  const meerkatLogo = useColorModeValue(meerkatLogoColor, meerkatLogoWhite);

  return {
    mixedColor,
    bgGridColor,
    modalHeaderColor,
    modalBgColor,
    paramColor,
    color: textColor,
    hoverColor,
    bgHeaderColor,
    meerkatLogo,
    tableHeaderBg,
    input: {
      bg: inputBgColor,
      color: inputTextColor,
    },
  };
}
