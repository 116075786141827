import { useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useDisplayStore } from '@/contexts/displaySettingStore/useDisplayStore';
import useViewControlStore from '@/contexts/viewControlStore/useViewControlStore';
import { ViewRole } from '@/pages/CentralMain/types';
import { routes } from '@/router/routes';

interface CurrentViewInfo {
  viewRole: ViewRole | null;
  viewDataIds: string[];
  anotherViewDataCount: number;
}

const useCurrentViewInfo = (): CurrentViewInfo => {
  const param = useParams();
  const location = useLocation();
  const {
    query: { viewRole },
  } = useMemo(() => {
    return routes.CENTRAL_MAIN.parse(param, location);
  }, [location, param]);

  const currentViewRole = useMemo(() => {
    if (viewRole === 'main') return 'main';
    if (viewRole === 'viewOnly') return 'viewOnly';
    return null;
  }, [viewRole]);

  const { row, column } = useDisplayStore((state) => ({ row: state.row, column: state.column }), shallow);
  const totalNum = useMemo(() => row * column, [column, row]);

  const { viewData } = useViewControlStore((state) => ({ viewData: state.viewData }), shallow);
  const viewDataIds = useMemo(() => {
    if (currentViewRole) return viewData[currentViewRole].slice(0, totalNum);
    return [];
  }, [currentViewRole, totalNum, viewData]);
  const anotherViewDataCount = useMemo(
    () => viewData[currentViewRole === 'main' ? 'viewOnly' : 'main'].length,
    [currentViewRole, viewData],
  );

  return { viewRole: currentViewRole, viewDataIds, anotherViewDataCount };
};

export default useCurrentViewInfo;
