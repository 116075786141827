import { differenceInYears, format, parse } from 'date-fns';

export const parseUTCString = (utcString: string) => {
  const date = parse(utcString.slice(0, 25), 'EEE, dd MMM yyyy HH:mm:ss', new Date());
  return date;
};

export const formatUTCTime = (time: Date) => {
  const utc0String = format(time, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);
  return utc0String;
};

// Amy: api에서 만드는 것과 동일한 형태 (local time 을 api 로 전달할 때)
export const makeLocalTimeToUTC0 = (time: Date) => {
  const utcTimeString = time.toUTCString();
  const utc0Date = parseUTCString(utcTimeString);
  const utc0String = formatUTCTime(utc0Date);

  return utc0String;
};

export const parseTime = (timeString: string) => {
  const time = new Date(timeString);
  return time;
};

export const parseBirthdate = (timeString: string) => {
  const slicedBirthDate = timeString.slice(0, 10);
  const time = parse(slicedBirthDate, 'yyyy-MM-dd', new Date());

  return time;
};

export const formatBirthDate = (time: Date) => {
  const formattedDate = format(time, 'yyyy-MM-dd');
  return formattedDate;
};

export const fullFormat = (time: Date) => {
  const formattedDate = format(time, 'yyyy-MM-dd HH:mm:ss');
  return formattedDate;
};

export const timeFormat = (time: Date) => {
  const formattedDate = format(time, 'yyyy-MM-dd HH:mm');
  return formattedDate;
};

export const calcAge = (birthDateString: string) => {
  return differenceInYears(new Date(), parseBirthdate(birthDateString));
};
