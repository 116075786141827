import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '../utils/withStorageDOMEvents';

type State = {
  controllerViewId: string | null; // current control view id
  version: number;
};

interface Actions {
  registViewId: (viewId: string) => boolean;
  deregistViewId: () => void;
}

const initialState: State = {
  version: Date.now(),
  controllerViewId: null,
};

const useViewIdStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        registViewId: (viewId: string) => {
          const { controllerViewId } = get();
          if (controllerViewId !== null && controllerViewId !== viewId) {
            return false;
          }
          console.debug(`viewId: ${viewId}`);
          set({ controllerViewId: viewId, version: Date.now() });
          return true;
        },
        deregistViewId: () => {
          console.debug(`deregistViewId`);
          set({ version: Date.now(), controllerViewId: null });
        },
      }),
      { name: 'controller-view-id', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useViewIdStore);
export default useViewIdStore;
