export default function checkBrowser() {
  const agent = navigator.userAgent.toUpperCase();
  if (agent.indexOf('TRIDENT') >= 0) {
    return 'IE';
  }
  if (agent.indexOf('FIREFOX') >= 0) {
    return 'FIREFOX';
  }
  if (agent.indexOf('EDG') >= 0) {
    return 'EDGE';
  }
  if (agent.indexOf('SAFARI') >= 0) {
    if (agent.indexOf('CHROME') >= 0) {
      return 'CHROME';
    }
    return 'SAFARI';
  }
  return '';
}
