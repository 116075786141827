import { Data } from '@/pages/CentralMain/types';

import { TDisplayParam } from '../types';

// Amy: param 의 label 과 unit 을 저장. 유니코드 : ₂
export const PARAMS: { [K in keyof Data['params']]: TDisplayParam } = {
  // pm
  arrhythmia: {
    label: 'Arrhythmia',
    unit: '',
  },
  etn2o: {
    label: 'EtN₂O',
    unit: '%',
    min: { min: '0', max: '90', off: { default: '500' } },
    max: { min: '0', max: '90', off: { default: '500' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  etdes: {
    label: 'EtDES',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  etenf: {
    label: 'EtENF',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  etiso: {
    label: 'EtISO',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  etsev: {
    label: 'EtSEV',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  fides: {
    label: 'FiDES',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  fienf: {
    label: 'FiENF',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  fiiso: {
    label: 'FiISO',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  fin2o: {
    label: 'FiN₂O',
    unit: '%',
    min: { min: '0', max: '90', off: { default: '500' } },
    max: { min: '0', max: '90', off: { default: '500' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  fisev: {
    label: 'FiSEV',
    unit: '%',
    min: { min: '0', max: '25', off: { default: '25.4' } },
    max: { min: '0', max: '25', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  ico_bt: {
    label: 'ICO BT',
    unit: '℃',
    min: { min: '10', max: '50', off: { default: '0' } },
    max: { min: '10', max: '50', off: { default: '0' } },
  },
  ico_co: {
    label: 'ICO CO',
    unit: 'LMP',
  },
  ico_paw: {
    label: 'ICO PAW',
    unit: 'mmHg',
  },
  ico_pr: {
    label: 'ICO PR',
    unit: 'BPM',
    maxTextCount: 3,
  },
  pvc: {
    label: 'PVC',
    unit: '',
  },
  fico2: {
    label: 'FiCO₂',
    unit: '%',
    min: { min: '0', max: '10', off: { default: '25.4' } },
    max: { min: '0', max: '10', off: { default: '25.4' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  hr: {
    label: 'HR',
    unit: '/ min',
    min: { min: '30', max: '300', off: { default: '0', MP1000NTP: '15' } },
    max: { min: '30', max: '300', off: { default: '0', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ethal: { label: 'EtHAL', unit: '', decimalPlaces: 1, maxTextCount: 4 },
  fihal: { label: 'FiHAL', unit: '', decimalPlaces: 1, maxTextCount: 4 },
  ibp1_s: {
    label: 'IBP① SYS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp1_m: {
    label: 'IBP① MEAN',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp1_d: {
    label: 'IBP① DIAS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp1_pr: { label: 'IBP① PR', unit: '/ min', maxTextCount: 3 },
  ibp2_s: {
    label: 'IBP② SYS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp2_d: {
    label: 'IBP② DIAS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp2_m: {
    label: 'IBP② MEAN',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '-55' } },
    max: { min: '-40', max: '300', off: { default: '500', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp2_pr: { label: 'IBP② PR', unit: '/ min', maxTextCount: 3 },
  ibp3_s: {
    label: 'IBP③ SYS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp3_m: {
    label: 'IBP③ MEAN',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp3_d: {
    label: 'IBP③ DIAS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp3_pr: { label: 'IBP③ PR', unit: '/ min', maxTextCount: 3 },
  ibp4_s: {
    label: 'IBP④ SYS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp4_m: {
    label: 'IBP④ MEAN',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp4_d: {
    label: 'IBP④ DIAS',
    unit: 'mmHg',
    min: { min: '-40', max: '300', off: { default: '500' } },
    max: { min: '-40', max: '300', off: { default: '500' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  ibp4_pr: { label: 'IBP④ PR', unit: '/ min', maxTextCount: 3 },
  nibp_s: {
    label: 'NIBP SYS',
    unit: 'mmHg',
    min: { min: '30', max: '300', off: { default: '0' } },
    max: { min: '30', max: '300', off: { default: '0', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  nibp_m: {
    label: 'NIBP MEAN',
    unit: 'mmHg',
    min: { min: '30', max: '300', off: { default: '0' } },
    max: { min: '30', max: '300', off: { default: '0', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  nibp_d: {
    label: 'NIBP DIAS',
    unit: 'mmHg',
    min: { min: '30', max: '300', off: { default: '0' } },
    max: { min: '30', max: '300', off: { default: '0', MP1000NTP: '305' } },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  nibp_pr: {
    label: 'NIBP PR',
    unit: '/min',
    maxTextCount: 3,
  },
  nibp_time: {
    label: 'NIBP time',
    unit: '',
    maxTextCount: 5,
  },
  st_i: {
    label: 'ST Ⅰ',
    unit: 'mV',
    min: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '-1' } },
    max: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '1' } },
    maxTextCount: 5,
    decimalPlaces: 2,
  },
  st_ii: {
    label: 'ST Ⅱ',
    unit: 'mV',
    min: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '-1' } },
    max: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '1' } },
    maxTextCount: 5,
    decimalPlaces: 2,
  },
  st_iii: {
    label: 'ST Ⅲ',
    unit: 'mV',
    min: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '-1' } },
    max: { min: '-0.9', max: '0.9', off: { default: '1.26', MP1000NTP: '1' } },
    maxTextCount: 5,
    decimalPlaces: 2,
  },
  tmp1: {
    label: 'TEMP①',
    unit: '℃',
    min: { min: '10', max: '50', off: { default: '0', MP1000NTP: '9' } },
    max: { min: '10', max: '50', off: { default: '0', MP1000NTP: '51' } },
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  tmp2: {
    label: 'TEMP②',
    unit: '℃',
    min: { min: '10', max: '50', off: { default: '0', MP1000NTP: '9' } },
    max: { min: '10', max: '50', off: { default: '0', MP1000NTP: '51' } },
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  flow: {
    label: 'Flow',
    unit: 'LPM',
    min: { min: '0', max: '64' },
    max: { min: '1', max: '65' },
    settingParam: 'setting_flow',
    therapySettingParam: {
      HFT700: {
        HF_bi: { setting: 'setting_base_flow', conditionSetting: 'setting_assist_flow' },
        HF_bi_tsf: { setting: 'setting_base_flow', conditionSetting: 'setting_assist_flow' },
      },
    },
    therapyMinMax: {
      HF_bi: { conditionSetting: 'setting_assist_flow', min: { min: '0', max: '109' }, max: { min: '1', max: '110' } },
    },
    decimalPlaces: 1,
    maxTextCount: 5,
  },
  fio2: {
    label: 'FiO₂',
    unit: '%',
    min: { min: '18', max: '99' },
    max: { min: '19', max: '100', off: { default: '101', MV50: '101' } },
    settingParam: 'setting_fio2',
    therapySettingParam: {
      HFT700: { HF_bi_tsf: { setting: 'setting_backup_fio2' }, HF_tsf: { setting: 'setting_backup_fio2' } },
    },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  spo2: {
    label: 'SpO₂',
    unit: '%',
    min: { min: '1', max: '99', off: { default: '0', MP1000NTP: '50' } },
    max: { min: '2', max: '100', off: { default: '0', MP1000NTP: '101', MV50: '100' }, maxCustomString: 'Off' },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  airway_temp: {
    label: 'Airway Temp.',
    unit: '℃',
    min: { min: '0', max: '43' },
    max: { min: '1', max: '44' },
    settingParam: 'setting_dew_point',
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  rr: {
    label: 'RR',
    unit: '/ min',
    min: { min: '0', max: '149', off: { default: '0', MP1000NTP: '1' } },
    max: { min: '1', max: '150', off: { default: '0', MP1000NTP: '151' } },
    settingParam: 'setting_rr',
    therapySettingParam: {
      HFT700: {
        'BI-LEVEL_t': { setting: 'setting_rr' },
        'BI-LEVEL_s_t': { setting: 'setting_rr' },
        'BI-LEVEL_s_t_v': { setting: 'setting_rr' },
        'BI-LEVEL_t_v': { setting: 'setting_rr' },
      },
    },
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  pr: {
    label: 'PR',
    unit: '/ min',
    min: { min: '30', max: '240', maxCustomString: 'Off' },
    max: { min: '35', max: '240', maxCustomString: 'Off' },
    customDelta: 5,
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  pi: {
    label: 'PI',
    unit: '%',
    min: { min: '0.03', max: '19', maxCustomString: 'Off' },
    max: { min: '0.04', max: '20', maxCustomString: 'Off' },
    maxTextCount: 4,
    decimalPlaces: 2,
  },
  vti: { label: 'VTi', unit: 'mL', settingParam: 'setting_vti', decimalPlaces: 0, maxTextCount: 4 },
  vte: {
    label: 'VTe',
    unit: 'mL',
    max: { min: '0', max: '3000', off: { default: '3001', MV2000: '2505', MV50: '2505' } },
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  ppeak: { label: 'Ppeak', unit: 'cmH₂O', decimalPlaces: 0, maxTextCount: 4 },
  peep: {
    label: 'PEEP',
    unit: 'cmH₂O',
    min: { min: '0', max: '6', off: { default: '-1', MV50: '-1' } },
    max: { min: '8', max: '22', off: { default: '23', MV50: '100' } },
    settingParam: 'setting_peep',
    therapySettingParam: {
      HFT700: {
        'BI-LEVEL_t': { setting: 'setting_epap' },
        CPAP: { setting: 'setting_cpap' },
        'BI-LEVEL_s': { setting: 'setting_epap' },
        'BI-LEVEL_s_t': { setting: 'setting_epap' },
        'BI-LEVEL_s_t_v': { setting: 'setting_epap' },
        'BI-LEVEL_s_v': { setting: 'setting_epap' },
        'BI-LEVEL_t_v': { setting: 'setting_epap' },
      },
    },
    therapyMinMax: {
      CPAP: { min: { min: '0', max: '12' }, max: { min: '14', max: '22' } },
    },
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  pip: {
    label: 'PIP',
    unit: 'cmH₂O',
    therapySettingParam: {
      HFT700: {
        'BI-LEVEL_t': { setting: 'setting_ipap' },
        'BI-LEVEL_s': { setting: 'setting_ipap' },
        'BI-LEVEL_s_t': { setting: 'setting_ipap' },
      },
    },
    min: { min: '0', max: '15', disabled: ['BI-LEVEL_s_t_v', 'BI-LEVEL_s_v', 'BI-LEVEL_t_v'] },
    max: { min: '17', max: '43' },
    maxTextCount: 5,
    decimalPlaces: 1,
  },
  ptif: {
    label: 'PTIF',
    unit: 'LPM',
    maxTextCount: 5,
    decimalPlaces: 1,
  },
  sf_ratio: {
    label: 'S/F ratio',
    unit: '',
    maxTextCount: 3,
    decimalPlaces: 0,
  },
  rox: {
    label: 'ROX',
    unit: '',
    maxTextCount: 5,
    decimalPlaces: 1,
  },
  peep_h: {
    label: 'PEEP H',
    unit: 'cmH₂O',
    settingParam: 'setting_peep_h',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  ti: {
    label: 'TI',
    unit: 'sec',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  ppause: {
    label: 'Ppause',
    unit: 'cmH₂O',
    settingParam: 'setting_ppause',
    decimalPlaces: 0,
    maxTextCount: 3,
  },
  vme: {
    label: 'VMe',
    unit: 'l/min',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  peep_l: {
    label: 'PEEP L',
    unit: 'cmH₂O',
    settingParam: 'setting_peep_l',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  te: {
    label: 'TE',
    unit: 'sec',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  pmean: {
    label: 'Pmean',
    unit: 'cmH₂O',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  rr_spont: {
    label: 'RR spont',
    unit: 'BPM',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  vme_s: {
    label: 'VMe S.',
    unit: 'l/min',
    maxTextCount: 5,
    decimalPlaces: 1,
  },
  'p_0.1': {
    label: 'P 0.1',
    unit: 'cmH₂O',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  rsbi: {
    label: 'RSBi',
    unit: 'BPM/L',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  wobv: {
    label: 'WOBv',
    unit: 'J/L',
    decimalPlaces: 2,
    maxTextCount: 6,
  },
  cl: {
    label: 'CL',
    unit: 'ml/cmH₂O',
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  ra: {
    label: 'RA',
    unit: 'cmH₂O/L/s',
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  auto_peep: {
    label: 'AutoPEEP',
    unit: 'cmH₂O',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  e: {
    label: 'E',
    unit: 'cmH₂O/L',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  tc: {
    label: 'TC',
    unit: 'sec',
    maxTextCount: 5,
    decimalPlaces: 2,
  },
  etco2: {
    label: 'EtCO₂',
    unit: '%',
    min: { min: '0', max: '20', off: { default: '25.4', MV50: '-0.1' } },
    max: { min: '0', max: '20', off: { default: '25.4', MV50: '25.1' } },
    decimalPlaces: 1,
    maxTextCount: 4,
  },
  ico2: {
    label: 'iCO₂',
    unit: '%',
    min: { min: '0', max: '99', off: { default: '100', MV50: '-0.1' } },
    decimalPlaces: 1,
    maxTextCount: 5,
  },
  etco2_rr: {
    label: 'EtCO₂ RR',
    unit: 'BPM',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  vico2: {
    label: 'ViCO₂',
    unit: 'mL',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  veco2: {
    label: 'VeCO₂',
    unit: 'mL',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  tsf_status: {
    label: 'TSF',
    unit: '',
  },
  estimated_tidal_volume: {
    label: 'Vt est',
    unit: 'ml',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  v_delivery: {
    label: 'V delivery',
    unit: 'ml',
    min: { min: '50', max: '2499' },
    max: { min: '51', max: '2500' },
    therapySettingParam: {
      HFT700: {
        'BI-LEVEL_s_t_v': { setting: 'setting_inspiratory_volume_vi' },
        'BI-LEVEL_s_v': { setting: 'setting_inspiratory_volume_vi' },
        'BI-LEVEL_t_v': { setting: 'setting_inspiratory_volume_vi' },
      },
    },
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  fi_peak: {
    label: 'Fi-peak',
    unit: 'l/min',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  fe_peak: {
    label: 'Fe-peak',
    unit: 'l/min',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  ie_ratio: {
    label: 'I : E',
    unit: '',
    decimalPlaces: undefined,
    maxTextCount: 5,
  },
  vmeco2: {
    label: 'VMe CO₂',
    unit: 'mLPM',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  alarm_status: {
    label: 'Alarm Status',
    unit: '',
  },
  paw: {
    label: 'PAW',
    unit: 'cmH₂O',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  wobp: {
    label: 'WOBp',
    unit: 'J/L',
    decimalPlaces: 2,
    maxTextCount: 4,
  },
  exp_flow: {
    label: 'Exp.Flow',
    unit: 'LPM',
    settingParam: 'setting_flow',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  ve_stroke: {
    label: 'Ve Stroke',
    unit: 'mL',
    decimalPlaces: 0,
    maxTextCount: 4,
  },
  h_freq: {
    label: 'H.Freq',
    unit: 'Hz',
    settingParam: 'setting_h_freq',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  amplitude: {
    label: 'Amplitude',
    unit: 'cmH₂O',
    maxTextCount: 4,
    decimalPlaces: 1,
  },
  vent_run: {
    label: 'Vent Run',
    unit: '',
  },
  nibp_status: {
    label: 'NIBP Status',
    unit: '',
  },
} as const;
