import { TDevice, TWaves } from '@/constants/types';

const flowWave = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 100, 220, 300, 360, 430, 870, 1520, 2210, 2770, 3190,
  3480, 3690, 3820, 3940, 3990, 4000, 4010, 4010, 4000, 4010, 4000, 4000, 4020, 4030, 4030, 4050, 4050, 4040, 4040,
  4050, 4060, 4070, 4070, 4070, 4060, 4060, 4050, 4050, 4040, 4020, 4020, 4010, 4010, 4010, 4010, 4010, 4010, 4010,
  4000, 4000, 3990, 4000, 4010, 4020, 4020, 4010, 4010, 4020, 4020, 4020, 4010, 4000, 4000, 4010, 4020, 4020, 4020,
  4030, 4010, 4000, 3990, 3970, 3940, 3920, 3890, 3870, 3850, 3840, 3830, 3820, 3810, 3800, 3790, 3780, 3780, 3790,
  3800, 3800, 3780, 3780, 3770, 3780, 3770, 3770, 3750, 3730, 3710, 3700, 3670, 3650, 3620, 3600, 3600, 3600, 3590,
  3600, 3590, 3590, 3580, 3570, 3560, 3550, 3560, 3550, 3540, 3540, 3540, 3530, 3520, 3400, 2890, 2340, 1870, 1490,
  1190, 950, 760, 610, 1, -280, -3550, -5670, -5990, -6020, -6090, -6050, -5990, -5920, -5810, -5710, -5620, -5540,
  -5460, -5380, -5310, -5250, -5210, -5190, -5170, -5140, -5110, -5070, -5030, -4990, -4960, -4920, -4910, -4870, -4810,
  -4750, -4690, -4640, -4600, -4550, -4530, -4490, -4460, -4410, -4370, -4340, -4310, -4310, -4310, -4310, -4270, -4250,
  -4230, -4200, -4160, -4140, -4110, -4060, -4010, -3990, -3960, -3920, -3880, -3840, -3820, -3780, -3750, -3710, -3690,
  -3640, -3580, -3540, -3490, -3470, -3430, -3380, -3320, -3270, -3220, -3180, -3140, -3100, -3060, -3020, -2990, -2950,
  -2910, -2860, -2810, -2780, -2740, -2690, -2650, -2600, -2560, -2510, -2460, -2410, -2370, -2310, -2260, -2220, -2160,
  -2100, -2050, -2010, -1970, -1930, -1880, -1840, -1790, -1740, -1700, -1650, -1610, -1570, -1520, -1470, -1420, -1380,
  -1330, -1280, -1250, -1200, -1160, -1110, -1070, -1030, -970, -920, -870, -810, -750, -690, -640, -590, -550, -510,
  -480, -450, -420, -380, -350, -320, -300, -280, -250, -230, -210, -190, -160, -140, -120, -110, -100, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];

export const waveData: { [K in TDevice]: { [K in TWaves]?: number[] } } = {
  HFT700: {
    flow_wave: new Array(60).fill(45),
    patient_flow_wave: new Array(60).fill(39),
    pressure_wave: [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 4, 5, 7, 9, 10, 11, 12, 13, 14, 14, 15, 15, 16, 16, 17, 17, 18, 18, 18, 19, 19, 19,
      20, 20, 21, 21, 21, 22, 22, 22, 22, 23, 23, 23, 23, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 27, 27, 27, 27,
      28, 28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29,
      30, 30, 30, 30, 31, 31, 31, 31, 32, 32, 32, 32, 33, 33, 33, 33, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 35, 35,
      35, 35, 36, 36, 36, 37, 37, 38, 38, 39, 39, 37, 35, 36, 35, 34, 35, 34, 34, 34, 29, 24, 18, 14, 11, 9, 7, 6, 5, 4,
      4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    ],
    volume_wave: [
      25, 24, 24, 24, 24, 24, 23, 23, 22, 22, 21, 21, 21, 20, 20, 19, 19, 18, 18, 18, 17, 12, 12, 12, 12, 12, 12, 12, 1,
      1, 1, 1, 1, 1, 1, 1, 4, 8, 13, 18, 24, 30, 36, 42, 48, 55, 61, 68, 75, 81, 88, 95, 102, 109, 115, 122, 129, 136,
      143, 150, 156, 163, 170, 177, 184, 191, 197, 204, 211, 218, 225, 231, 238, 245, 252, 259, 265, 272, 279, 286, 293,
      300, 306, 313, 320, 327, 334, 341, 347, 354, 361, 368, 375, 382, 388, 395, 402, 409, 416, 423, 429, 436, 442, 449,
      455, 462, 468, 474, 481, 487, 494, 500, 506, 513, 519, 526, 532, 538, 545, 551, 557, 564, 570, 576, 583, 589, 595,
      601, 607, 613, 620, 626, 632, 638, 642, 648, 652, 658, 666, 672, 682, 744, 748, 752, 755, 757, 759, 760, 761, 762,
      763, 760, 752, 742, 731, 721, 711, 700, 690, 680, 671, 661, 652, 642, 633, 624, 615, 607, 598, 589, 581, 572, 564,
      555, 547, 539, 530, 522, 514, 506, 498, 491, 483, 475, 467, 460, 452, 445, 438, 430, 423, 416, 408, 401, 394, 387,
      380, 373, 366, 359, 352, 345, 338, 331, 324, 317, 311, 304, 298, 291, 285, 278, 272, 266, 260, 254, 248, 242, 236,
      230, 224, 219, 213, 207, 202, 197, 191, 186, 181, 176, 171, 166, 161, 156, 151, 147, 142, 138, 133, 129, 125, 120,
      116, 112, 108, 104, 101, 97, 93, 90, 86, 83, 79, 76, 73, 70, 67, 64, 61, 58, 56, 53, 50, 48, 46, 43, 41, 39, 37,
      35, 33, 31, 29, 28, 26, 24, 23, 22, 20, 19, 18, 17, 16, 15, 14, 17, 16, 16, 16, 15, 15, 15, 14, 14, 14, 13, 13,
      13, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    ],
  },
  MP1300: {
    ecg_wave_i: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 5, 8, 9, 9, 11, 14, 14, 14, 14, 14, 12, 10, 9, 8, 7, 4, 2, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2, -5, -8, -7, 15, 32, 49, 63, 71, 67, 55, 39, 21,
      -4, -7, -7, -4, -1, 0, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      1, 2, 5, 7, 8, 10, 12, 14, 15, 17, 19, 21, 21, 22, 23, 24, 25, 25, 27, 27, 27, 27, 26, 26, 24, 22, 22, 21, 18, 17,
      15, 14, 11, 9, 7, 4, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_ii: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 5, 11, 16, 18, 19, 23, 28, 28, 28, 29, 28, 24, 21, 19, 17, 14, 8,
      4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -4, -10, -16, -14, 31, 64, 98, 127, 142, 135,
      110, 78, 43, -8, -15, -15, -9, -2, 1, -2, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 2, 5, 10, 14, 16, 21, 24, 29, 30, 35, 38, 42, 42, 44, 46, 49, 50, 51, 55, 55, 55, 55, 53, 52, 49,
      45, 44, 42, 37, 34, 31, 28, 22, 18, 14, 9, 5, 2, 0, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
      -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_iii: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 5, 6, 6, 7, 9, 9, 9, 9, 9, 8, 7, 6, 5, 4, 2, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -3, -5, -4, 10, 21, 32, 42, 47, 45, 36, 26, 14, -2, -5, -5,
      -3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 5,
      7, 8, 9, 10, 11, 12, 14, 14, 14, 15, 16, 16, 17, 18, 18, 18, 18, 17, 17, 16, 15, 14, 14, 12, 11, 10, 9, 7, 6, 4,
      3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_avr: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2, -5, -10, -14, -15, -16, -20, -24, -24, -24, -25, -24, -20, -18,
      -16, -15, -12, -7, -4, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 4, 9, 14, 12, -26, -54,
      -82, -106, -119, -113, -92, -65, -36, 7, 13, 13, 8, 2, -1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2, -5, -9, -12, -14, -18, -20, -25, -25, -30, -32, -35, -35, -37, -39, -41,
      -42, -43, -46, -46, -46, -46, -45, -44, -41, -38, -37, -35, -31, -29, -26, -24, -19, -15, -12, -8, -5, -2, 0, 1,
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_avl: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 3, 3, 3, 4, 5, 5, 5, 5, 5, 4, 3, 3, 3, 3, 2, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -2, -3, -3, 5, 11, 17, 21, 24, 22, 19, 13, 7, -2, -2, -2,
      -1, -1, 0, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 3,
      3, 3, 4, 5, 5, 6, 7, 7, 7, 8, 8, 8, 9, 8, 9, 9, 9, 9, 9, 9, 8, 7, 8, 7, 6, 6, 5, 5, 4, 3, 3, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_avf: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 6, 10, 12, 12, 14, 18, 18, 18, 18, 18, 16, 13, 12, 10, 9, 5, 2,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2, -6, -10, -9, 20, 42, 65, 84, 94, 89, 73, 52,
      28, -5, -9, -9, -5, -1, 0, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 1, 2, 6, 9, 10, 13, 16, 18, 20, 22, 25, 28, 28, 29, 30, 32, 33, 33, 36, 36, 36, 36, 34, 34, 32, 29, 29, 28,
      24, 22, 20, 18, 14, 12, 9, 5, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    ecg_wave_v: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 5, 6, 6, 7, 9, 9, 9, 9, 9, 8, 7, 6, 5, 4, 2, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -3, -5, -4, 10, 21, 32, 42, 47, 45, 36, 26, 14, -2, -5, -5,
      -3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 5,
      7, 8, 9, 10, 11, 12, 14, 14, 14, 15, 16, 16, 17, 18, 18, 18, 18, 17, 17, 16, 15, 14, 14, 12, 11, 10, 9, 7, 6, 4,
      3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    resp_wave: [
      -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120,
      -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120, -120,
      -120, -120, -120, -120, -119, -119, -119, -119, -118, -118, -117, -117, -117, -116, -116, -115, -115, -114, -114,
      -113, -113, -112, -111, -111, -110, -109, -108, -108, -107, -106, -105, -104, -103, -102, -100, -99, -97, -96,
      -94, -93, -91, -89, -87, -86, -84, -82, -79, -77, -75, -73, -70, -68, -65, -63, -60, -57, -54, -51, -48, -45, -43,
      -40, -37, -34, -31, -29, -26, -23, -21, -18, -16, -13, -11, -9, -6, -4, -2, 0, 2, 4, 6, 8, 10, 12, 14, 15, 17, 19,
      20, 22, 24, 25, 27, 28, 30, 31, 33, 34, 36, 37, 39, 40, 42, 43, 45, 46, 48, 49, 51, 52, 54, 55, 57, 58, 60, 61,
      63, 64, 66, 67, 69, 70, 72, 73, 74, 76, 77, 79, 80, 81, 83, 84, 85, 87, 88, 89, 90, 91, 93, 94, 95, 96, 97, 98,
      99, 100, 101, 102, 103, 103, 104, 105, 106, 107, 108, 108, 109, 110, 110, 111, 112, 112, 113, 113, 114, 114, 115,
      115, 116, 116, 117, 117, 117, 118, 118, 118, 118, 119, 119, 119, 119, 119, 119, 119, 119, 120, 120, 120, 120, 120,
      120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120,
      120, 120, 120, 120, 120, 120, 120, 119, 119, 119, 119, 119, 119, 119, 119, 118, 118, 118, 118, 117, 117, 117, 116,
      116, 115, 115, 114, 114, 113, 113, 112, 112, 111, 110, 110, 109, 108, 108, 107, 106, 105, 104, 103, 102, 101, 99,
      98, 96, 95, 93, 92, 90, 88, 87, 85, 83, 81, 78, 76, 74, 72, 69, 67, 64, 62, 59, 56, 53, 51, 48, 45, 42, 39, 36,
      33, 30, 28, 25, 22, 20, 17, 15, 12, 10, 8, 6, 3, 1, -1, -3, -5, -7, -9, -11, -13, -15, -16, -18, -20, -21, -23,
      -24, -26, -27, -29, -30, -32, -33, -34, -36, -37, -38, -40, -41, -42, -43, -44, -45, -47, -48, -49, -50, -51, -52,
      -53, -54, -55, -56, -56, -57, -58, -59, -60, -60, -61, -62, -63, -63, -64, -65, -66, -66, -67, -68, -69, -69, -70,
      -71, -72, -72, -73, -74, -75, -75, -76, -77, -78, -78, -79, -80, -81, -81, -82, -83, -84, -84, -85, -86, -87, -87,
      -88, -89, -90, -90, -91, -92, -93, -93, -94, -95, -96, -96, -97, -98, -99, -99, -100, -101, -102, -102, -103,
      -104, -105, -105, -106, -107, -108, -108, -109, -110, -111, -111, -112, -113, -113, -114, -114, -115, -115, -116,
      -116, -117, -117, -117, -118, -118, -119, -119, -119, -119, -120, -120, -120, -120, -120, -120, -120, -120, -120,
    ],
    spo2_wave: [
      -182, -191, -198, -204, -210, -216, -224, -232, -241, -250, -259, -268, -275, -281, -287, -293, -298, -304, -311,
      -320, -338, -349, -356, -361, -364, -367, -372, -379, -388, -397, -406, -415, -423, -432, -438, -444, -449, -455,
      -461, -467, -473, -479, -485, -490, -496, -499, -496, -485, -470, -452, -431, -409, -384, -357, -327, -292, -254,
      -216, -179, -143, -108, -70, -31, 11, 55, 99, 143, 187, 231, 272, 310, 344, 373, 398, 419, 437, 452, 466, 476,
      485, 491, 495, 496, 495, 489, 477, 463, 449, 433, 415, 395, 372, 347, 320, 294, 271, 247, 222, 195, 169, 143, 119,
      97, 76, 55, 37, 22, 7, -8, -22, -34, -41, -46, -49, -51, -49, -46, -42, -39, -37, -37, -39, -42, -45, -48, -50,
      -53, -57, -60, -66, -72, -78, -84, -90, -96, -96, -100, -106, -112, -118, -124, -129, -135, -141, -147, -153,
      -156, -161, -167, -173,
    ],
    ibp1_wave: [
      813, 807, 802, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 802, 806, 812, 824, 843, 871, 906, 946, 983, 1015, 1043, 1067, 1088, 1110, 1131, 1151, 1168, 1181, 1190,
      1197, 1200, 1199, 1196, 1196, 1198, 1200, 1199, 1196, 1190, 1181, 1171, 1159, 1146, 1134, 1121, 1109, 1098, 1088,
      1082, 1076, 1070, 1065, 1065, 1071, 1080, 1088, 1092, 1092, 1092, 1088, 1080, 1071, 1062, 1052, 1043, 1034, 1024,
      1012, 996, 983, 972, 963, 954, 944, 938, 932, 925, 919, 913, 907, 901, 894, 888, 882, 876, 870, 864, 858, 855,
      854, 854, 853, 850, 844, 838, 835, 834, 831, 831, 829, 825, 823, 823, 823, 823, 823, 823, 823, 823, 823, 823, 822,
      819,
    ],
    ibp2_wave: [
      813, 807, 802, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 802, 806, 812, 824, 843, 871, 906, 946, 983, 1015, 1043, 1067, 1088, 1110, 1131, 1151, 1168, 1181, 1190,
      1197, 1200, 1199, 1196, 1196, 1198, 1200, 1199, 1196, 1190, 1181, 1171, 1159, 1146, 1134, 1121, 1109, 1098, 1088,
      1082, 1076, 1070, 1065, 1065, 1071, 1080, 1088, 1092, 1092, 1092, 1088, 1080, 1071, 1062, 1052, 1043, 1034, 1024,
      1012, 996, 983, 972, 963, 954, 944, 938, 932, 925, 919, 913, 907, 901, 894, 888, 882, 876, 870, 864, 858, 855,
      854, 854, 853, 850, 844, 838, 835, 834, 831, 831, 829, 825, 823, 823, 823, 823, 823, 823, 823, 823, 823, 823, 822,
      819,
    ],
    ibp3_wave: [
      813, 807, 802, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 802, 806, 812, 824, 843, 871, 906, 946, 983, 1015, 1043, 1067, 1088, 1110, 1131, 1151, 1168, 1181, 1190,
      1197, 1200, 1199, 1196, 1196, 1198, 1200, 1199, 1196, 1190, 1181, 1171, 1159, 1146, 1134, 1121, 1109, 1098, 1088,
      1082, 1076, 1070, 1065, 1065, 1071, 1080, 1088, 1092, 1092, 1092, 1088, 1080, 1071, 1062, 1052, 1043, 1034, 1024,
      1012, 996, 983, 972, 963, 954, 944, 938, 932, 925, 919, 913, 907, 901, 894, 888, 882, 876, 870, 864, 858, 855,
      854, 854, 853, 850, 844, 838, 835, 834, 831, 831, 829, 825, 823, 823, 823, 823, 823, 823, 823, 823, 823, 823, 822,
      819,
    ],
    ibp4_wave: [
      813, 807, 802, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800, 800,
      800, 802, 806, 812, 824, 843, 871, 906, 946, 983, 1015, 1043, 1067, 1088, 1110, 1131, 1151, 1168, 1181, 1190,
      1197, 1200, 1199, 1196, 1196, 1198, 1200, 1199, 1196, 1190, 1181, 1171, 1159, 1146, 1134, 1121, 1109, 1098, 1088,
      1082, 1076, 1070, 1065, 1065, 1071, 1080, 1088, 1092, 1092, 1092, 1088, 1080, 1071, 1062, 1052, 1043, 1034, 1024,
      1012, 996, 983, 972, 963, 954, 944, 938, 932, 925, 919, 913, 907, 901, 894, 888, 882, 876, 870, 864, 858, 855,
      854, 854, 853, 850, 844, 838, 835, 834, 831, 831, 829, 825, 823, 823, 823, 823, 823, 823, 823, 823, 823, 823, 822,
      819,
    ],
    co2_wave: [
      87, 84, 83, 82, 81, 80, 80, 79, 79, 79, 78, 78, 78, 78, 79, 78, 78, 79, 80, 82, 85, 96, 125, 177, 237, 278, 305,
      321, 329, 331, 336, 336, 338, 340, 341, 342, 343, 344, 345, 346, 350, 349, 348, 348, 348, 348, 349, 350, 350, 351,
      350, 350, 350, 349, 349, 348, 349, 340, 311, 249, 175, 126, 102, 92,
    ],
  },
  MV2000: {
    flow_wave: flowWave,
    pressure_wave: [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 4, 5, 7, 9, 10, 11, 12, 13, 14, 14, 15, 15, 16, 16, 17, 17, 18, 18, 18, 19, 19, 19,
      20, 20, 21, 21, 21, 22, 22, 22, 22, 23, 23, 23, 23, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 27, 27, 27, 27,
      28, 28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29,
      30, 30, 30, 30, 31, 31, 31, 31, 32, 32, 32, 32, 33, 33, 33, 33, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 35, 35,
      35, 35, 36, 36, 36, 37, 37, 38, 38, 39, 39, 37, 35, 36, 35, 34, 35, 34, 34, 34, 29, 24, 18, 14, 11, 9, 7, 6, 5, 4,
      4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    ],
    volume_wave: [
      25, 24, 24, 24, 24, 24, 23, 23, 22, 22, 21, 21, 21, 20, 20, 19, 19, 18, 18, 18, 17, 12, 12, 12, 12, 12, 12, 12, 1,
      1, 1, 1, 1, 1, 1, 1, 4, 8, 13, 18, 24, 30, 36, 42, 48, 55, 61, 68, 75, 81, 88, 95, 102, 109, 115, 122, 129, 136,
      143, 150, 156, 163, 170, 177, 184, 191, 197, 204, 211, 218, 225, 231, 238, 245, 252, 259, 265, 272, 279, 286, 293,
      300, 306, 313, 320, 327, 334, 341, 347, 354, 361, 368, 375, 382, 388, 395, 402, 409, 416, 423, 429, 436, 442, 449,
      455, 462, 468, 474, 481, 487, 494, 500, 506, 513, 519, 526, 532, 538, 545, 551, 557, 564, 570, 576, 583, 589, 595,
      601, 607, 613, 620, 626, 632, 638, 642, 648, 652, 658, 666, 672, 682, 744, 748, 752, 755, 757, 759, 760, 761, 762,
      763, 760, 752, 742, 731, 721, 711, 700, 690, 680, 671, 661, 652, 642, 633, 624, 615, 607, 598, 589, 581, 572, 564,
      555, 547, 539, 530, 522, 514, 506, 498, 491, 483, 475, 467, 460, 452, 445, 438, 430, 423, 416, 408, 401, 394, 387,
      380, 373, 366, 359, 352, 345, 338, 331, 324, 317, 311, 304, 298, 291, 285, 278, 272, 266, 260, 254, 248, 242, 236,
      230, 224, 219, 213, 207, 202, 197, 191, 186, 181, 176, 171, 166, 161, 156, 151, 147, 142, 138, 133, 129, 125, 120,
      116, 112, 108, 104, 101, 97, 93, 90, 86, 83, 79, 76, 73, 70, 67, 64, 61, 58, 56, 53, 50, 48, 46, 43, 41, 39, 37,
      35, 33, 31, 29, 28, 26, 24, 23, 22, 20, 19, 18, 17, 16, 15, 14, 17, 16, 16, 16, 15, 15, 15, 14, 14, 14, 13, 13,
      13, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    ],
  },
  MV50: {
    flow_wave: flowWave,
    pressure_wave: [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 4, 5, 7, 9, 10, 11, 12, 13, 14, 14, 15, 15, 16, 16, 17, 17, 18, 18, 18, 19, 19, 19,
      20, 20, 21, 21, 21, 22, 22, 22, 22, 23, 23, 23, 23, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 27, 27, 27, 27,
      28, 28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29,
      30, 30, 30, 30, 31, 31, 31, 31, 32, 32, 32, 32, 33, 33, 33, 33, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 35, 35,
      35, 35, 36, 36, 36, 37, 37, 38, 38, 39, 39, 37, 35, 36, 35, 34, 35, 34, 34, 34, 29, 24, 18, 14, 11, 9, 7, 6, 5, 4,
      4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    ],
    volume_wave: [
      25, 24, 24, 24, 24, 24, 23, 23, 22, 22, 21, 21, 21, 20, 20, 19, 19, 18, 18, 18, 17, 12, 12, 12, 12, 12, 12, 12, 1,
      1, 1, 1, 1, 1, 1, 1, 4, 8, 13, 18, 24, 30, 36, 42, 48, 55, 61, 68, 75, 81, 88, 95, 102, 109, 115, 122, 129, 136,
      143, 150, 156, 163, 170, 177, 184, 191, 197, 204, 211, 218, 225, 231, 238, 245, 252, 259, 265, 272, 279, 286, 293,
      300, 306, 313, 320, 327, 334, 341, 347, 354, 361, 368, 375, 382, 388, 395, 402, 409, 416, 423, 429, 436, 442, 449,
      455, 462, 468, 474, 481, 487, 494, 500, 506, 513, 519, 526, 532, 538, 545, 551, 557, 564, 570, 576, 583, 589, 595,
      601, 607, 613, 620, 626, 632, 638, 642, 648, 652, 658, 666, 672, 682, 744, 748, 752, 755, 757, 759, 760, 761, 762,
      763, 760, 752, 742, 731, 721, 711, 700, 690, 680, 671, 661, 652, 642, 633, 624, 615, 607, 598, 589, 581, 572, 564,
      555, 547, 539, 530, 522, 514, 506, 498, 491, 483, 475, 467, 460, 452, 445, 438, 430, 423, 416, 408, 401, 394, 387,
      380, 373, 366, 359, 352, 345, 338, 331, 324, 317, 311, 304, 298, 291, 285, 278, 272, 266, 260, 254, 248, 242, 236,
      230, 224, 219, 213, 207, 202, 197, 191, 186, 181, 176, 171, 166, 161, 156, 151, 147, 142, 138, 133, 129, 125, 120,
      116, 112, 108, 104, 101, 97, 93, 90, 86, 83, 79, 76, 73, 70, 67, 64, 61, 58, 56, 53, 50, 48, 46, 43, 41, 39, 37,
      35, 33, 31, 29, 28, 26, 24, 23, 22, 20, 19, 18, 17, 16, 15, 14, 17, 16, 16, 16, 15, 15, 15, 14, 14, 14, 13, 13,
      13, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
      12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    ],
  },
};
