import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Input: ComponentStyleConfig = {
  variants: {
    outline: ({ colorMode }) => ({
      field: {
        // borderRadius: 'lg',
        // borderColor: '#D0D0D0',
        background: colorMode === 'dark' ? '#5C6870' : 'white',
        color: 'black',
        fontSize: '14px',
        rounded: '4px',
        _focus: {
          shadow: 'none',
        },
        _disabled: {
          bg: '#F5F5F6',
          color: 'gray.700',
          opacity: '1',
        },
        _placeholder: {
          color: 'gray.700',
          fontWeight: '300',
          fontSize: 'inherit',
        },
        _invalid: {
          shadow: 'none',
        },
      },
      addon: {
        bg: 'transparent',
      },
      element: {
        h: 'full',
      },
    }),
  },

  sizes: {
    md: {
      field: {
        h: '34px',
      },
    },
    xl: {
      field: {
        h: '56px',
        px: '6',
      },
    },
  },

  defaultProps: {
    focusBorderColor: 'brand.500',
    size: 'md',
    errorBorderColor: 'critical.500',
  },
};

export default Input;
