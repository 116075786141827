import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tag: ComponentStyleConfig = {
  baseStyle: {
    container: {
      fontWeight: 'inherit',
    },
    label: {
      fontWeight: 'inherit',
    },
  },
};

export default Tag;
