import { memo, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { addSeconds, isBefore, subMinutes } from 'date-fns';

import { useWaveDetail } from '@/apis/patient/queries';
import { WAVE_PARAMS } from '@/constants/data';
import { useA4Page } from '@/hooks/usePdf';
import usePeriodFilledData from '@/pages/CentralMain/hooks/usePeriodFilledData';
import {
  TrendFormatRule,
  getTrendFormatRuleListByDeviceModel,
} from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { DeviceModel } from '@/pages/CentralMain/types';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import A4Container from '@/reports/container/A4Container';
import { useTrendData } from '@/reports/hooks/useTrendData';
import TrendTable from '@/reports/trend-table';
import EcgWave28sec from '@/reports/wave/EcgWave28sec';
import { isEqualBySeconds, parseUTC0String } from '@/utils/dateTimeUtils';

import ReportHeader, { HeaderData } from '../common/ReportHeader';

/* 
사용 예시:
  script부분:
    const pdfId = useId();
    const baseDateTime = new Date();
    const { openFileInNewWindow, isProcessing } = usePdf(
      Array.from({ length: 4 }, (_, index) => `${pdfId}_${index}`),
      { filename: filenameGenerator({ prefix: 'ecg' }) },
    );

  JSX부분:
    <Button onClick={openFileInNewWindow} isLoading={isProcessing} loadingText="Processing" variant="basic">
      Print
    </Button>
    {dataId &&
      Array.from({ length: 4 }, (_, index) => {
        return (
          <EcgWave28secPage
            key={`${index.toString()}`}
            pageId={`${pdfId}_${index}`}
            page={index + 1}
            id={dataId}
            baseDateTime={subSeconds(baseDateTime, (4 - 1 - index) * 28)}
          />
        );
      })
    }
 */
const TrendTableBefore7min = ({ id, baseDateTime, sec }: { id: string; baseDateTime: Date; sec: number }) => {
  const { model } = parseDataId(id);
  const tableFormatRules = useMemo(() => {
    const deviceModel = model as DeviceModel;
    return getTrendFormatRuleListByDeviceModel<TrendFormatRule<typeof deviceModel>>(deviceModel).filter((rule) =>
      ['hr', 'spo2', 'nibp', 'rr', 'ibp1', 'ibp2', 'tmp'].includes(rule.property),
    );
  }, [model]);

  const lastTableDatetime = new Date(addSeconds(baseDateTime, 28 - 1).setSeconds(0, 0)); // wave chart 표시 마지막 데이터의 일시
  const firstTableDatetime = subMinutes(lastTableDatetime, 7);
  const to = addSeconds(new Date(baseDateTime), sec - 1); // wave 출력 요청 마지막 데이터의 일시
  const from = subMinutes(to, 7);
  const deviceData = useTrendData(id, from, to);
  const { filledData } = usePeriodFilledData(deviceData, 'date_time', {
    from: firstTableDatetime,
    to: isBefore(to, lastTableDatetime) ? to : lastTableDatetime,
  });

  return <TrendTable data={filledData} tableFormatRules={tableFormatRules} />;
};

interface Props {
  id: string;
  pageId: string;
  page?: number;
  baseDateTime: Date;
  sec?: number;
  headerData: HeaderData;
}
// report
const EcgWave28secPage = ({ id, pageId, page = 1, baseDateTime, sec = 28, headerData }: Props) => {
  const { convertMmToPx } = useA4Page({});

  const { data: waveDetailData } = useWaveDetail(sec, id, new Date(baseDateTime));
  const data = useMemo(() => {
    const samplingRate = Number(waveDetailData?.data[0]?.ecg_wave?.ecg_wave_ii_hz || 240);
    const resolution = Number(waveDetailData?.data[0]?.ecg_wave?.ecg_wave_ii_resolution || 150);

    const apiData = waveDetailData?.data;
    if (!apiData) {
      return null;
    }
    const data =
      Array.from({ length: 28 }, (_, index) => {
        const targetTime = addSeconds(baseDateTime, index);
        const targetData =
          apiData.find((data) => isEqualBySeconds(targetTime, parseUTC0String(data.date_time)))?.ecg_wave.ecg_wave_ii ||
          Array.from({ length: samplingRate }, () => NaN);

        return { time: targetTime, data: targetData.slice(0, samplingRate) };
      })
        .map(({ time, data }) => {
          const timeStamp = time.getTime();
          const miliGap = Math.floor(1000 / samplingRate) || 1;
          const tmp = data.map((ecg, j) => ({ x: timeStamp + j * miliGap, y: ecg }));
          return tmp;
        })
        .flat() || [];

    return { samplingRate, data, resolution };
  }, [baseDateTime, waveDetailData?.data]);

  return (
    <A4Container page={page} pageId={pageId}>
      <Box w={convertMmToPx(175.5)}>
        <ReportHeader headerData={headerData} />
      </Box>
      <Box
        border={`solid ${1}px black`}
        borderTopWidth={0}
        w="max-content"
        h="max-content"
        boxSizing="border-box"
        pb={`${convertMmToPx(10)}px`}
      >
        {/* Ecg Wave */}
        <EcgWave28sec {...data} label={WAVE_PARAMS.ecg_wave_ii.label} page={page} />
        {/* Trend Table: 이전 7분 */}
        <TrendTableBefore7min id={id} baseDateTime={baseDateTime} sec={sec} />
      </Box>
    </A4Container>
  );
};

export default memo(EcgWave28secPage);
