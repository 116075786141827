import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Select: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        borderRadius: 'lg',
        borderColor: 'border.500',
        color: 'gray.900',
      },
    },
    noOutline: {
      field: {
        color: 'white',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'border.500',
  },
};

export default Select;
