import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { ReqAdmitPatient, ReqDeviceUpdate, ReqDischargePatient, ReqPatientInfo } from '@/apis/patient/type';
import { patients, devices } from '@/mock/data';

import { TPatientInfo, TConnectionInfo } from '../patientInfoStore/type';

interface MockData {
  mockPatient: (TPatientInfo & { isAdmit: boolean })[];
  setMockPatient: (patients: (TPatientInfo & { isAdmit: boolean })[]) => void;
  connectionInfo: TConnectionInfo;
  setConnectionInfo: (info: TConnectionInfo) => void;
  admitConnectionInfo: (admitInfo: ReqAdmitPatient, patientInfo: TPatientInfo | undefined) => void;
  dischargeConnectionInfo: (dischargeInfo: ReqDischargePatient) => void;
  mockPatientUpdate: (req: ReqPatientInfo) => void;
  mockPatientReg: (req: ReqPatientInfo) => void;
  mockDeviceAliasUpdate: (req: ReqDeviceUpdate) => void;
  resetMockData: () => void;
  mockDeviceAlias: { [key: string]: string };
  updateDeviceAlias: (alias: string, id: string) => void;
}

export const initPatientInfo = {
  eat: '',
  pat: '',
  patId: '',
  patLastName: '',
  patFirstName: '',
  patBirthdate: '',
  patBirthdateFormatCode: '',
  sex: '',
  race: '',
  otherInfo: '',
  ssn: '',
};

const initConnectionInfo = devices.reduce((acc: TConnectionInfo, curr) => {
  acc[curr.getKey()] = {
    bedId: curr.getDeviceData().bed_id,
    admitDatetime: '',
    dischargeDatetime: '',
    dat: curr.getDeviceData().dat,
    deviceAlias: '',
    aat: curr.getDeviceData().aat,
    deviceActiveYn: 'Y',
    ...initPatientInfo,
    isRoxNoti: false,
  };
  return acc;
}, {});

const initDeviceAlias = devices.reduce((acc: { [key: string]: string }, curr, index) => {
  acc[curr.getKey()] = `BED ${index + 1}`;
  return acc;
}, {});

// // disconnected device
export const disconnectDeviceKey = '01//MP1300//DS00_33';
const disconnectedDeviceAlias = 'BED 33';
initConnectionInfo[disconnectDeviceKey] = {
  bedId: 'ER-33',
  admitDatetime: '',
  dischargeDatetime: '',
  dat: null,
  deviceAlias: disconnectedDeviceAlias,
  aat: null,
  deviceActiveYn: 'Y',
  isRoxNoti: false,
  ...initPatientInfo,
};
initDeviceAlias[disconnectDeviceKey] = disconnectedDeviceAlias;

export const useMockDataStore = create<MockData>()(
  devtools(
    persist(
      (set, get) => ({
        mockPatient: [...patients],
        setMockPatient: (patients) => set(() => ({ mockPatient: patients })),
        connectionInfo: initConnectionInfo,
        setConnectionInfo: (info) => set(() => ({ connectionInfo: info })),
        admitConnectionInfo: (req, patientInfo) => {
          const { connectionInfo, mockPatient } = get();
          const tempInfo = connectionInfo;
          Object.keys(tempInfo).forEach((key) => {
            if (tempInfo[key].dat === req.dat) {
              tempInfo[key].admitDatetime = req.start;
              tempInfo[key].dischargeDatetime = null;
              tempInfo[key] = { ...tempInfo[key], ...patientInfo };
            }
          });
          const newPatient = mockPatient.map((item) => {
            if (item.pat === req.pat) return { ...item, isAdmit: true };
            return item;
          });
          set(() => ({ connectionInfo: tempInfo, mockPatient: newPatient }));
        },
        dischargeConnectionInfo: (req) => {
          const { connectionInfo, mockPatient } = get();
          const tempInfo = connectionInfo;
          let pat: string | null = '';
          Object.keys(tempInfo).forEach((key) => {
            if (tempInfo[key].aat === req.aat) {
              tempInfo[key].dischargeDatetime = '';
              tempInfo[key].admitDatetime = '';
              pat = tempInfo[key].pat;
              tempInfo[key] = { ...tempInfo[key], ...initPatientInfo };
            }
          });
          const newPatient = mockPatient.map((item) => {
            if (item.pat === pat) return { ...item, isAdmit: false };
            return item;
          });
          set(() => ({ connectionInfo: tempInfo, mockPatient: newPatient }));
        },
        mockPatientUpdate: (req) => {
          const { mockPatient, connectionInfo } = get();
          const newPatient = mockPatient.map((item) => {
            if (item.pat === req.pat) return { ...req, isAdmit: item.isAdmit };
            return item;
          });
          const tempInfo = connectionInfo;
          Object.keys(tempInfo).forEach((key) => {
            if (tempInfo[key].pat === req.pat) {
              tempInfo[key] = { ...tempInfo[key], ...req };
            }
          });
          set(() => ({ mockPatient: newPatient, connectionInfo: tempInfo }));
        },
        mockPatientReg: (req) => {
          const { mockPatient } = get();
          const newPatient = { ...req, isAdmit: false, pat: req.patId };
          set(() => ({ mockPatient: [...mockPatient, newPatient] }));
        },
        mockDeviceAliasUpdate: (req) => {
          const { connectionInfo, mockDeviceAlias } = get();
          const tempInfo = connectionInfo;
          const tempAlias = mockDeviceAlias;
          Object.keys(tempInfo).forEach((key) => {
            if (tempInfo[key].dat === req.dat) {
              tempInfo[key].deviceAlias = req.deviceAlias;
              tempAlias[key] = req.deviceAlias;
            }
          });
          set(() => ({ connectionInfo: tempInfo, mockDeviceAlias: tempAlias }));
        },
        resetMockData: () =>
          set(() => ({ mockPatient: patients, connectionInfo: initConnectionInfo, mockDeviceAlias: initDeviceAlias })),
        mockDeviceAlias: initDeviceAlias,
        updateDeviceAlias: (alias, id) => {
          const { mockDeviceAlias } = get();
          set(() => ({ mockDeviceAlias: { ...mockDeviceAlias, [id]: alias } }));
        },
      }),
      { name: 'mock-data', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);
