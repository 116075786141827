import { TDevice } from '@/constants/types';
import { DEVICE_MODEL } from '@/pages/CentralMain/types';

export const makeTDevice = (device: string) => {
  let findParamSetting: TDevice;
  if (device === DEVICE_MODEL.MV50) {
    findParamSetting = 'MV50';
  } else if (device === DEVICE_MODEL.MV2000) {
    findParamSetting = 'MV2000';
  } else if (device === DEVICE_MODEL.MP1000NTP || device === DEVICE_MODEL.MP1300 || device === DEVICE_MODEL.MP800) {
    findParamSetting = 'MP1300';
  } else {
    findParamSetting = 'HFT700';
  }

  return findParamSetting;
};
