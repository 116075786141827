/* eslint-disable camelcase */
import { DEVICE_MODEL, DeviceModel } from '../../types';

// constants
export const HFT_TOTAL_TREND_PARAMETERS = {
  rox: 'rox',
  sf_ratio: 'sf_ratio',
  rr: 'rr',
  fio2: 'fio2',
  flow: 'flow',
  pi: 'pi',
  peep: 'peep',
  spo2: 'spo2',
  pr: 'pr',
  estimated_tidal_volume: 'estimated_tidal_volume',
  pip: 'pip',
  v_delivery: 'v_delivery',
} as const;
export const MP_TOTAL_TREND_PARAMETERS = {
  hr: 'hr',
  spo2: 'spo2',
  tmp1: 'tmp1',
  tmp2: 'tmp2',
  rr: 'rr',
  ibp1_s: 'ibp1_s',
  ibp1_d: 'ibp1_d',
  ibp1_m: 'ibp1_m',
  ibp2_s: 'ibp2_s',
  ibp2_d: 'ibp2_d',
  ibp2_m: 'ibp2_m',
  nibp_s: 'nibp_s',
  nibp_d: 'nibp_d',
  nibp_m: 'nibp_m',
  etco2: 'etco2',
  fico2: 'fico2',
} as const;
export const MV50_TOTAL_TREND_PARAMETERS = {
  ppeak: 'ppeak',
  ppause: 'ppause',
  pmean: 'pmean',
  peep: 'peep',
  rr: 'rr',
  rr_spont: 'rr_spont',
  etco2_rr: 'etco2_rr', // ?:etco2
  vti: 'vti',
  vte: 'vte',
  vme: 'vme',
  fio2: 'fio2',
  spo2: 'spo2',
  ti: 'ti',
  te: 'te',
  tc: 'tc',
  flow: 'flow',
  fi_peak: 'fi_peak',
  fe_peak: 'fe_peak',
  peep_h: 'peep_h',
  peep_l: 'peep_l',
  vico2: 'vico2',
  veco2: 'veco2',
  vmeco2: 'vmeco2',
  pi: 'pi',
  pr: 'pr',
  rsbi: 'rsbi',
  wobv: 'wobv',
  cl: 'cl',
  ra: 'ra',
} as const;
export const MV2000_TOTAL_TREND_PARAMETERS = {
  pmean: 'pmean',
  ppeak: 'ppeak',
  peep: 'peep',
  vte: 'vte',
  rr: 'rr',
  vme: 'vme',
} as const;

// types
export type TrendValue = number | null;
type HFTTrendParameter = (typeof HFT_TOTAL_TREND_PARAMETERS)[keyof typeof HFT_TOTAL_TREND_PARAMETERS];
export type HFTTrendData = { [key in HFTTrendParameter]: TrendValue };
type MPTrendParameter = (typeof MP_TOTAL_TREND_PARAMETERS)[keyof typeof MP_TOTAL_TREND_PARAMETERS];
export type MPTrendData = { [key in MPTrendParameter]: TrendValue };
type MV50TrendParameter = (typeof MV50_TOTAL_TREND_PARAMETERS)[keyof typeof MV50_TOTAL_TREND_PARAMETERS];
export type MV50TrendData = { [key in MV50TrendParameter]: TrendValue };
type MV2000TrendParameter = (typeof MV2000_TOTAL_TREND_PARAMETERS)[keyof typeof MV2000_TOTAL_TREND_PARAMETERS];
export type MV2000TrendData = { [key in MV2000TrendParameter]: TrendValue };
export type TTrendData = HFTTrendData | MPTrendData | MV2000TrendData | MV50TrendData;

export type TrendData<D extends DeviceModel> = D extends typeof DEVICE_MODEL.HFT700 | typeof DEVICE_MODEL.HFT750
  ? HFTTrendData
  : D extends typeof DEVICE_MODEL.MP800 | typeof DEVICE_MODEL.MP1000NTP | typeof DEVICE_MODEL.MP1300
  ? MPTrendData
  : D extends typeof DEVICE_MODEL.MV2000
  ? MV2000TrendData
  : D extends typeof DEVICE_MODEL.MV50
  ? MV50TrendData
  : never;

export type ResTotalTrendData<D extends DeviceModel> = {
  from: string;
  to: string;
  manufacturerCode: string;
  deviceModel: D;
  deviceSerial: string;
  mpData: (MPTrendData & { date_time: string })[] | null;
  mv50Data: (MV50TrendData & { date_time: string })[] | null;
  mv2000Data: (MV2000TrendData & { date_time: string })[] | null;
  hftData: (HFTTrendData & { date_time: string })[] | null;
};

export const ECG_PARAMETER_KEYS = [
  'ecg_wave_avf',
  'ecg_wave_avl',
  'ecg_wave_avr',
  'ecg_wave_i',
  'ecg_wave_ii',
  'ecg_wave_iii',
  'ecg_wave_v',
] as const;
export const IBP_PARAMETER_KEYS = ['ibp1_wave', 'ibp2_wave'] as const;
export type WaveOverview = {
  ecg_wave: {
    [k in (typeof ECG_PARAMETER_KEYS)[number]]: number[];
  } & { ecg_wave_ii_hz: string };
  ibp_wave: {
    [k in (typeof IBP_PARAMETER_KEYS)[number]]: number[];
  } & {
    ibp1_wave_hz: string;
    ibp2_wave_hz: string;
  };
  resp_wave: number[];
  resp_wave_hz: string;
  spo2_wave: number[];
  spo2_wave_hz: string;
};
// Amy: 일단은 MP 만
export type ResWaveOverview = {
  from: string;
  to: string;
  manufacturerCode: string;
  deviceModel: DeviceModel;
  deviceSerial: string;
  data: ({
    date_time: string;
  } & WaveOverview)[];
};
