import { useMemo } from 'react';

import { useNibpTrendByPeriod, useTrendAllByPeriod } from '@/apis/patient/queries';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { sliceUtc0StringToMinute } from '@/utils/dateTimeUtils';

export const useTrendData = (dataId: string, from: Date, to: Date) => {
  const { model } = parseDataId(dataId);

  const { data: nibpData } = useNibpTrendByPeriod(dataId, from, to);
  const { data: allParamData } = useTrendAllByPeriod(dataId, from, to);
  const deviceData = useMemo(() => {
    switch (model) {
      case 'HFT700':
      case 'HFT750':
        return allParamData?.hftData || [];
      case 'MV50':
        return allParamData?.mv50Data || [];
      case 'MV2000':
        return allParamData?.mv2000Data || [];
      case 'MP800':
      case 'MP1300':
      case 'MP1000NTP':
        return (
          allParamData?.mpData?.map((data) => {
            const nibp = nibpData?.data
              .filter((nibp) => sliceUtc0StringToMinute(nibp.date_time) === sliceUtc0StringToMinute(data.date_time))
              .at(-1);
            if (nibp) {
              return { ...data, nibp_d: nibp.nibp_d, nibp_m: nibp.nibp_m, nibp_s: nibp.nibp_s };
            }
            return { ...data, nibp_d: null, nibp_m: null, nibp_s: null };
          }) || []
        );
      default:
        return [];
    }
  }, [
    allParamData?.hftData,
    allParamData?.mpData,
    allParamData?.mv2000Data,
    allParamData?.mv50Data,
    model,
    nibpData?.data,
  ]);
  return deviceData;
};
