import { PropsWithChildren, useEffect } from 'react';

import { useColorMode } from '@chakra-ui/system';

export const ColorProvider = ({ children }: PropsWithChildren) => {
  // Amy: Chakra UI의 useColorMode 훅을 사용하여 현재 색상 모드를 가져옵니다.
  const { setColorMode } = useColorMode();

  // Amy: 로컬 스토리지 변경을 감지하고, 변경된 값으로 색상 모드를 설정합니다.
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'chakra-ui-color-mode') {
        setColorMode(e.newValue || 'light');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setColorMode]);

  return <>{children}</>;
};
