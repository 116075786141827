import { ComponentStyleConfig } from '@chakra-ui/react';

export const H1: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '50px',
    color: `gray.900`,
  },
};

export const H2: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: '700',
    color: `gray.900`,
  },
};

export const H3: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '700',
    color: `gray.900`,
  },
};

export const H4: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '700',
    color: `gray.900`,
  },
};
