import { ReactElement } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

interface QueryProps {
  children: ReactElement;
}

const QueryProvider = ({ children }: QueryProps): ReactElement => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        networkMode: 'always', // for offline demo
      },
      mutations: {
        networkMode: 'always',
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default QueryProvider;
