import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { demoSetting } from '@/mock/utils/getTagetHospitalSetting';
import { DisplayMode, ViewRole, ViewData } from '@/pages/CentralMain/types';

import { withStorageDOMEvents } from '../utils/withStorageDOMEvents';

type WindowStatus = 'open' | 'close';

type State = {
  viewData: ViewData;
  displayMode: DisplayMode;
  lastWindowSetting: { [key in ViewRole]: WindowStatus };
  isViewDataSorted: boolean;
};
interface Actions {
  getViewData: (viewRole: ViewRole) => string[];
  getAllDisplayedDataKey: () => string[];
  setViewData: (viewRole: ViewRole, newData: string[], isSorted?: boolean) => void;
  setViewDataBoth: (mainData: string[], viewData: string[], isSorted?: boolean) => void;
  addViewData: (viewRole: ViewRole, dataId: string) => void;
  deleteViewData: (viewRole: ViewRole, dataId: string) => void;
  moveViewData: ({
    viewRoleFrom,
    viewRoleTo,
    dataId,
  }: {
    viewRoleFrom: ViewRole;
    viewRoleTo: ViewRole;
    dataId: string;
  }) => void;
  setDisplayMode: (displayMode: DisplayMode) => void;
  registerLastWindowSetting: (value: { [key in ViewRole]: WindowStatus }) => boolean;
  setSignageMode: () => void;
  resetViewDate: () => void;
  setIsViewDataSorted: (isViewDataSorted: boolean) => void;
}

const initialState: State = {
  viewData: demoSetting?.viewData || {
    main: [],
    viewOnly: [],
  },
  displayMode: demoSetting?.displayMode || 'simple',
  lastWindowSetting: { main: 'open', viewOnly: 'close' },
  isViewDataSorted: false,
};

const useViewControlStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        getViewData: (viewRole) => {
          const { viewData } = get();
          if (viewRole === 'main') {
            return viewData.main;
          }
          if (viewRole === 'viewOnly') {
            return viewData.viewOnly;
          }
          throw new Error('unknown view role');
        },
        getAllDisplayedDataKey: () => {
          const { viewData } = get();
          return [...viewData.main, ...viewData.viewOnly];
        },
        setViewData: (viewRole, newData, isSorted) => {
          set((state) => ({
            ...state,
            viewData: { ...state.viewData, [viewRole]: newData },
            isViewDataSorted: isSorted || false,
          }));
        },
        setViewDataBoth: (mainData, viewData, isSorted) => {
          set((state) => ({
            ...state,
            viewData: { main: mainData, viewOnly: viewData },
            isViewDataSorted: isSorted || false,
          }));
        },
        addViewData: (viewRole, dataId) => {
          const { viewData } = get();
          const isAlreadyExist = viewData[viewRole].find((prevData) => prevData === dataId);
          if (isAlreadyExist) return;
          const newViewData = [...viewData[viewRole], dataId];
          set((state) => ({
            ...state,
            viewData: { ...state.viewData, [viewRole]: newViewData },
            isViewDataSorted: false,
          }));
        },
        deleteViewData: (viewRole: ViewRole, dataId: string) => {
          const { viewData } = get();
          const targetData = viewData[viewRole].find((data) => data === dataId);
          if (!targetData) return;
          const newViewData = [...viewData[viewRole]].filter((prevData) => prevData !== dataId);
          set((state) => ({
            ...state,
            viewData: { ...state.viewData, [viewRole]: newViewData },
            isViewDataSorted: false,
          }));
        },
        moveViewData: ({ viewRoleFrom, viewRoleTo, dataId }) => {
          if (viewRoleFrom === viewRoleTo) return;
          const { viewData } = get();
          const viewDataFrom = viewData[viewRoleFrom];
          const targetData = viewDataFrom.find((data) => data === dataId);
          if (!targetData) return;
          const newViewDataFrom = viewDataFrom.filter((prevData) => prevData !== dataId);
          const newViewDataTo = [...viewData[viewRoleTo], dataId];
          set((state) => ({
            ...state,
            viewData: { ...state.viewData, [viewRoleFrom]: newViewDataFrom, [viewRoleTo]: newViewDataTo },
            isViewDataSorted: false,
          }));
        },
        setDisplayMode: (displayMode) => {
          set((state) => ({ ...state, displayMode }));
        },
        registerLastWindowSetting: (value) => {
          set((state) => ({ ...state, lastWindowSetting: value }));
          return true;
        },
        setSignageMode: () => {
          set((state) => ({
            ...state,
            viewData: { main: [...state.viewData.main, ...state.viewData.viewOnly], viewOnly: [] },
            lastWindowSetting: { ...state.lastWindowSetting, viewOnly: 'close' as WindowStatus },
          }));
        },
        resetViewDate: () => {
          set(() => ({
            viewData: initialState.viewData,
            isViewDataSorted: false,
            displayMode: initialState.displayMode,
          }));
        },
        setIsViewDataSorted: (isViewDataSorted: boolean) => {
          set((prev) => ({
            ...prev,
            isViewDataSorted,
          }));
        },
      }),
      { name: 'view_control_store', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useViewControlStore);
export default useViewControlStore;
