import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Checkbox: ComponentStyleConfig = {
  baseStyle: () => ({
    control: {
      borderRadius: '8px',
      w: '32px',
      h: '32px',
      bg: 'white',
      borderColor: 'gray',
      // border: 'none',
      _checked: {
        // bg: '#26282B',
        borderColor: '#C8C6D0',
        // color: '#ffffff',
        _hover: {
          border: 'none',
          color: '#ffffff',
        },
      },
    },
  }),
  variants: {
    mini: {
      bg: 'white',
      _checked: {
        bg: '#26282B',
        borderColor: '#26282B',
      },
      control: {
        border: '1px',
        w: '18px',
        h: '18px',
        borderRadius: '0',
        borderColor: '#26282B',
      },
    },
    medium: {
      bg: 'white',
      _checked: {
        bg: '#26282B',
        borderColor: '#26282B',
      },
      control: {
        borderRadius: '5px',
        w: '20px',
        h: '20px',
        bg: 'white',
        // border: 'none',
        _checked: {
          // bg: '#26282B',
          borderColor: '#C8C6D0',
          // color: '#ffffff',
          _hover: {
            border: 'none',
            color: '#ffffff',
          },
        },
      },
    },
  },
  sizes: {
    xl: { control: { w: 26, h: 26 } },
    sm: { control: { w: 10, h: 10 } },
  },
  defaultProps: {},
};

export default Checkbox;
