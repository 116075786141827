import { shadeColor } from '../utils/shadeColor';

const colors = {
  brand: {
    50: '#EDEBFA',
    100: '#DCD7F4',
    200: '#B8AFE9',
    300: '#9587DE',
    400: '#725FD3',
    500: '#725FD3',
    600: shadeColor('#3F2CA1', -15),
  },
  border: {
    500: '#C8C6D0',
    600: shadeColor('#C8C6D0', -15),
  },
  gray: {
    50: '#F6F6F8',
    100: '#EBEAEE',
    300: '#C8C6D0',
    500: '#AEADB5',
    700: '#7E7D83',
    900: '#2E2E34',
  },
  black: {
    600: '#1A202C',
  },
  cyan: {
    500: '#3ACCD6',
    600: shadeColor('#3ACCD6', -15),
  },
  success: {
    50: '#E6FAF3',
    500: '#29CC97',
    600: shadeColor('#29CC97', -15),
  },
  critical: {
    500: '#DA292F',
    600: shadeColor('#DA292F', -15),
  },
  warning: {
    500: '#FF9922',
    600: shadeColor('#FF9922', -15),
  },
  warning2: {
    500: '#FAE07A',
    600: shadeColor('#FAE07A', -15),
  },
  purple: {
    400: '#675CA1',
    500: '#543DAB',
  },
  blue: {
    500: '#3F82EF',
  },
  primary: {
    100: '#2e3438',
    200: '#d3e0f5',
  },
  buttonBg: {
    100: '#73828C',
    200: '#638fd6',
  },
  buttonColor: {
    100: 'white',
    200: 'white',
  },
  errorTextColor: {
    100: '#AEADB5',
    200: '#7E7D83',
  },
  headerBg: {
    100: '#5C6870',
    200: '#5773f2',
  },
  dataGridBg: {
    100: '#1e1e1e',
    200: '#3d57cc',
  },
};

export default colors;
