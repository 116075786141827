const breakPoints = ['base', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'] as const;
type BreakPoints = Record<(typeof breakPoints)[number], string>;
export type BreakPointsObject = Partial<BreakPoints>;

export const breakpoints: BreakPointsObject = {
  xs: '480px',
  sm: '768px',
  md: '896px',
  lg: '1200px',
  xl: '1920px', // FHD
  '2xl': '2560px', // QHD
  '3xl': '3840px', // 4K
};

export const sizes: { container: BreakPointsObject } = {
  container: {
    sm: breakpoints.sm,
    md: breakpoints.md,
    lg: breakpoints.lg,
    xl: breakpoints.xl,
  },
};
