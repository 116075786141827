import { useMemo } from 'react';

import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { addMinutes, format } from 'date-fns';
import { LineChart, XAxis, YAxis, Line, CartesianGrid, Bar, BarChart, Cell, Tooltip } from 'recharts';

import { useNibpTrendByPeriod, useTrendAllByPeriod } from '@/apis/patient/queries';
import { useA4Page } from '@/hooks/usePdf';
import { makeLocalTimeToUTC0 } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { isInPeriod, parseUTC0String } from '@/utils/dateTimeUtils';

const CHART_WIDTH_IN_MM = 185;

type NibpSingleData = {
  dt: number | null;
  smd: (number | null)[];
};

const NibpStick = ({ x, y, height, width, smd }: any) => {
  const [sys, mean, dias] = smd;
  const targetX = x + width / 2;
  const ratio = height / Math.abs(sys - mean);

  return (
    <g stroke="black" fill="none" strokeWidth="2">
      {/* sys */}
      <path
        d={`
            M ${targetX - 7}, ${y + height}
            H ${targetX + 7}
          `}
      />
      {/* mean */}
      <path
        d={`
            M ${targetX - 5}, ${y}
            H ${targetX + 5}
          `}
      />
      {/* dias */}
      <path
        d={`
            M ${targetX - 7}, ${y - Math.abs(dias - mean) * ratio}
            H ${targetX + 7}
          `}
      />
      <path
        d={`
            M ${targetX},${y + height}
            L ${targetX},${y - Math.abs(dias - mean) * ratio}
          `}
      />
    </g>
  );
};
const NibpChart = ({ data, xDomain }: { data: NibpSingleData[]; xDomain: number[] }) => {
  // console.debug(data);
  const { convertMmToPx } = useA4Page({});
  return (
    <>
      <BarChart
        width={convertMmToPx(CHART_WIDTH_IN_MM)}
        height={convertMmToPx(20)}
        margin={{ top: 20, right: 5, left: 0, bottom: 20 }}
        data={[...data]}
      >
        <XAxis dataKey="dt" type="number" domain={xDomain} hide />
        <YAxis domain={[0, 300]} ticks={[0, 300]} strokeWidth={0} fontSize={`${convertMmToPx(3)}px`} fontWeight={500} />
        {/* Min, Max Grid */}
        <CartesianGrid
          strokeWidth="1px"
          stroke="#718096"
          strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
          vertical={false}
        />
        <Bar dataKey="smd" fill="#8884d8" shape={<NibpStick dataKey="smd" />} isAnimationActive={false}>
          {data.map((_, index) => (
            <Cell key={`cell-${index.toString()}`} />
          ))}
        </Bar>
      </BarChart>
    </>
  );
};

type ChartData = {
  deviceData: { [key in string]: ({ x: number; y: number | null } | NibpSingleData)[] };
  period: { from: Date; to: Date };
};

// config
const MP_CHART_PARAMETERS = ['hr', 'spo2', 'rr', 'nibp', 'tmp1'];
const chartConfig: {
  [key in string]?: {
    domain: { min: number; max: number };
    label: string;
    hInMm: number;
  };
} = {
  hr: { label: 'HR', domain: { min: 0, max: 300 }, hInMm: 20 },
  spo2: { label: 'SpO₂', domain: { min: 0, max: 100 }, hInMm: 15 },
  nibp: { label: 'NIBP', domain: { min: 0, max: 300 }, hInMm: 20 },
  rr: { label: 'RR', domain: { min: 0, max: 150 }, hInMm: 15 },
  tmp1: { label: 'TEMP', domain: { min: 0, max: 50 }, hInMm: 15 },
};

interface Props {
  id: string;
  from: Date;
  to: Date;
}
const MpTrend = ({ id, from, to }: Props) => {
  const { convertMmToPx } = useA4Page({});

  const { data: nibpData } = useNibpTrendByPeriod(id, from, addMinutes(to, 1));
  const { data: allParamData } = useTrendAllByPeriod(id, from, addMinutes(to, 1));
  const { deviceData }: ChartData = useMemo(() => {
    if (!nibpData || !allParamData) {
      return { deviceData: { nibp: [] }, period: { from, to } };
    }
    const { model } = parseDataId(id);
    const getTargetData = (): Array<any> => {
      switch (model) {
        case 'HFT700':
        case 'HFT750':
          return allParamData?.hftData || [];
        case 'MV50':
          return allParamData?.mv50Data || [];
        case 'MV2000':
          return allParamData?.mv2000Data || [];
        case 'MP800':
        case 'MP1300':
        case 'MP1000NTP':
          return allParamData?.mpData || [];
        default:
          return [];
      }
    };
    const makeNumber = (data: string | number | null) => (data === null ? null : Number(data));

    const parseDataSet = (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
      objs?: ({ [key in string]: string | number | null } & { date_time: string })[],
    ): { dataMap: { [key in string]: { x: number; y: number | null }[] }; period: { from: Date; to: Date } } => {
      if (!objs || objs.length === 0) {
        return { dataMap: {}, period: { from, to } };
      }
      const dataMap: { [key in string]: { x: number; y: number | null }[] } = {};

      const keys = Object.keys(objs[0]).filter((key) => key !== 'date_time' || key.includes('nibp'));
      keys.forEach((key) => {
        dataMap[key] = [];
      });

      objs.forEach((obj) => {
        const x = parseUTC0String(obj.date_time).setSeconds(0, 0);
        if (isInPeriod(x, { from, to })) {
          keys.forEach((key) => {
            const y = makeNumber(obj[key]);
            dataMap[key].push({ x, y });
          });
        }
      });
      const period = {
        from: new Date(parseUTC0String(objs.at(0)?.date_time || makeLocalTimeToUTC0(from)).setSeconds(0, 0)),
        to: new Date(parseUTC0String(objs.at(-1)?.date_time || makeLocalTimeToUTC0(to)).setSeconds(0, 0)),
      };

      return { dataMap, period };
    };
    const parseNibpDateSet = (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
      objs?: ({ [key in string]: string | number | null } & { date_time: string })[],
    ): { nibp: NibpSingleData[] } => {
      if (!objs || objs.length === 0) {
        return { nibp: [] };
      }
      const formattedData: NibpSingleData[] = [];
      objs.forEach((obj) => {
        const x = parseUTC0String(obj.date_time).getTime();
        if (isInPeriod(x, { from, to })) {
          formattedData.push({
            dt: x,
            smd: [makeNumber(obj.nibp_s), makeNumber(obj.nibp_m), makeNumber(obj.nibp_d)],
          });
        }
      });

      return { nibp: formattedData };
    };
    const nibp = parseNibpDateSet(nibpData?.data || []);

    const { dataMap, period } = parseDataSet(getTargetData());

    return { deviceData: { ...dataMap, ...nibp }, period };
  }, [allParamData, from, id, nibpData, to]);

  const xDomain = useMemo(() => {
    const dataFrom = from;
    const dataTo = addMinutes(dataFrom, 15);
    return [dataFrom.getTime(), dataTo.getTime()];
  }, [from]);

  return (
    <>
      <Box pos="relative" w="100%" px={`${convertMmToPx(10)}px`}>
        {MP_CHART_PARAMETERS.map((dataKey, index) => {
          const data = deviceData[dataKey] ? deviceData[dataKey] : [];

          const domain = chartConfig[dataKey]
            ? [chartConfig[dataKey]?.domain.min || 0, chartConfig[dataKey]?.domain.max || 100]
            : undefined;

          return (
            <Box key={dataKey}>
              {/* title */}
              <Text fontSize={`${convertMmToPx(3)}px`} fontWeight={500} mb={`${convertMmToPx(1)}px`}>
                {chartConfig[dataKey]?.label}
              </Text>
              {/* Chart */}
              <Flex w="full" justify="flex-end">
                {dataKey === 'nibp' && (
                  <NibpChart key="nibp" data={data as unknown as NibpSingleData[]} xDomain={xDomain} />
                )}
                {dataKey !== 'nibp' && (
                  <LineChart
                    width={convertMmToPx(CHART_WIDTH_IN_MM)}
                    height={convertMmToPx(chartConfig[dataKey]?.hInMm || 20)}
                    margin={{ top: 20, right: 5, left: 0, bottom: 20 }}
                    data={data || []}
                  >
                    {/* Axis */}
                    <XAxis dataKey="x" type="number" hide domain={xDomain} />
                    <YAxis
                      dataKey="y"
                      domain={domain}
                      ticks={domain}
                      strokeWidth={0}
                      fontSize={`${convertMmToPx(3)}px`}
                      fontWeight={500}
                    />
                    {/* Min, Max Grid */}
                    <CartesianGrid
                      strokeWidth="1px"
                      stroke="#718096"
                      strokeDasharray={`${convertMmToPx(0.5)} ${convertMmToPx(0.5)}`}
                      vertical={false}
                    />
                    {/* Line */}
                    <Line type="linear" dataKey="y" stroke="#000" dot={false} isAnimationActive={false} />
                  </LineChart>
                )}
              </Flex>
              {index === MP_CHART_PARAMETERS.length - 1 && (
                <HStack
                  w={convertMmToPx(CHART_WIDTH_IN_MM - 7)}
                  justify="space-between"
                  ml={convertMmToPx(3)}
                  mt={convertMmToPx(1)}
                  fontSize={`${convertMmToPx(3)}px`}
                >
                  <Box>{format(xDomain[0], 'yyyy-MM-dd HH:mm')}</Box>
                  <Box>{format((xDomain[0] + xDomain[1]) / 2, 'yyyy-MM-dd HH:mm')}</Box>
                  <Box>{format(xDomain[1], 'yyyy-MM-dd HH:mm')}</Box>
                </HStack>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default MpTrend;
