import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '../utils/withStorageDOMEvents';

type State = {
  isReset: boolean;
  resetViewId: string | null;
};

interface Actions {
  setIsRest: (reset: boolean, resetViewId: string) => void;
}

const initialState: State = {
  isReset: false,
  resetViewId: null,
};

const useResetStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setIsRest: (reset: boolean, resetViewId: string) => {
          set((state) => ({ ...state, isReset: reset, resetViewId }));
        },
      }),
      { name: 'reset-store', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useResetStore);
export default useResetStore;
