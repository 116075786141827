import { TDevice } from '@/constants/types';
import { WaveProperty } from '@/pages/CentralMain/types';

export const waveProperty: { [K in TDevice]: WaveProperty[] } = {
  HFT700: [
    {
      signal_name: 'flow_wave',
      hz: '50',
      digitize_value: null,
      min: '-30',
      max: '60',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'pressure_wave',
      hz: '300',
      digitize_value: null,
      min: '0',
      max: '50',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'patient_flow_wave',
      hz: '50',
      digitize_value: null,
      min: '0',
      max: '2000',
      auto_range: 'false',
      baseline: '0',
    },
  ],
  MV50: [
    {
      signal_name: 'spo2_wave',
      hz: '150',
      digitize_value: null,
      min: '-900',
      max: '900',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'flow_wave',
      hz: '100',
      digitize_value: null,
      min: '-30',
      max: '60',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'pressure_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '2000',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'volume_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '50',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'etco2_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '500',
      auto_range: 'true',
      baseline: '0',
    },
  ],
  MV2000: [
    {
      signal_name: 'spo2_wave',
      hz: '150',
      digitize_value: null,
      min: '-900',
      max: '900',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'flow_wave',
      hz: '100',
      digitize_value: null,
      min: '-30',
      max: '60',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'pressure_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '2000',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'volume_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '50',
      auto_range: 'true',
      baseline: '0',
    },
    {
      signal_name: 'etco2_wave',
      hz: '100',
      digitize_value: null,
      min: '0',
      max: '500',
      auto_range: 'true',
      baseline: '0',
    },
  ],
  MP1300: [
    {
      signal_name: 'ecg_wave_iii',
      hz: '240',
      digitize_value: null,
      min: '-50',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_i',
      hz: '240',
      digitize_value: null,
      min: '-50',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'spo2_wave',
      hz: '150',
      digitize_value: null,
      min: '-900',
      max: '900',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_ii',
      hz: '240',
      digitize_value: null,
      min: '-50',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ibp3_wave',
      hz: '150',
      digitize_value: null,
      min: '600',
      max: '1400',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'resp_wave',
      hz: '120',
      digitize_value: null,
      min: '-150',
      max: '150',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ibp1_wave',
      hz: '150',
      digitize_value: null,
      min: '600',
      max: '1400',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_avr',
      hz: '240',
      digitize_value: null,
      min: '-200',
      max: '50',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ibp4_wave',
      hz: '150',
      digitize_value: null,
      min: '600',
      max: '1400',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_avf',
      hz: '240',
      digitize_value: null,
      min: '-50',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_avl',
      hz: '240',
      digitize_value: null,
      min: '-50',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ecg_wave_v',
      hz: '240',
      digitize_value: null,
      min: '-100',
      max: '200',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'ibp2_wave',
      hz: '150',
      digitize_value: null,
      min: '600',
      max: '1400',
      auto_range: 'false',
      baseline: '0',
    },
    {
      signal_name: 'co2_wave',
      hz: '20',
      digitize_value: null,
      min: '0',
      max: '500',
      auto_range: 'true',
      baseline: '0',
    },
  ],
};
