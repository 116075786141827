export const getRandomStr = () => Math.random().toString(36).substring(2, 12);

export const getRandomNumber = (min: number, max: number, step: number) => {
  const numberOfSteps = Math.floor((max - min) / step);
  return Math.floor(Math.random() * numberOfSteps) * step + min;
};

export const getRandomInt = (max: number) => {
  return getRandomNumber(0, max, 1);
};
