import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface DualMonitorStore {
  isDualMonitor: boolean;
  setIsDualMonitor: (isDualMonitor: boolean) => void;
}

export const useDualMonitorStore = create<DualMonitorStore>()(
  devtools((set) => ({
    isDualMonitor: false,
    setIsDualMonitor: (isDualMonitor) =>
      set(() => ({
        isDualMonitor,
      })),
  })),
);
