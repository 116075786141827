import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Slider: ComponentStyleConfig = {
  variants: {
    xl: {
      container: {
        w: '50%',
      },
      track: { w: 4 },
      thumb: { boxSize: 7 },
    },
    l: {
      container: {
        w: '98%',
      },
      track: { h: 2 },
      thumb: { boxSize: 5 },
    },
  },
  defaultProps: {
    focusBorderColor: 'border.500',
  },
};

export default Slider;
