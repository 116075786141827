import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';
import { demoSetting } from '@/mock/utils/getTagetHospitalSetting';

interface Display {
  row: number;
  column: number;
  cellHeaderSize: number;
  setRowColumn: (row: number, column: number) => void;
  setCellHeaderSize: (size: number) => void;
  resetStore: () => void;
  isAutoAdjustmentMode: boolean;
  setIsAutoAdjustmentMode: (isAutoAdjustmentMode: boolean) => void;
}

export const useDisplayStore = create<Display>()(
  devtools(
    persist(
      (set) => ({
        row: demoSetting?.displayLayout.row || 4,
        column: demoSetting?.displayLayout.column || 4,
        cellHeaderSize: demoSetting?.displayLayout.cellHeaderSize || 1,
        setRowColumn: (row, column) => set(() => ({ row, column })),
        setCellHeaderSize: (size) => set(() => ({ cellHeaderSize: size })),
        resetStore: () => {
          set(() => ({
            row: demoSetting?.displayLayout.row || 4,
            column: demoSetting?.displayLayout.column || 4,
            cellHeaderSize: demoSetting?.displayLayout.cellHeaderSize || 1,
            isAutoAdjustmentMode: false,
          }));
        },
        isAutoAdjustmentMode: false,
        setIsAutoAdjustmentMode: (isAutoAdjustmentMode) => set(() => ({ isAutoAdjustmentMode })),
      }),
      { name: 'display-setting', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useDisplayStore);
