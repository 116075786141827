import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Badge: ComponentStyleConfig = {
  baseStyle: {
    px: '2',
    py: '1',
    borderRadius: 'md',
  },
};

export default Badge;
