import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Menu: ComponentStyleConfig = {
  parts: ['button'],
  baseStyle: {
    button: {
      h: '32px',
    },
    list: {
      minW: '110px',
      w: '110px',
    },
  },
  variants: {
    filter: {
      button: {
        minW: '43px',
        h: '32px',
        pr: '18px',
        pl: '12px',
        pb: '3px',
        whiteSpace: 'nowrap',
        rounded: '4px',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 7 6' fill='none'%3E%3Cpath d='M3.22182 5.35513C3.36605 5.54829 3.63395 5.54829 3.77818 5.35513L6.9169 1.15173C7.11089 0.891945 6.94359 0.5 6.63872 0.5H0.361281C0.0564096 0.5 -0.110887 0.891944 0.0830986 1.15173L3.22182 5.35513Z' fill='currentColor'/%3E%3C/svg%3E");
        `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 5px top 46%',
        _active: {
          backgroundColor: 'brand.50',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 14 10' fill='none'%3E%3Cpath d='M6.44363 0.289735C6.7321 -0.0965782 7.2679 -0.0965784 7.55636 0.289735L13.8338 8.69654C14.2218 9.21611 13.8872 10 13.2774 10H0.722562C0.112819 10 -0.221773 9.21611 0.166197 8.69654L6.44363 0.289735Z' fill='%232E2E34'/%3E%3C/svg%3E");`,
        },
      },
      list: {
        minW: '100px',
        textAlign: 'center',
        fontSize: 'sm',
        py: 0,
        border: '1px solid',
        borderColor: 'brand.500',
        shadow: '0px 0px 9px rgba(105, 105, 105, 0.24);',
      },
      item: {
        _hover: { bg: 'brand.50', color: 'brand.500' },
        justifyContent: 'center',
        rounded: '4px',
        w: 'calc(100% - 6px)',
        margin: '3px',
        fontWeight: '400',
      },
      paramButton: {
        textColor: 'white',
      },
    },
  },
};

export default Menu;
