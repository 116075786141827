import { shallow } from 'zustand/shallow';

import { ReqPatientList } from '@/apis/patient/type';
import { useMockDataStore } from '@/contexts/mockDataStore';
import { escapeRegExp } from '@/utils/excapeRegExp';

export const useMockPatientList = (request: ReqPatientList) => {
  const { mockPatient } = useMockDataStore((state) => ({ mockPatient: state.mockPatient }), shallow);
  const basePatList = mockPatient;
  const regexpPatId = new RegExp(`${escapeRegExp(request.patId)}`, 'i');

  let result = basePatList.filter((val) => val.patId.search(regexpPatId) >= 0);

  const parts: string[] = request.patFullName.split(' ');
  const firstNameReg = new RegExp(`${escapeRegExp(parts[0])}`, 'i');
  const lastNameReg = new RegExp(`${escapeRegExp(parts[1])}`, 'i');
  result = result.filter(
    (val) =>
      firstNameReg.test(`${val.patFirstName}`) ||
      firstNameReg.test(`${val.patLastName}`) ||
      lastNameReg.test(`${val.patFirstName}`) ||
      lastNameReg.test(`${val.patLastName}`),
  );
  return result;
};
