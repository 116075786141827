import colors from '../foundations/colors';

export default {
  box: {
    p: '16px 14px',
    borderRadius: '4px',
    bg: 'gray.50',
    border: '1px solid',
    borderColor: '#C8C6D0',
    color: '#66656A',
  },
  input: {
    border: `1px solid ${colors.border[500]}`,
    borderRadius: '8px',
    px: 2.5,
    py: 2,
    fontSize: 'sm',
  },
};
