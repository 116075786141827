import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ko from 'date-fns/locale/ko';
import { registerLocale } from 'react-datepicker';
import { useRoutes } from 'react-router-dom';

import { AxiosInterceptor } from '@/apis/AxiosInterceptor';
import { LCHost } from '@/contexts/lightning-chart';
import MqttConnectionProvider from '@/contexts/MqttConnectionProvider';
import QueryProvider from '@/contexts/react-query/QueryProvider';
import routeInfo from '@/router/router';
import theme from '@/theme';

import { ColorProvider } from './contexts/ColorProvider';
import { WindowProvider } from './contexts/dualMonitorProvider';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ko', ko);
function App() {
  const RouteElement = useRoutes(routeInfo);

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ColorProvider>
        <QueryProvider>
          <>
            <MqttConnectionProvider>
              <WindowProvider>
                <AxiosInterceptor>
                  <LCHost>{RouteElement}</LCHost>
                </AxiosInterceptor>
              </WindowProvider>
            </MqttConnectionProvider>
            {process.env.REACT_APP_TANSTACK_QUERY_DEV_TOOL === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
          </>
        </QueryProvider>
      </ColorProvider>
    </ChakraProvider>
  );
}

export default App;
