import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';

interface Audio {
  volume: number;
  mute: boolean;
  tempMute: boolean;
  highNoMute: boolean;
  setMute: (mute: boolean) => void;
  setTempMute: (tempMute: boolean) => void;
  setVolume: (volume: number) => void;
  setHighNoMute: (noMute: boolean) => void;
}

export const useAudioStore = create<Audio>()(
  devtools(
    persist(
      (set) => ({
        volume: 1,
        mute: true,
        tempMute: false,
        highNoMute: true,
        setMute: (mute) => {
          set(() => ({ mute }));
        },
        setVolume: (volume) => {
          set(() => ({ volume }));
        },
        setTempMute: (tempMute) => {
          set(() => ({ tempMute }));
        },
        setHighNoMute: (highNoMute) => {
          set(() => ({ highNoMute }));
        },
      }),
      { name: 'audio-setting', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useAudioStore);
