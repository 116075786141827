import { route, typeParser } from 'route-type-safe';

export const routes = {
  SAMPLE: route({ path: '/' }), // for testing
  AUDIO_TEST: route({ path: '/audio' }),
  HOME: route({ path: '/' }),
  CENTRAL_MAIN: route({ path: '/main', typeQuery: { viewRole: typeParser.string.required } }),
  REPORT_PROCESSOR: route({
    path: '/main/report',
    typeQuery: {
      reportType: typeParser.string.required,
      dataId: typeParser.string.required,
      from: typeParser.string.required,
      to: typeParser.string.required,
      patientId: typeParser.string.required,
      name: typeParser.string.required,
      age: typeParser.string.required,
      sex: typeParser.string.required,
      deviceAlias: typeParser.string.required,
      bedId: typeParser.string.required,
    },
  }),
  PAGE_FALLBACK: route({ path: '/*' }),
  ERROR: route({ path: '/error' }),
  ERROR_INVALID_ACCESS: route({ path: '/error/invalid-access' }),
  ERROR_INVALID_ACCESS_REFRESH: route({ path: '/error/invalid-access-refresh' }),
};
