import { useMemo, useCallback, useEffect, useId, useRef } from 'react';

import { Box, CircularProgress, Text } from '@chakra-ui/react';
import { addSeconds } from 'date-fns';

import useCustomColorMode from '@/hooks/useCustomColorMode';
import useLanguage from '@/hooks/useLanguage';
import { usePdf } from '@/hooks/usePdf';
import { HeaderData } from '@/reports/common/ReportHeader';
import EcgWave28secPage from '@/reports/pages/EcgWave28secPage';
import { filenameGenerator } from '@/reports/utils/filename';

import { createWaveReportStore, useWaveReportStoreContext, WaveReportStoreContext } from './useWaveReportStore';
import Indicator from '../common/Indicator';

interface Props {
  dataId: string;
  pageCount: number;
  from: Date;
  to: Date;
  headerData: HeaderData;
}

const EcgWaveReport = ({ dataId, from, to, pageCount, headerData }: Props) => {
  const { translate } = useLanguage();
  const { color } = useCustomColorMode();

  const pdfId = useId();
  const { openFileInNewWindow, isProcessing, isComplete } = usePdf(
    Array.from({ length: pageCount }, (_, index) => `${pdfId}_${index}`),
    { filename: filenameGenerator({ prefix: 'ecg' }) },
  );
  const sec = useCallback(
    (index: number) => {
      const gap = Math.floor((to.getTime() - addSeconds(new Date(from), index * 28).getTime()) / 1000);
      return Math.min(gap, 28);
    },
    [from, to],
  );

  const isDataProcessed = useWaveReportStoreContext((state) => state.isDataProcessed);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDataProcessed.every((value) => value === true) && !isProcessing && !isComplete) {
      timer = setTimeout(openFileInNewWindow, 0);
    }
    if (isComplete) {
      window.close();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isComplete, isDataProcessed, isProcessing, openFileInNewWindow]);

  return (
    <>
      <Indicator>
        <Text color={color} fontWeight={600}>
          {translate('processPdf')}
        </Text>
        <CircularProgress isIndeterminate color="blue.300" size="100px" />
      </Indicator>
      <Box>
        {Array.from({ length: pageCount }, (_, index) => {
          return (
            <EcgWave28secPage
              key={`${index.toString()}`}
              pageId={`${pdfId}_${index}`}
              page={index + 1}
              id={dataId}
              baseDateTime={addSeconds(new Date(from), index * 28)}
              sec={sec(index)}
              headerData={headerData}
            />
          );
        })}
      </Box>
    </>
  );
};

const Wrapper = ({ from, to, ...props }: Omit<Props, 'pageCount'>) => {
  const pageCount = useMemo(() => {
    return Math.ceil((to.getTime() - from.getTime()) / 1000 / 28);
  }, [from, to]);

  const store = useRef(
    createWaveReportStore({ pageCount, isDataProcessed: Array.from({ length: pageCount }, () => false) }),
  ).current;

  return (
    <WaveReportStoreContext.Provider value={store}>
      <EcgWaveReport from={from} to={to} pageCount={pageCount} {...props} />
    </WaveReportStoreContext.Provider>
  );
};

export default Wrapper;
