import { AlarmHistoryProperty } from '@/apis/alarm/type';
import { PARAMS } from '@/constants/data';
import { Data, DeviceModel } from '@/pages/CentralMain/types';
import { fullFormat } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseUTC0String } from '@/utils/dateTimeUtils';

import { valueFormatter } from '../../NumericDataTemplate/utils/formatters';
import { TrendData } from '../constants';

// type helper
type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;
type ReturnType<T extends (...args: any) => any> = T extends (...args: any) => infer R ? R : any;

// type
type HFT = 'HFT700' | 'HFT750';
type MP = 'MP800' | 'MP1000NTP' | 'MP1300';
// eslint-disable-next-line camelcase
type Formatter<D extends DeviceModel> = (data: ArrayElement<(TrendData<D> & { date_time: string })[]>) => {
  value: string | undefined;
};

// table header keys
type CustomTrendKeys = 'tmp' | 'ibp1' | 'ibp2' | 'nibp' | 've_min' | 'st';
export type TrendFormatRule<D extends DeviceModel> = {
  property: keyof TrendData<D> | CustomTrendKeys;
  label: string;
  formatter: Formatter<D>;
};

// util
const defaultLabel = (property: keyof Data['params']): string => {
  return PARAMS[property].label;
};

const getDecimalPlace = (property: keyof Data['params']) => {
  return PARAMS[property].decimalPlaces;
};

const defaultDataFormatter = <D extends DeviceModel>(property: keyof Data['params']) => {
  return (data: TrendData<D>) => {
    const value = valueFormatter(data[property as keyof TrendData<D>]?.toString(), getDecimalPlace(property));
    return {
      value: value || '--',
    };
  };
};

// table label list
const HFTTrendFormatRules: TrendFormatRule<HFT>[] = [
  {
    property: 'rox',
    label: defaultLabel('rox'),
    formatter: defaultDataFormatter('rox'),
  },
  {
    property: 'sf_ratio',
    label: defaultLabel('sf_ratio'),
    formatter: defaultDataFormatter('sf_ratio'),
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
  {
    property: 'fio2',
    label: defaultLabel('fio2'),
    formatter: defaultDataFormatter('fio2'),
  },
  {
    property: 'flow',
    label: defaultLabel('flow'),
    formatter: defaultDataFormatter('flow'),
  },
  {
    property: 'pi',
    label: defaultLabel('pi'),
    formatter: defaultDataFormatter('pi'),
  },
  {
    property: 'peep',
    label: defaultLabel('peep'),
    formatter: defaultDataFormatter('peep'),
  },
  {
    property: 'spo2',
    label: defaultLabel('spo2'),
    formatter: defaultDataFormatter('spo2'),
  },
  {
    property: 'pr',
    label: defaultLabel('pr'),
    formatter: defaultDataFormatter('pr'),
  },
  {
    property: 'estimated_tidal_volume',
    label: defaultLabel('estimated_tidal_volume'),
    formatter: defaultDataFormatter('estimated_tidal_volume'),
  },
  {
    property: 'pip',
    label: defaultLabel('pip'),
    formatter: defaultDataFormatter('pip'),
  },
  {
    property: 'v_delivery',
    label: defaultLabel('v_delivery'),
    formatter: defaultDataFormatter('v_delivery'),
  },
];
const MPTrendFormatRules: TrendFormatRule<MP>[] = [
  {
    property: 'hr',
    label: defaultLabel('hr'),
    formatter: defaultDataFormatter('hr'),
  },
  {
    property: 'spo2',
    label: defaultLabel('spo2'),
    formatter: defaultDataFormatter('spo2'),
  },
  {
    property: 'nibp',
    label: 'NIBP',
    formatter: (data) => {
      return {
        value: `${defaultDataFormatter('nibp_s')(data).value} / ${defaultDataFormatter('nibp_d')(data).value} (${
          defaultDataFormatter('nibp_m')(data).value
        })`,
      };
    },
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
  {
    property: 'tmp',
    label: 'TEMP 1/2',
    formatter: (data) => {
      return {
        value: `${defaultDataFormatter('tmp1')(data).value} / ${defaultDataFormatter('tmp2')(data).value}`,
      };
    },
  },
  {
    property: 'ibp1',
    label: 'IBP①',
    formatter: (data) => {
      return {
        value: `${defaultDataFormatter('ibp1_s')(data).value} / ${defaultDataFormatter('ibp1_d')(data).value} (${
          defaultDataFormatter('ibp1_m')(data).value
        })`,
      };
    },
  },
  {
    property: 'ibp2',
    label: 'IBP②',
    formatter: (data) => {
      return {
        value: `${defaultDataFormatter('ibp2_s')(data).value} / ${defaultDataFormatter('ibp2_d')(data).value} (${
          defaultDataFormatter('ibp2_m')(data).value
        })`,
      };
    },
  },
  {
    property: 'st',
    label: 'ST 1/2',
    formatter: (data) => {
      return {
        value: `${defaultDataFormatter('st_i')(data).value} / ${defaultDataFormatter('st_ii')(data).value}`,
      };
    },
  },
  {
    property: 'etco2',
    label: defaultLabel('etco2'),
    formatter: defaultDataFormatter('etco2'),
  },
  {
    property: 'fico2',
    label: defaultLabel('fico2'),
    formatter: defaultDataFormatter('fico2'),
  },
];

const MV2000TrendFormatRules: TrendFormatRule<'MV2000'>[] = [
  {
    property: 'vme',
    label: 'VE min',
    formatter: defaultDataFormatter('vme'),
  },
  {
    property: 'pmean',
    label: defaultLabel('pmean'),
    formatter: defaultDataFormatter('pmean'),
  },
  {
    property: 'ppeak',
    label: defaultLabel('ppeak'),
    formatter: defaultDataFormatter('ppeak'),
  },
  {
    property: 'peep',
    label: defaultLabel('peep'),
    formatter: defaultDataFormatter('peep'),
  },
  {
    property: 'vte',
    label: defaultLabel('vte'),
    formatter: defaultDataFormatter('vte'),
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
];
const MV50TrendFormatRules: TrendFormatRule<'MV50'>[] = [
  {
    property: 'ppeak',
    label: defaultLabel('ppeak'),
    formatter: defaultDataFormatter('ppeak'),
  },
  {
    property: 'ppause',
    label: defaultLabel('ppause'),
    formatter: defaultDataFormatter('ppause'),
  },
  {
    property: 'pmean',
    label: defaultLabel('pmean'),
    formatter: defaultDataFormatter('pmean'),
  },
  {
    property: 'peep',
    label: defaultLabel('peep'),
    formatter: defaultDataFormatter('peep'),
  },
  {
    property: 'rr',
    label: defaultLabel('rr'),
    formatter: defaultDataFormatter('rr'),
  },
  {
    property: 'rr_spont',
    label: defaultLabel('rr_spont'),
    formatter: defaultDataFormatter('rr_spont'),
  },
  {
    property: 'etco2_rr',
    label: defaultLabel('etco2_rr'),
    formatter: defaultDataFormatter('etco2_rr'), // ?: etco2
  },
  {
    property: 'vti',
    label: defaultLabel('vti'),
    formatter: defaultDataFormatter('vti'),
  },
  {
    property: 'vte',
    label: defaultLabel('vte'),
    formatter: defaultDataFormatter('vte'),
  },
  {
    property: 'vme',
    label: defaultLabel('vme'),
    formatter: defaultDataFormatter('vme'),
  },
  {
    property: 'fio2',
    label: defaultLabel('fio2'),
    formatter: defaultDataFormatter('fio2'),
  },
  {
    property: 'spo2',
    label: defaultLabel('spo2'),
    formatter: defaultDataFormatter('spo2'),
  },
  {
    property: 'ti',
    label: defaultLabel('ti'),
    formatter: defaultDataFormatter('ti'),
  },
  {
    property: 'te',
    label: defaultLabel('te'),
    formatter: defaultDataFormatter('te'),
  },
  {
    property: 'tc',
    label: defaultLabel('tc'),
    formatter: defaultDataFormatter('tc'),
  },
  {
    property: 'flow',
    label: defaultLabel('flow'),
    formatter: defaultDataFormatter('flow'),
  },
  {
    property: 'fi_peak',
    label: defaultLabel('fi_peak'),
    formatter: defaultDataFormatter('fi_peak'),
  },
  {
    property: 'fe_peak',
    label: defaultLabel('fe_peak'),
    formatter: defaultDataFormatter('fe_peak'),
  },
  {
    property: 'peep_h',
    label: defaultLabel('peep_h'),
    formatter: defaultDataFormatter('peep_h'),
  },
  {
    property: 'peep_l',
    label: defaultLabel('peep_l'),
    formatter: defaultDataFormatter('peep_l'),
  },
  {
    property: 'vico2',
    label: defaultLabel('vico2'),
    formatter: defaultDataFormatter('vico2'),
  },
  {
    property: 'veco2',
    label: defaultLabel('veco2'),
    formatter: defaultDataFormatter('veco2'),
  },
  {
    property: 'vmeco2',
    label: defaultLabel('vmeco2'),
    formatter: defaultDataFormatter('vmeco2'),
  },
  {
    property: 'pi',
    label: defaultLabel('pi'),
    formatter: defaultDataFormatter('pi'),
  },
  {
    property: 'pr',
    label: defaultLabel('pr'),
    formatter: defaultDataFormatter('pr'),
  },
  {
    property: 'rsbi',
    label: defaultLabel('rsbi'),
    formatter: defaultDataFormatter('rsbi'),
  },
  {
    property: 'wobv',
    label: defaultLabel('wobv'),
    formatter: defaultDataFormatter('wobv'),
  },
  {
    property: 'cl',
    label: defaultLabel('cl'),
    formatter: defaultDataFormatter('cl'),
  },
  {
    property: 'ra',
    label: defaultLabel('ra'),
    formatter: defaultDataFormatter('ra'),
  },
];

export const AlarmHistoryFormatRules: {
  property: keyof AlarmHistoryProperty;
  label: string;
  formatter: (s: string) => string;
}[] = [
  {
    property: 'cleared_date_time',
    label: 'clearedTime',
    formatter: (s) => fullFormat(parseUTC0String(s)),
  },
  {
    property: 'alarm_msg',
    label: 'message',
    formatter: (s) => s,
  },
  {
    property: 'alarm_level',
    label: 'level',
    formatter: (s) => s,
  },
  {
    property: 'curr_value',
    label: 'value',
    formatter: (s) => s,
  },
  {
    property: 'min_value',
    label: 'alarm',
    formatter: (s) => s,
  },
  {
    property: 'max_value',
    label: 'alarm',
    formatter: (s) => s,
  },
];

export const getTrendFormatRuleListByDeviceModel = <R>(deviceModel: DeviceModel): R[] => {
  if (deviceModel === 'HFT700' || deviceModel === 'HFT750') {
    return HFTTrendFormatRules as R[];
  }
  if (deviceModel === 'MP800' || deviceModel === 'MP1000NTP' || deviceModel === 'MP1300') {
    return MPTrendFormatRules as R[];
  }
  if (deviceModel === 'MV50') {
    return MV50TrendFormatRules as R[];
  }
  if (deviceModel === 'MV2000') {
    return MV2000TrendFormatRules as R[];
  }
  return [];
};
