import { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { addMinutes } from 'date-fns';

import { useA4Page } from '@/hooks/usePdf';
import usePeriodFilledData from '@/pages/CentralMain/hooks/usePeriodFilledData';
import {
  getTrendFormatRuleListByDeviceModel,
  TrendFormatRule,
} from '@/pages/CentralMain/organisms/TrendModal/utils/trend-table-formatter';
import { DeviceModel } from '@/pages/CentralMain/types';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import A4Container from '@/reports/container/A4Container';
import { useTrendData } from '@/reports/hooks/useTrendData';

import ReportHeader, { HeaderData } from '../common/ReportHeader';
import MpTrend from '../trend/MpTrend';
import TableInTrendPage from '../trend-table/TableInTrendPage';

// trend report: 15min data per page
export const DATA_PER_PAGE = 15;

const TrendTable = ({ id, from, to }: { id: string; from: Date; to: Date }) => {
  const { model } = parseDataId(id);
  const tableFormatRules = useMemo(() => {
    const deviceModel = model as DeviceModel;
    return getTrendFormatRuleListByDeviceModel<TrendFormatRule<typeof deviceModel>>(deviceModel).filter((rule) =>
      ['hr', 'spo2', 'nibp', 'rr', 'ibp1', 'ibp2', 'tmp', 'st', 'etco2', 'fico2'].includes(rule.property),
    );
  }, [model]);

  const deviceData = useTrendData(id, from, addMinutes(to, 1));
  const { filledData } = usePeriodFilledData(deviceData, 'date_time', { from, to });

  return <TableInTrendPage data={filledData.slice(0, DATA_PER_PAGE + 1)} tableFormatRules={tableFormatRules} />;
};

interface Props {
  id: string;
  pageId: string;
  from: Date;
  to: Date;
  page?: number;
  headerData: HeaderData;
}
const TrendChartWithTablePage = ({ id, pageId, from, to, page, headerData }: Props) => {
  const { convertMmToPx } = useA4Page({});

  return (
    <A4Container page={page} pageId={pageId}>
      {/* Header */}
      <Box w="full" px={`${convertMmToPx(10)}px`}>
        <ReportHeader headerData={headerData} />
      </Box>
      {/* Trend chart */}
      <MpTrend id={id} from={from} to={to} />
      {/* Table */}
      <Box h={`${convertMmToPx(120)}px`}>
        <TrendTable id={id} from={from} to={to} />
      </Box>
    </A4Container>
  );
};

export default TrendChartWithTablePage;
