import { PropsWithChildren } from 'react';

import { Box, Center, useColorModeValue } from '@chakra-ui/react';

import useCustomColorMode from '@/hooks/useCustomColorMode';

const Indicator = ({ children }: PropsWithChildren) => {
  const { color } = useCustomColorMode();
  const bgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.400');
  return (
    <Center pos="fixed" top={0} left={0} w="100vw" h="100vh">
      <Box w="max-content" h="max-content" p={10} bg={bgColor} color={color} borderRadius="lg" zIndex={1000}>
        {children}
      </Box>
    </Center>
  );
};

export default Indicator;
