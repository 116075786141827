import React, { useEffect } from 'react';

interface Props {
  storageEventCallback: (event: StorageEvent) => void;
}

const useStorageEvent = ({ storageEventCallback }: Props) => {
  useEffect(() => {
    window.addEventListener('storage', storageEventCallback);

    return () => {
      window.removeEventListener('storage', storageEventCallback);
    };
  }, [storageEventCallback]);
};

export default useStorageEvent;
