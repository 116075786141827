import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Modal: ComponentStyleConfig = {
  parts: ['dialogContainer', 'overlay'],
  baseStyle: ({ colorMode }) => ({
    overlay: {
      width: '100%',
      height: '100%',
    },
    dialogContainer: {
      width: '100%',
      height: '100%',
    },
    dialog: {
      borderRadius: 'md',
      bg: colorMode === 'dark' ? `purple.100` : 'white',
      maxW: 'unset',
      width: 'max-content',
    },
  }),
};

export default Modal;
