import { FC, useEffect, useRef } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  ThemeProvider,
  useDisclosure,
} from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';

import theme from '@/theme';

enum AlertType {
  ALERT,
  CONFIRM,
}

export interface IAlertProps {
  message: string;
  title: string;
  hasClose?: boolean;
  buttons?: { label?: string; onClick?: () => void }[];
}

const AlertRoot: FC<IAlertProps & { type: AlertType; time: Date }> = (props) => {
  const { title, message, buttons = [], hasClose = false, type, time = new Date() } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  useEffect(() => {
    onOpen();
  }, [onOpen, time]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AlertDialog
          isCentered
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          motionPreset="slideInBottom"
          onClose={onClose}
        >
          <AlertDialogContent
            w="auto"
            minW="290px"
            fontFamily="Spoqa Han Sans Neo"
            shadow="2px 0px 30px rgba(104, 104, 106, 0.15)"
            rounded="10px"
          >
            <AlertDialogHeader pt="32px" px="9" fontSize="sm" fontWeight="medium">
              {title}
            </AlertDialogHeader>
            {hasClose && <AlertDialogCloseButton />}
            <AlertDialogBody pt="0" pb="20px" px="9">
              {message.split('\n').map((line) => {
                return (
                  <Box key={line} fontSize="sm">
                    <span>{line}</span>
                    <br />
                  </Box>
                );
              })}
            </AlertDialogBody>
            <AlertDialogFooter justifyContent="center" gap="4" pb="32px">
              {type === AlertType.ALERT && (
                <Button
                  ref={cancelRef}
                  size="sm"
                  variant="unstyled"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {buttons[0]?.label || '확인'}
                </Button>
              )}

              {type === AlertType.CONFIRM &&
                buttons.map(({ label, onClick }) => (
                  <Button
                    key={label}
                    px="4"
                    onClick={() => {
                      onClose();
                      if (onClick) {
                        onClick();
                      }
                    }}
                    size="sm"
                    variant="solid"
                    w="max-content"
                  >
                    {label}
                  </Button>
                ))}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </ThemeProvider>
    </>
  );
};

const portalRoot = document.createElement('portal');
document.body.appendChild(portalRoot);
const root = createRoot(portalRoot);
// let alertRoot: void | null = null;

function Create(props: Omit<IAlertProps, 'type'>, type: AlertType = AlertType.ALERT) {
  // Amy : portal 확인하고 싶을 때
  // const portalElement = document.body.querySelectorAll('div.chakra-portal');
  root.render(<AlertRoot {...props} type={type} time={new Date()} />);
}

export function confirm(options: Omit<IAlertProps, 'type'>) {
  Create(options, AlertType.CONFIRM);
}

export function alert(options: Omit<IAlertProps, 'type'>) {
  // const newRoot = createRoot(portalRoot);
  root.render(<AlertRoot {...options} type={AlertType.ALERT} time={new Date()} />);
  // Create(options, AlertType.ALERT);
}
