import React, { PropsWithChildren } from 'react';

import { Flex, HStack, VStack } from '@chakra-ui/react';

import useLanguage from '@/hooks/useLanguage';
import { useA4Page } from '@/hooks/usePdf';
import { fullFormat } from '@/pages/CentralMain/utils/convertAdmissionTime';

const HeaderText = ({
  children,
  wInPercentage = 100,
  fontWeight = 600,
}: PropsWithChildren<{ wInPercentage?: number; fontWeight?: number }>) => {
  const { convertMmToPx } = useA4Page({});
  return (
    <Flex
      fontSize={`${convertMmToPx(3)}px`}
      fontWeight={fontWeight}
      h={`${convertMmToPx(7)}px`}
      w={`${wInPercentage}%`}
      align="center"
      pl={`${convertMmToPx(2)}px`}
      borderRight="solid 1px"
      borderBottom="solid 1px"
    >
      {children}
    </Flex>
  );
};

export type HeaderData = {
  name: string;
  age: string;
  sex: string;
  patientId: string;
  printPeriod: string;
  printDate: Date;
  deviceAlias: string;
  deviceSerial: string;
  bedId: string;
};
interface Props {
  headerData: HeaderData;
}
const ReportHeader = ({ headerData }: Props) => {
  const { translate } = useLanguage();
  const { convertMmToPx } = useA4Page({});

  return (
    <VStack mb={`${convertMmToPx(5)}px`}>
      <Flex w="full" justify="flex-end" fontSize={`${convertMmToPx(3)}px`}>
        {fullFormat(headerData.printDate)}
      </Flex>
      <VStack gap={0} w="full" borderTop="solid 1px" borderLeft="solid 1px">
        <HStack w="full" gap={0}>
          <HeaderText wInPercentage={20}>{translate('patientId')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.patientId}
          </HeaderText>
          <HeaderText wInPercentage={20}>{translate('name')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.name}
          </HeaderText>
        </HStack>
        <HStack w="full" gap={0}>
          <HeaderText wInPercentage={20}>{translate('ageCapital')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.age}
          </HeaderText>
          <HeaderText wInPercentage={20}>{translate('sex')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.sex}
          </HeaderText>
        </HStack>
        <HStack w="full" gap={0}>
          <HeaderText wInPercentage={20}>{translate('bedId')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.bedId}
          </HeaderText>
          <HeaderText wInPercentage={20}>{translate('deviceInfo')}</HeaderText>
          <HeaderText wInPercentage={30} fontWeight={400}>
            {headerData.deviceAlias
              ? `${headerData.deviceSerial} (${headerData.deviceAlias})`
              : headerData.deviceSerial}
          </HeaderText>
        </HStack>
        <HStack w="full" gap={0}>
          <HeaderText wInPercentage={20}>{`${translate('print')} ${translate('period')}`}</HeaderText>
          <HeaderText wInPercentage={80} fontWeight={400}>
            {headerData.printPeriod}
          </HeaderText>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default ReportHeader;
