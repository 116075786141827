import { Data } from '../types';

export const formatDataId = (manufacturerCode: string, model: string, serial: string) =>
  `${manufacturerCode}//${model}//${serial}`;

export const getDataId = (data: Data) => {
  return formatDataId(data.man_code, data.device_model, data.device_serial);
};

export const parseDataId = (dataId: string) => {
  const split = dataId.split('//');
  return { manufacturer: split[0], model: split[1], deviceSerial: split[2] };
};

export const parseDataIdAPI = (dataId: string) => {
  const split = dataId.split('//');
  return { manufacturer: split[0], deviceModel: split[1], deviceSerial: split[2] };
};
