export const valueFormatter = (
  paramValue: string | number | undefined | null,
  decimalPlaces?: number,
): string | null => {
  if (paramValue == null || paramValue === 'null') {
    return null;
  }
  if (decimalPlaces === undefined) {
    return paramValue.toString();
  }
  let value: number;
  if (typeof paramValue === 'string') {
    value = Number(paramValue);
    if (Number.isNaN(value)) {
      return null;
    }
  } else {
    value = paramValue;
  }

  return value.toFixed(decimalPlaces);
};

export const placeholderFormatter = (decimalPlaces = 0, maxTextCount = 1) => {
  if (decimalPlaces <= 0) {
    return '-'.repeat(maxTextCount);
  }
  const intergerDigitsCnt = maxTextCount - decimalPlaces - 1; // 1: 소수점 1자리
  return `${'-'.repeat(intergerDigitsCnt)}.${'-'.repeat(decimalPlaces)}`;
};
