import { subMinutes } from 'date-fns';

import { instance } from '@/apis';
import {
  ReqAdmitPatient,
  ReqAllParamsTrend,
  ReqConnectionInfos,
  ReqDeviceConnectionList,
  ReqDeviceUpdate,
  ReqDischargePatient,
  ReqPatientInfo,
  ReqPatientList,
  ReqRemoteSetting,
  ReqTrendData,
  ResNibpTrend,
} from '@/apis/patient/type';
import { makeApiKeyParam } from '@/components/charts/util/makeApiKeyParam';
import { TTrend } from '@/constants/types';
import {
  getMockLastDeviceInfo,
  getMockNibpTrend,
  getMockWaveDetail,
  getMockWaveOverview,
  getPdpCurrList,
  postAllParamTrend,
  postRoxTrend,
  postTrend,
} from '@/mock/apis';
import { ResTotalTrendData } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { DeviceModel } from '@/pages/CentralMain/types';
import { makeLocalTimeToUTC0 } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseUTC0String } from '@/utils/dateTimeUtils';

export const patientApis = {
  deviceConnectionList: async (data: ReqDeviceConnectionList) => {
    const response = await instance.post('/central/getSearchCurrPatList', data, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  patientInfoDetail: async (pId: string) => {
    const response = await instance.get(`/central/patientInfo/detail/${pId}`, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  patientInfoReg: async (data: ReqPatientInfo) => {
    const response = await instance.post('/central/patientInfo/reg', data, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  patientInfoDel: async (pId: string) => {
    const response = await instance.delete(`/central/patientInfo/delete/${pId}`, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });
    return response.data;
  },
  patientInfoUpdate: async (data: ReqPatientInfo) => {
    const response = await instance.put('/central/patientInfo/update', data, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  admitPatient: async (data: ReqAdmitPatient) => {
    const response = await instance.post('/patient/map/mapdevice', data, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });
    return response.data;
  },
  dischargePatient: async (data: ReqDischargePatient) => {
    const response = await instance.put(`/patient/unmapdevice/${data.aat}/${data.end}`, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  patientList: async (data: ReqPatientList) => {
    const response = await instance.post('/central/patientInfo/list', data, {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    });
    return response.data;
  },
  remoteSetting: async (data: ReqRemoteSetting) => {
    const response = await instance.post('/central/remote-setting', data, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });
    return response.data;
  },
  getConnectionInfos: async (data: ReqConnectionInfos) => {
    const res = await instance.post(`/central/device-connection-infos`, data, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });

    return res.data;
  },
  deviceUpdate: async (data: ReqDeviceUpdate) => {
    const res = await instance.post(`/central/device/update`, data, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });
    return res.data;
  },
  roxNotificationAck: async (serial: string) => {
    const res = await instance.get(`/central/rox-notification/ack/${serial}`, {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    });
    return res.data;
  },
};

export const getPatientDevicePairList = async () => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return getPdpCurrList();
  }
  const res = await instance.get(`/central/pdpCurrList`, {
    headers: { Accept: 'application/mek.central.api.v1+json' },
  });
  return res.data;
};

export const getRoxTrend = async (data: ReqTrendData) => {
  // Amy : admit 이 되었으면 admit time 과 discharge 를 다 보내지만, admit 이 없으면 둘 다 빈 string 을 넣음
  const adjData = {
    deviceSerial: data.deviceSerial,
    from: data.from,
    to: data.to || (data.from && makeLocalTimeToUTC0(new Date())),
  };
  if (process.env.REACT_APP_MODE === 'demo') {
    return postRoxTrend(adjData);
  }
  const res = await instance.post(`/central/roxMongo`, adjData, {
    headers: { Accept: 'application/mek.central.api.v2+json' },
  });
  return res.data;
};

export const getTrend = async (data: ReqTrendData, param: TTrend) => {
  // Amy : admit 이 되었으면 admit time 과 discharge 를 다 보내지만, admit 이 없으면 둘 다 빈 string 을 넣음
  const adjData = {
    deviceSerial: data.deviceSerial,
    from: data.from,
    to: data.to || (data.from && makeLocalTimeToUTC0(new Date())),
  };

  if (process.env.REACT_APP_MODE === 'demo') {
    return postTrend(adjData, param);
  }
  const res = await instance.post(`/central/trend/${makeApiKeyParam(param)}`, adjData, {
    headers: { Accept: 'application/mek.central.api.v2+json' },
  });
  return res.data;
};

// Amy: 모든 파라미터의 트렌드
export const getAllParamTrend = async (req: ReqAllParamsTrend): Promise<ResTotalTrendData<DeviceModel>> => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return postAllParamTrend(req);
  }

  const res = await instance.get(
    `/central/allParamsTrend/${req.manufacturerCode}/${req.model}/${req.deviceSerial}/${req.start}/${req.end}/${req.sortOrder}`,
    {
      headers: { Accept: 'application/mek.central.api.v2+json' },
      timeout: 600000,
    },
  );
  return res.data;
};

// Amy: NIBP 값 (전의 값과 같을경우 보내지 않음)
export const getNibpTrend = async (req: ReqAllParamsTrend): Promise<ResNibpTrend> => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return getMockNibpTrend(req);
  }

  const adjStart = makeLocalTimeToUTC0(subMinutes(parseUTC0String(req.start), 2)); // note: 분을 넘어 값이 동일 구간이 있는 경우 시간이 달라지는 문제 방어

  const res = await instance.get(
    `/central/nibpHistory/${req.manufacturerCode}/${req.model}/${req.deviceSerial}/${adjStart}/${req.end}/${req.sortOrder}`,
    {
      headers: { Accept: 'application/mek.central.api.v2+json' },
      timeout: 600000,
    },
  );
  const originalDataList: ResNibpTrend['data'] = res.data?.data;
  if (originalDataList && originalDataList.length > 0) {
    const onlyRequestDataList = originalDataList.filter((data) => data.date_time.localeCompare(req.start) >= 0);
    return { ...res.data, data: onlyRequestDataList };
  }

  return res.data;
};

// Amy: wave history overview 에 들어가는 부분
export const getWaveOverview = async (req: ReqAllParamsTrend) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return getMockWaveOverview(req);
  }
  const res = await instance.get(
    `/central/waveOverview/${req.manufacturerCode}/${req.model}/${req.deviceSerial}/${req.start}/${req.end}/${req.sortOrder}`,
    {
      headers: { Accept: 'application/mek.central.api.v2+json' },
      timeout: 600000,
    },
  );
  return res.data;
};

// Amy: wave history overview 에 들어가는 부분
export const getWaveDetail = async (req: ReqAllParamsTrend) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return getMockWaveDetail(req);
  }
  const res = await instance.get(
    `/central/waveDetail/${req.manufacturerCode}/${req.model}/${req.deviceSerial}/${req.start}/${req.end}/${req.sortOrder}`,
    {
      headers: { Accept: 'application/mek.central.api.v2+json' },
    },
  );
  return res.data;
};

export const getLastDeviceInfo = async (req: Omit<ReqAllParamsTrend, 'sortOrder'>) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return getMockLastDeviceInfo(req);
  }
  const res = await instance.get(
    `/central/last-device-info/${req.manufacturerCode}/${req.model}/${req.deviceSerial}/${req.start}/${req.end}`,
    {
      headers: { Accept: 'application/mek.central.api.v1+json' },
    },
  );
  return res.data;
};
