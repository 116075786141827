import { useCallback, useDeferredValue, useEffect, useState } from 'react';

import { getRandomStr } from '@/utils/random';

const VIEW_ID_KEY = 'view-id';
const useViewSessionStore = () => {
  useEffect(() => {
    const viewId = sessionStorage.getItem(VIEW_ID_KEY);
    if (viewId !== null) {
      return;
    }
    const newViewId = `${Date.now()}-${getRandomStr()}`;
    sessionStorage.setItem(VIEW_ID_KEY, newViewId);
  }, []);

  const [_viewId, setViewId] = useState(sessionStorage.getItem(VIEW_ID_KEY));
  const updateViewId = useCallback(() => {
    setViewId(sessionStorage.getItem(VIEW_ID_KEY));
  }, []);
  useEffect(() => {
    const interval = setTimeout(updateViewId, 0);
    return () => {
      clearTimeout(interval);
    };
  }, [updateViewId]);
  const viewId = useDeferredValue(_viewId || 'INVALID');

  return { viewId };
};
export default useViewSessionStore;
