import { DEVICE_MODEL, Data, DeviceModel } from '@/pages/CentralMain/types';

import { TSettingParam } from '../types';

export const SETTING_PARAMS: { [K in keyof Data['settings']]: TSettingParam } = {
  setting_ppause: {
    label: 'Ppause',
    max: '80',
    min: '5',
    unit: 'cmH₂O',
  },
  setting_h_freq: {
    label: 'H.Freq',
    max: '2',
    min: '20',
    unit: 'Hz',
  },
  setting_ibp1_mode: {
    label: 'IBP① mode',
    max: '7',
    min: '0',
    unit: '',
  },
  setting_ibp2_mode: {
    label: 'IBP② mode',
    max: '7',
    min: '0',
    unit: '',
  },
  setting_ibp3_mode: {
    label: 'IBP③ mode',
    max: '7',
    min: '0',
    unit: '',
  },
  setting_ibp4_mode: {
    label: 'IBP④ mode',
    max: '7',
    min: '0',
    unit: '',
  },
  setting_backup_fio2: {
    label: 'Backup FiO₂',
    unit: '%',
    min: '21',
    max: '100',
  },
  setting_dew_point: {
    label: 'Dew point',
    unit: '℃',
    min: '31',
    max: '37',
  },
  setting_fio2: {
    label: 'FiO₂',
    unit: '%',
    min: '21',
    max: '100',
  },
  setting_flow: {
    label: 'Flow',
    unit: 'LPM',
    min: '5',
    max: '60',
  },
  setting_humidification_level: {
    label: 'Humid level',
    unit: '',
    min: '1',
    max: '5',
  },
  setting_rr_detection: {
    label: 'RR detection',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  setting_therapy_mode: {
    label: 'Therapy_mode',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_tsf_fio2_high: {
    label: 'Max.',
    unit: '%',
    min: '30',
    max: '100',
    customLabel: () => 'FiO₂ Max.',
    maxTextCount: 3,
  },
  setting_tsf_fio2_low: {
    label: 'Min.',
    unit: '%',
    min: '21',
    max: '30',
    customLabel: () => 'FiO₂ Min.',
    maxTextCount: 3,
  },
  setting_tsf_mode: {
    label: 'TSF',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  setting_tsf_spo2: {
    label: 'Target SPO₂',
    unit: '%',
    min: '85',
    max: '99',
    maxTextCount: 3,
  },
  // 표시 X
  setting_demo_mode: {
    label: 'Demo mode',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_bed_id: {
    label: 'Bed Id',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_patient_id: {
    label: 'Patient Id',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_base_flow: {
    label: 'Base Flow',
    unit: 'LPM',
    min: '10',
    max: '40',
  },
  setting_assist_flow: {
    label: 'Assist Flow',
    unit: 'LPM',
    min: '0',
    max: '55',
    minCustomValue: 'Off',
    maxTextCount: 3,
  },
  setting_biflow_mode: {
    label: 'Bi-Flow',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  setting_tsf_time_const_inc: {
    label: 'Inc. Interval',
    unit: 'sec / %',
    min: '10',
    max: '20',
  },
  setting_tsf_time_const_dec: {
    label: 'Dec. Interval',
    unit: 'sec / %',
    min: '30',
    max: '120',
  },
  'setting_pa+': {
    label: 'PA(+)',
    unit: 'cmH₂O',
    min: '0',
    max: '3',
    minCustomValue: 'Off',
  },
  setting_cpap: {
    label: 'CPAP',
    unit: 'cmH₂O',
    min: '4',
    max: '20',
  },
  'setting_pa-': {
    label: 'PA(-)',
    unit: 'cmH₂O',
    min: '-3',
    max: '0',
    maxCustomValue: 'Off',
    minCondition: {
      'BI-LEVEL_s_t_v': { param: 'setting_epap', sumValue: 4 },
      'BI-LEVEL_t_v': { param: 'setting_epap', sumValue: 4 },
      'BI-LEVEL_s_v': { param: 'setting_epap', sumValue: 4 },
      CPAP: { param: 'setting_cpap', sumValue: 4 },
    },
  },
  setting_trigger_level: {
    label: 'Trig. level',
    unit: 'LPM',
    min: '3',
    max: '20',
  },
  setting_rise_time: {
    label: 'Rise Time',
    unit: '',
    min: '0',
    max: '2',
    customValue: ['Fast', 'Medium', 'Low'],
  },
  setting_auto_start: {
    label: 'Auto Start',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  setting_ipap: {
    label: 'IPAP',
    unit: 'cmH₂O',
    min: '0',
    max: '40',
  },
  setting_epap: {
    label: 'EPAP',
    unit: 'cmH₂O',
    min: '0',
    max: '20',
  },
  setting_rr: {
    label: 'RR',
    unit: 'BPM',
    min: '0',
    max: '150',
  },
  setting_inspiratory_time_ti: {
    label: 'TI',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_trigger_type: {
    label: 'Trig. Type',
    unit: '',
    min: '1',
    max: '3',
    customValue: ['', 'S', 'S/T', 'T'],
  },
  setting_volume_guarantee: {
    label: 'Volume Guarantee',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  setting_inspiratory_volume_vi: {
    label: 'Vi',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_p_limit: {
    label: 'Plimit',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_backup_ti: {
    label: 'Backup TI',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_backup_rr: {
    label: 'Backup RR',
    unit: 'BPM',
    min: '0',
    max: '150',
    targetProperty: { MV50: 'setting_rr' },
  },
  setting_patient_type: {
    label: 'Patient type',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_patient_height: {
    label: 'Height',
    unit: 'cm',
    min: '40',
    max: '270',
  },
  setting_vti: {
    label: 'VTi',
    unit: 'mL',
    min: '2',
    max: '2500',
  },
  setting_peep: {
    label: 'PEEP',
    unit: 'cmH₂O',
    min: '0',
    max: '60',
  },
  setting_tinsp: {
    label: 'Tinsp',
    unit: 'sec',
    min: '0.1',
    max: '10',
  },
  setting_trigger: {
    label: 'Trigger',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_f_trig: {
    label: 'F Trig',
    unit: 'LPM',
    min: '0',
    max: '40',
  },
  setting_p_trig: {
    label: 'P Trig',
    unit: 'cmH₂O',
    min: '0',
    max: '40',
  },
  setting_plimit: {
    label: 'Plimit',
    unit: 'cmH₂O',
    min: '1',
    max: '100',
  },
  setting_trise: {
    label: 'Trise',
    unit: 'sec',
    min: '0',
    max: '2',
  },
  setting_exsens: {
    label: 'ExSens',
    unit: '%',
    min: '0',
    max: '80',
    offValue: '0',
    minCustomValue: 'Off',
    customLabel: (model?: DeviceModel, toggle?: boolean) => {
      if (model === DEVICE_MODEL.MV50) {
        if (toggle) return 'ExSens T';
        return 'ExSens Mv';
      }
      return 'ExSens';
    },
  },
  setting_tpause: {
    label: 'TPause',
    unit: 'sec',
    min: '0',
    max: '2',
  },
  setting_fend: {
    label: 'Fend',
    unit: '%',
    min: '25',
    max: '100',
  },
  setting_sigh: {
    label: 'Sigh',
    unit: 'RPB',
    min: '0',
    max: '120',
  },
  setting_adaptive_flow: {
    label: 'Adaptive Flow',
    unit: '',
    min: '0',
    max: '1',
    customValue: ['Off', 'On'],
  },
  setting_pinsp: {
    label: 'Pinsp',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
  },
  setting_ps: {
    label: 'PS',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
  },
  setting_flow_limit: {
    label: 'Flimit',
    unit: '%',
    min: '0',
    max: '10',
  },
  setting_trise_psv: {
    label: 'TrisePSv',
    unit: 'sec',
    min: '0.1',
    max: '0.5',
  },
  setting_exsens_psv: {
    label: 'ExSens PSv',
    unit: '%',
    min: '5',
    max: '80',
    customLabel: (model?: DeviceModel, toggle?: boolean) => {
      if (model === DEVICE_MODEL.MV50) {
        if (toggle) return 'ExSens S';
        return 'ExSens PSv';
      }
      return 'ExSens PSv';
    },
  },
  setting_th_peep: {
    label: 'TH PEEP',
    unit: 'sec',
    min: '0.2',
    max: '50',
  },
  setting_tl_peep: {
    label: 'TL PEEP',
    unit: 'sec',
    min: '0.2',
    max: '30',
  },
  setting_peep_h: {
    label: 'PEEP H',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
  },
  setting_peep_l: {
    label: 'PEEP L',
    unit: 'cmH₂O',
    min: '0',
    max: '60',
  },
  setting_minvol: {
    label: 'minVol',
    unit: '%',
    min: '70',
    max: '300',
  },
  setting_rr_sens: {
    label: 'RR Sens',
    unit: '',
    min: '0.2',
    max: '3',
  },
  setting_st_type: {
    label: 'Breath Type',
    unit: '',
    min: '0',
    max: '2',
    customValue: ['S', 'T', 'S/T'],
  },
  setting_backup_apnea_mode: {
    label: 'Apnea Mode',
    unit: '',
    min: '0',
    max: '7',
    customValue: ['PRVC', 'PRVC-SIMV', 'V-ACV', 'V-SIMV', 'P-ACV', 'P-SIMV', 'TCPL-AC', 'TCPL-SIMV'],
  },
  setting_backup_vti: {
    label: 'Backup VTI',
    unit: 'mL',
    min: '2',
    max: '2500',
    targetProperty: { MV50: 'setting_vti' },
  },
  setting_backup_pinsp: {
    label: 'Backup Pinsp',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
    targetProperty: { MV50: 'setting_pinsp' },
  },
  setting_backup_tinsp: {
    label: 'Backup Tinsp',
    unit: 'sec',
    min: '0.1',
    max: '10',
    targetProperty: { MV50: 'setting_tinsp' },
  },
  setting_backup_trise: {
    label: 'Backup Trise',
    unit: 'sec',
    min: '0',
    max: '2',
    targetProperty: { MV50: 'setting_trise' },
  },
  setting_backup_tpause: {
    label: 'Backup Tpause',
    unit: 'sec',
    min: '0',
    max: '2',
    targetProperty: { MV50: 'setting_tpause' },
  },
  setting_backup_fend: {
    label: 'Backup Fend',
    unit: '%',
    min: '25',
    max: '100',
    targetProperty: { MV50: 'setting_fend' },
  },
  setting_backup_exsens: {
    label: 'Backup Exsens',
    unit: '%',
    min: '0',
    max: '80',
    targetProperty: { MV50: 'setting_exsens' },
  },
  setting_backup_plimit: {
    label: 'Backup Plimit',
    unit: 'cmH₂O',
    min: '1',
    max: '100',
    targetProperty: { MV50: 'setting_plimit' },
  },
  alarm_apnea: {
    label: 'Apnea Time',
    unit: 'sec',
    min: '10',
    max: '60',
  },
  alarm_check_refill_water: {
    label: 'Check refill the water',
    unit: '',
    min: '0',
    max: '1',
    onOffValue: ['Off', 'On'],
    customValue: ['Off', 'On'],
  },
  alarm_leakage_high: {
    label: 'High Leak',
    unit: 'LPM',
    min: '10',
    max: '70',
  },
  setting_ecg_cable: {
    label: 'ECG Cable',
    unit: '',
    min: '',
    max: '',
  },

  setting_apnea: {
    label: 'Apnea',
    unit: 'sec',
    min: '2',
    max: '60',
    offValue: '0',
    minCustomValue: 'Off',
  },
  setting_flimit: {
    label: 'Flimit',
    unit: 'LPM',
    min: '20', // Neonate: 10
    max: '60', // Neonate: 30
    offValue: '0',
    minCustomValue: 'Off',
  },
  setting_power: {
    label: 'Power',
    unit: '%',
    min: '1',
    max: '100',
  },
  setting_map: {
    label: 'MAP',
    unit: 'cmH₂O',
    min: '5',
    max: '60',
  },
  setting_imv_etime: {
    label: 'IMV Etime',
    unit: 'sec',
    min: '0.2',
    max: '6',
  },
  setting_trigger_mode: {
    label: 'Trigger Mode',
    unit: '',
    min: '0',
    max: '2',
    customValue: ['Pressure', 'Flow', 'OFF'],
  },
  setting_b_guide: {
    label: 'B.GUIDE',
    unit: 'BPM',
    min: '0',
    max: '120',
  },
  setting_b_interval: {
    label: 'B.INTERVAL',
    unit: 'min',
    min: '0',
    max: '30',
  },
  setting_ps_h: {
    label: 'PS H',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
  },
  setting_ps_l: {
    label: 'PS L',
    unit: 'cmH₂O',
    min: '0',
    max: '99',
  },
  setting_patient_weight: {
    label: 'PBW',
    unit: 'Kg',
    min: '1',
    max: '150',
  },
  setting_resp_lead: {
    label: 'RESP Lead',
    unit: '',
    min: '0',
    max: '1',
  },
  setting_fpeak: {
    label: 'Flow',
    unit: 'LPM',
    min: '5',
    max: '60',
  },
  setting_hfv_peep: {
    label: 'PEEP',
    unit: 'cmH₂O',
    min: '3',
    max: '45',
  },
  setting_nibp_run: {
    label: 'NIBP Run',
    unit: '',
    min: '',
    max: '',
  },
};
