import { format } from 'date-fns';

type FilenameOption = { prefix?: string; subfix?: string };
const generateFileName = ({ prefix, subfix }: FilenameOption) => {
  const currentTime = format(new Date(), 'yyMMdd');
  return `${prefix || 'report'}_${currentTime}${subfix ? `_${subfix}` : ''}`;
};

export const filenameGenerator =
  ({ prefix, subfix }: FilenameOption) =>
  () =>
    generateFileName({ prefix, subfix });
