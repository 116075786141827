import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);
const minimal = definePartsStyle((props) => {
  const { colorScheme: c, colorMode } = props;

  return {
    th: {
      fontWeight: 700,
      paddingBlock: 0,
      height: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inehrit',
      borderBottomWidth: '0px',
      '&[data-is-numeric=true]': {
        textAlign: 'end',
      },
    },
    td: {
      paddingBlock: 0,
      height: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inehrit',
      borderBottomWidth: '0px',
      '&[data-is-numeric=true]': {
        textAlign: 'end',
      },
    },
    caption: {
      color: `${c}.100`, // colorMode === 'light' ? `${c}.600` : `${c}.100`,
    },
    thead: {
      tr: {
        background: `${c}.900`, // colorMode === 'light' ? `${c}.100` : `${c}.900`,
      },
    },
    tbody: {
      tr: {
        background: `${c}.900`, // colorMode === 'light' ? `${c}.100` : `${c}.900`,
        _hover: {
          background: `${c}.700`, // colorMode === 'light' ? `${c}.300` : `${c}.700`,
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});
const report = definePartsStyle(() => {
  return {
    th: {
      fontWeight: 700,
      paddingBlock: 0,
      height: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inehrit',
      color: 'inherit',
      borderBottomWidth: '0px',
      '&[data-is-numeric=true]': {
        textAlign: 'end',
      },
    },
    td: {
      paddingBlock: 0,
      height: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inehrit',
      color: 'inherit',
      borderBottomWidth: '0px',
      '&[data-is-numeric=true]': {
        textAlign: 'end',
      },
    },
    tbody: {
      tr: {
        _hover: { background: 'inherit' },
      },
    },
  };
});

const Table: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => ({
    th: {
      h: '58px',
      fontFamily: 'SUIT',
      fontWeight: '500',
      textTransform: 'inherit',
      color: colorMode === 'dark' ? 'white' : 'black',
    },
    td: {
      px: 8,
      fontFamily: 'SUIT',
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'inherit',
      color: colorMode === 'dark' ? 'white' : 'black',
      h: '58px',
      borderBottomColor: '#454C53 !important',
    },
    tbody: {
      tr: {
        _hover: {
          bg: colorMode === 'dark' ? '#454C53' : 'blackAlpha.100',
        },
      },
    },
  }),
  variants: {
    basic: {
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
        tr: {
          h: '48px',
          mb: 2,
        },
        th: {
          px: 8,
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: '400',
          textTransform: 'inherit',
          _first: {
            roundedLeft: '8px',
          },
          _last: {
            roundedRight: '8px',
          },
        },
        td: {
          px: 8,
        },

        thead: {
          tr: {
            rounded: '8px',
            bg: 'brand.100',
            color: '#66656a',
          },
        },

        tbody: {
          tr: {
            bg: 'white',
            td: {
              borderBottom: '1px solid',
              borderColor: 'brand.100',
              _first: {
                borderTopLeftRadius: '8px',
              },
              _last: {
                borderTopRightRadius: '8px',
              },
            },
          },
        },
      },
    },

    bright: ({ colorMode }) => ({
      tbody: {
        tr: {
          _hover: {
            bg: colorMode === 'dark' ? 'gray' : 'blackAlpha.100',
            color: colorMode === 'dark' ? 'white' : 'black',
          },
          color: '#000000',
        },
        td: {
          color: 'unset',
        },
      },
    }),

    unstyled: {
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0 14px',
      },
      th: {
        p: '4px 0',
        fontWeight: 'inherit',
        textTransform: 'inherit',
      },
      td: {
        p: 0,
        fontWeight: 'inherit',
      },
    },
    minimal,
    report,
  },
  defaultProps: {},
};

export default Table;
