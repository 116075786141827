import { PropsWithChildren, memo, useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { LineChart, CartesianGrid, XAxis, YAxis, Line, Curve, Customized } from 'recharts';

import { useA4Page } from '@/hooks/usePdf';
import { useWaveReportStoreContext } from '@/pages/ReportProcessor/WaveReport/useWaveReportStore';

const FixedText = ({
  children,
  left,
  bottom,
  right,
  top,
}: PropsWithChildren<{
  left?: number | string;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
}>) => {
  const { convertMmToPx } = useA4Page({});

  return (
    <Box
      pos="absolute"
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      bg="white"
      zIndex={1}
      paddingInline={1}
      fontSize={`${convertMmToPx(3)}px`}
      lineHeight={`${convertMmToPx(5)}px`}
    >
      {children}
    </Box>
  );
};

export type SingleData = { x: number; y: number };
interface Props {
  data?: SingleData[];
  label?: string;
  samplingRate?: number;
  resolution?: number;
  page?: number;
}

const EcgWave28sec = ({ data = [], samplingRate = 240, resolution = 150, label = '', page = 1 }: Props) => {
  const { convertMmToPx } = useA4Page({});

  const cellMm = 5;
  const cellPx = convertMmToPx(cellMm);

  const borderWidthPx = 1;
  const dashPx = cellPx / 10;

  const secondsInRow = 7;
  const cellInWidth = secondsInRow * 5;
  const cellInHeight = 8;

  const domainY = [-2 * resolution, 2 * resolution];
  const domainX = [0, samplingRate * secondsInRow];

  const horizontalPoints = Array.from({ length: cellInHeight - 1 }, (_, index) => (index + 1) * cellPx);
  const verticalPoints = Array.from({ length: cellInWidth - 1 }, (_, index) => (index + 1) * cellPx);

  const formattedDataList = useMemo(() => {
    const populateData = (data: (SingleData | undefined)[]) => {
      return { startTimestamp: data[0]?.x ? format(data[0]?.x, 'HH:mm:ss @ yyyy-MM-dd') : '', data };
    };
    const row1 = data.slice(0, samplingRate * secondsInRow);
    const row2 = data.slice(samplingRate * secondsInRow, 2 * samplingRate * secondsInRow);
    const row3 = data.slice(2 * samplingRate * secondsInRow, 3 * samplingRate * secondsInRow);
    const row4 = data.slice(3 * samplingRate * secondsInRow, 4 * samplingRate * secondsInRow);
    return [row1, row2, row3, row4].map(populateData);
  }, [data, samplingRate]);

  const updateIsDataProcessed = useWaveReportStoreContext((state) => state.updateIsDataProcessed);
  const processedTrigger = useMemo(() => {
    const populatedData = formattedDataList.flatMap((value) => value.data).flat();
    return data.length > 0 && populatedData.every((value) => value !== undefined);
  }, [data, formattedDataList]);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (processedTrigger === true) {
      timer = setTimeout(() => {
        updateIsDataProcessed(page - 1);
      }, 0);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [page, processedTrigger, updateIsDataProcessed]);

  return (
    <>
      {formattedDataList.map(({ data, startTimestamp }, index) => (
        <Box
          key={`${index.toString()}`}
          w="max-content"
          h="max-content"
          borderBlock={`solid ${borderWidthPx}px black`}
          mb={`${cellPx}px`}
        >
          <Box pos="relative" w={convertMmToPx(175)} h={convertMmToPx(40)}>
            {/* Time: wave data start */}
            <FixedText top={0} left={0}>
              {startTimestamp}
            </FixedText>
            {/* Wave Label */}
            {index === 0 && (
              <FixedText top={`${cellPx}px`} left={0}>
                {label}
              </FixedText>
            )}
            {/* Sweep speed */}
            <FixedText top={0} right={0}>
              {`${cellMm * 5}mm/sec`}
            </FixedText>
            {/* Chart */}
            <LineChart
              style={{ position: 'absolute', top: 0, left: 0 }}
              width={convertMmToPx(175)}
              height={convertMmToPx(40)}
              margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
              data={data}
            >
              {/* Grid */}
              <CartesianGrid
                strokeWidth={borderWidthPx}
                stroke="#718096"
                strokeDasharray={`${dashPx} ${dashPx}`}
                horizontalPoints={horizontalPoints}
                verticalPoints={verticalPoints}
              />
              {/* ECG gain */}
              <Customized
                component={
                  <Curve
                    fill="#00000000" // transparant
                    points={[
                      { x: 0.8 * cellPx, y: 4 * cellPx },
                      { x: 1.2 * cellPx, y: 2 * cellPx },
                      { x: 1.8 * cellPx, y: 2 * cellPx },
                      { x: 2.2 * cellPx, y: 4 * cellPx },
                    ]}
                    stroke="#4299E1"
                    strokeWidth={2}
                    type="step"
                  />
                }
              />
              {/* Axis */}
              <XAxis dataKey="x" domain={domainX} hide />
              <YAxis dataKey="y" domain={domainY} hide />
              {/* Line */}
              <Line type="linear" dataKey="y" stroke="#000" dot={false} isAnimationActive={false} />
            </LineChart>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default memo(EcgWave28sec);
