import { PropsWithChildren, useEffect } from 'react';

import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { API_LOGIN_REFRESH, API_LOGIN } from '@/apis/auth/uri';
import { instance as axios, mqttPusherInstance } from '@/apis/index';
import { toastId } from '@/constants/id/toast';
import { useLoginInfoStore } from '@/contexts/loginInfoStore/useLoginInfoStore';
import useResetStore from '@/contexts/resetStore/useResetStore';
import useLanguage from '@/hooks/useLanguage';
import useLogout from '@/pages/CentralMain/hooks/useLogout';
import { appendAuthToken, getAuthToken, getRefreshToken } from '@/utils/localStorage/auth';

let refreshPromise: Promise<AxiosResponse> | null = null;

const getAccessToken = () => {
  if (refreshPromise) {
    return refreshPromise;
  }
  refreshPromise = axios.post(API_LOGIN_REFRESH).finally(() => {
    refreshPromise = null;
  });
  return refreshPromise;
};

export const AxiosInterceptor = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { translate } = useLanguage();
  const { logout } = useLogout();

  // Amy : 사용자 아이디
  const { userId, fingerprint } = useLoginInfoStore(
    (state) => ({ userId: state.userId, fingerprint: state.fingerprint }),
    shallow,
  );

  // Amy: 재설정 중에는 logout 시키지 않기
  const { isReset } = useResetStore(
    (state) => ({
      isReset: state.isReset,
    }),
    shallow,
  );

  useEffect(() => {
    const reqInterceptor = (config: any) => {
      const accessToken = getAuthToken();
      console.log(`============Request Interceptor Run============ :: ${config.url}`);

      const { headers } = config;
      const header = {
        ...headers,
        'Access-Control-Allow-Origins': '*',
        'Content-Type': 'application/json',
        fingerprint: `${fingerprint}`,
      };

      if (accessToken) {
        header.Authorization = `Bearer ${accessToken}`;
      }
      if (config.url === API_LOGIN_REFRESH) {
        const refreshToken = getRefreshToken();
        header.Refresh = refreshToken;
      }

      console.log({ ...config, headers: header });

      return { ...config, headers: header };
    };

    const reqErrInterceptor = async (error: any) => {
      return Promise.reject(error);
    };

    const resInterceptor = (response: any) => {
      console.log(`============Response Interceptor Run :: Success :: ${response.config.url}`);
      console.log(response);
      return response;
    };

    const resErrInterceptor = async (error: any) => {
      console.log('============Response Interceptor Run :: Error');
      console.log(error);

      if (error.code === 'ERR_NETWORK' && process.env.REACT_APP_MODE !== 'demo') {
        if (!toast.isActive(toastId.networkError)) {
          if (error.config?.baseURL?.includes('http:')) {
            toast({
              id: toastId.networkError,
              title: `${translate('networkError')}`,
              description: `${translate('checkMixedContent')}`,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          } else {
            toast({
              id: toastId.networkError,
              title: `${translate('networkError')}`,
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        }
      }

      const { status } = error.response.request;
      /*
            unAuthorized
              1. INFO_0007: 만료된 토큰인 경우
              2. 권한이 없는 경우
            */
      if (status === 401 && !isReset) {
        const { config: originalRequest } = error;
        console.log(`error 401 ======== res : ${error.response.data.resCode}`);
        if (error.response.data.resCode === 'INFO_0007') {
          const response = await getAccessToken();
          if (response.status === 200) {
            appendAuthToken(response.data.access_token, response.data.refresh_token);
            return axios(originalRequest);
          }
          if (!toast.isActive(toastId.expiredToken)) {
            toast({
              id: toastId.expiredToken,
              title: `${translate('expiredToken')}`,
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
          console.log('INFO 0007 logout');
          logout();
          return Promise.reject(error);
        }

        if (originalRequest.url === API_LOGIN) {
          toast({
            title: `${translate('loginFailed')}`,
            description: `${translate('loginFailedDescription')}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          return Promise.reject(error);
        }

        if (error.response.data.resCode === 'INFO_0018') {
          toast({
            title: `${translate('loggedOutByAccessingTheSameId')}`,
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          console.log('INFO_0018 logout');
          logout();
          return Promise.reject(error);
        }
        if (error.response.data.resCode.includes('ERROR_LOGOUT_')) {
          if (!toast.isActive(toastId.noPermission)) {
            toast({
              id: toastId.noPermission,
              title: `${translate('noPermission')}`,
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
          logout();
          return Promise.reject(error);
        }

        if (!toast.isActive(toastId.noPermission)) {
          toast({
            id: toastId.noPermission,
            title: `${translate('noPermission')}`,
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
        return Promise.reject(error);
      }
      toast({
        title: `${translate('serverError')}`,
        // description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      console.log('400, 403, 404, etc.');
      return Promise.reject(error);
    };

    const requestInterceptor = axios.interceptors.request.use(reqInterceptor, reqErrInterceptor);
    const responseInterceptor = axios.interceptors.response.use(resInterceptor, resErrInterceptor);

    const requestInterceptorForMqttPusher = mqttPusherInstance.interceptors.request.use(
      reqInterceptor,
      reqErrInterceptor,
    );
    const responseInterceptorForMqttPusher = mqttPusherInstance.interceptors.response.use(
      resInterceptor,
      resErrInterceptor,
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      mqttPusherInstance.interceptors.request.eject(requestInterceptorForMqttPusher);
      mqttPusherInstance.interceptors.response.eject(responseInterceptorForMqttPusher);
    };
  }, [fingerprint, isReset, logout, navigate, toast, translate, userId]);

  return <>{children}</>;
};
