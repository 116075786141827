const global = {
  body: {
    color: 'gray.900',
    height: '100vh',
    overflow: 'hidden',
    fontSize: 'sm',
    fontWeight: 300,
  },

  a: {
    display: 'inline-block',
    _hover: {
      textDecoration: 'none',
    },
  },
  'img, span': { display: 'inline-block' },
  svg: { display: 'inline-block', verticalAlign: 'middle', margin: '0 auto' },
  textarea: { resize: 'none' },
  '.fade-out': {
    opacity: 0,
    width: 0,
    height: 0,
    transition: 'width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s',
  },

  '.fade-in': {
    opacity: 1,
    transition: 'width 0.2s, height 0.2s, opacity 0.2s 0.2s',
  },
  '#root, #root > div': {
    height: '100%',
  },
  '.chakra-form-control label': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  '.react-select__control--menu-is-open .react-select__dropdown-indicator': {
    transform: 'rotate(180deg)',
  },

  '.chakra-link.chakra-button': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default global;
