import React from 'react';

import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SciChartSurface } from 'scichart';

import App from './App';

import './index.css';

// 배포 환경에서 console.log, console.warn 지우기
if (process.env.REACT_APP_NO_LOG === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noConsole = () => {};
  console.log = noConsole;
  console.error = noConsole;
  console.debug = noConsole;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Set this code in application startup, before creating a SciChartSurface
SciChartSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCICHART_LISENCE_KEY as string);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FpjsProvider loadOptions={{ apiKey: process.env.REACT_APP_FINGERPRINTJS_APP_KEY as string }}>
        <App />
      </FpjsProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
