import { TTrendSetting, TWaveSetting } from '@/pages/CentralMain/types';

// TODO: 파라미터 확인 필요
export const HFTTrendSetting: TTrendSetting = {
  mode: 'duo',
  duo: [['rox', 'sf_ratio']],
  single: ['rox', 'sf_ratio', 'rr', 'fio2', 'flow', 'pi'],
};
export const MPTrendSetting: TTrendSetting = {
  mode: 'single',
  duo: [],
  single: ['hr', 'nibp', 'ibp1', 'ibp2', 'rr', 'tmp1', 'tmp2', 'spo2', 'etco2'],
};
export const MV50TrendSetting: TTrendSetting = {
  mode: 'single',
  duo: [],
  single: ['ppeak', 'ppause', 'pmean', 'peep', 'rr', 'rr_spont', 'etco2'],
};
export const MV2000TrendSetting: TTrendSetting = {
  mode: 'single',
  duo: [],
  single: ['vme', 'pmean', 'ppeak', 'peep', 'vte', 'rr'],
};

export const HFTWaveSetting: TWaveSetting = {
  keys: [],
};
export const MPWaveSetting: TWaveSetting = {
  keys: [
    // 'ecg_wave_i',
    'ecg_wave_ii',
    // 'ecg_wave_iii',
    // 'ecg_wave_avf',
    // 'ecg_wave_avl',
    // 'ecg_wave_avr',
    // 'ecg_wave_v',
    'ibp1_wave',
    'ibp2_wave',
    'resp_wave',
    'spo2_wave',
  ],
};
export const MV50WaveSetting: TWaveSetting = {
  keys: [],
};
export const MV2000WaveSetting: TWaveSetting = {
  keys: [],
};
