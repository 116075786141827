import { useCallback, useEffect, useRef } from 'react';

import { debounce } from 'lodash-es';
import { shallow } from 'zustand/shallow';

import useScreenInfoStore from '@/contexts/viewControlStore/useScreenInfoStore';
import { ScreenInfo, ViewRole } from '@/pages/CentralMain/types';

import useCurrentViewInfo from './useCurrentViewInfo';

const useUserScreenDetails = () => {
  const screenDetails = useRef<any>();

  const getWindowManagementPermissionState = async () => {
    let state;
    // The new permission name.
    try {
      ({ state } = await navigator.permissions.query({
        name: 'window-management' as PermissionName,
      }));
    } catch (err) {
      if (err.name !== 'TypeError') {
        return `${err.name}: ${err.message}`;
      }
      // The old permission name.
      try {
        ({ state } = await navigator.permissions.query({
          name: 'window-placement' as PermissionName,
        }));
      } catch (err) {
        if (err.name === 'TypeError') {
          return 'Window management not supported.';
        }
        return `${err.name}: ${err.message}`;
      }
    }
    return state;
  };

  const pollyfill = useCallback(() => {
    // polyfill
    if (!('getScreenDetails' in window)) {
      // note: Property 'getScreenDetails' does not exist on type 'never'.
      (window as any).getScreenDetails = () => ({ currentScreen: window.screen, screens: [window.screen] });
    }
    if (!window.screenLeft) {
      window.screenLeft = window.screenX;
      window.screenTop = window.screenY;
    }
  }, []);

  useEffect(() => {
    pollyfill();
  }, [pollyfill]);

  const getCurrentScreenInfo = useCallback((): Partial<ScreenInfo> => {
    pollyfill();
    return {
      targetScreen: {
        availLeft: window.screen.availLeft || 0,
        availTop: window.screen.availTop || 0,
      },
      windowPosition: { screenLeft: window.screenLeft, screenTop: window.screenTop },
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  }, [pollyfill]);

  const { registerScreen, main, viewOnly } = useScreenInfoStore(
    (state) => ({
      registerScreen: state.registerScreen,
      main: state.main,
      viewOnly: state.viewOnly,
    }),
    shallow,
  );

  const { viewRole } = useCurrentViewInfo();

  // update screen info: current screen change
  const handleRegisterScreen = useCallback(() => {
    if (viewRole) registerScreen(viewRole, getCurrentScreenInfo());
  }, [getCurrentScreenInfo, registerScreen, viewRole]);

  useEffect(() => {
    const getScreenDetail = async () => {
      const permission = await getWindowManagementPermissionState();
      if (permission !== 'granted') {
        console.log('not permitted');
      }
      // Request information required to place content on specific screens.
      try {
        const screenDetailsResult = await window.getScreenDetails();
        screenDetailsResult.addEventListener?.('currentscreenchange', handleRegisterScreen);
        screenDetails.current = screenDetailsResult;
      } catch (err) {
        // Amy: error 던지지 말고 log 찍기
        console.log(err);
      }
    };
    if (screenDetails.current == null) {
      getScreenDetail();
    }
  }, [getCurrentScreenInfo, handleRegisterScreen, viewRole]);
  // update screen info: window resize, load
  useEffect(() => {
    const debouncedHandleRegisterScreen = debounce(handleRegisterScreen, 150);

    window.addEventListener('resize', debouncedHandleRegisterScreen);
    window.addEventListener('load', handleRegisterScreen);

    return () => {
      window.removeEventListener('resize', debouncedHandleRegisterScreen);
      window.removeEventListener('load', handleRegisterScreen);
    };
  }, [handleRegisterScreen]);

  const getSecondWindowPosition = () => {
    // Find a different screen, fill its available area with a new window.
    const otherScreen =
      screenDetails.current?.screens.find((s: any) => s !== screenDetails.current?.currentScreen) ||
      screenDetails.current?.currentScreen;

    return `left=${otherScreen.availLeft},top=${otherScreen.availTop},width=${otherScreen.availWidth},height=${otherScreen.availHeight}`;
  };

  const getLatestSavedWindowPosition = useCallback(
    (viewRole: ViewRole) => {
      const latestScreenInfo = viewRole === 'main' ? main : viewOnly;

      const isWindowAvailable = screenDetails.current?.screens.find((screen: any) => {
        return (
          screen.availLeft === latestScreenInfo?.targetScreen.availLeft &&
          screen.availTop === latestScreenInfo?.targetScreen.availTop
        );
      });

      if (isWindowAvailable == null) {
        return `left=${latestScreenInfo?.windowPosition.screenLeft || 0},top=${
          latestScreenInfo?.windowPosition.screenTop || 0
        },width=${latestScreenInfo?.windowSize.width},height=${latestScreenInfo?.windowSize.height}`;
      }

      return `left=${latestScreenInfo?.windowPosition.screenLeft},top=${latestScreenInfo?.windowPosition.screenTop},width=${latestScreenInfo?.windowSize.width},height=${latestScreenInfo?.windowSize.height}`;
    },
    [main, viewOnly],
  );

  const requestCurrentWindowFullScreen = useCallback(async () => {
    try {
      const currentScreen = screenDetails.current?.currentScreen;
      if (currentScreen) {
        await document.body.requestFullscreen({ screen: currentScreen } as FullscreenOptions);
      }
    } catch (err) {
      console.error(err.name, err.message);
    }
  }, []);

  return {
    getSecondWindowPosition,
    getLatestSavedWindowPosition,
    getWindowManagementPermissionState,
    requestCurrentWindowFullScreen,
  };
};

export default useUserScreenDetails;
