import { useMemo } from 'react';

import { useQuery, useQueries, UseQueryOptions, useMutation } from '@tanstack/react-query';
import { addSeconds } from 'date-fns';
import { shallow } from 'zustand/shallow';

import { TTrend } from '@/constants/types';
import { useMockDataStore } from '@/contexts/mockDataStore';
import { mqttKeys, useMqttConnection } from '@/contexts/MqttConnectionProvider';
import { postPatCurrListHandler } from '@/mock/apis';
import { useMockPatientList } from '@/mock/apis/patient';
import { devices } from '@/mock/data';
import { ResTotalTrendData, ResWaveOverview } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { Alarm, DeviceModel } from '@/pages/CentralMain/types';
import { makeLocalTimeToUTC0 } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { parseDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { ceilSeconds, floorSeconds, parseUTC0String } from '@/utils/dateTimeUtils';

import {
  ReqAdmitPatient,
  ReqAllParamsTrend,
  ReqConnectionInfos,
  ReqDeviceConnectionList,
  ReqDeviceUpdate,
  ReqDischargePatient,
  ReqPatientInfo,
  ReqPatientList,
  ReqTrendData,
  ResDeviceConnectionList,
  ResDeviceData,
  ResLastDeviceInfo,
  ResNibpTrend,
  ResPatientDevicePairList,
  ResPatientInfo,
  ResPatientList,
  ResRoxTrendData,
  ResTrendData,
  ResWaveDetail,
} from './type';

import {
  getAllParamTrend,
  getLastDeviceInfo,
  getNibpTrend,
  getPatientDevicePairList,
  getRoxTrend,
  getTrend,
  getWaveDetail,
  getWaveOverview,
  patientApis,
} from './index';

const generateDeviceDataQuery = <TData = ResDeviceData>(
  deviceId: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
): DeviceDataQueryOption<TData> => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return {
      queryKey: mqttKeys.detail(deviceId),
      queryFn: () => {
        const targetMockData = devices.find((device) => device.getKey() === deviceId);
        return targetMockData ? targetMockData.getPdpData() : undefined;
      },
      refetchInterval: 1000,
      ...options,
    };
  }
  return {
    queryKey: mqttKeys.detail(deviceId),
    queryFn: () => {
      return new Promise<ResDeviceData>((resolve) => {
        const data = JSON.parse('null');
        resolve(data);
      });
    },
    staleTime: 5000,
    cacheTime: 5000,
    ...options,
  };
};

type DeviceDataQueryOption<TData> = UseQueryOptions<ResDeviceData | undefined, unknown, TData>;
export const useDeviceDataDetail = <TData = ResDeviceData>(
  deviceId: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queryOption = useMemo(() => generateDeviceDataQuery(deviceId, options), [deviceId, options]);
  const query = useQuery(queryOption);
  return query;
};

export const useNullableDeviceDataDetail = <TData = ResDeviceData>(
  deviceId?: string,
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queryOption = deviceId
    ? generateDeviceDataQuery(deviceId, options)
    : {
        queryKey: ['none'],
        queryFn: () => {
          return undefined;
        },
      };
  const query = useQuery(queryOption);
  return query;
};

const useDeviceDataList = <TData = ResDeviceData>(
  deviceIds: string[],
  options?: Pick<DeviceDataQueryOption<TData>, 'select'>,
) => {
  const queries = deviceIds.map((deviceId) => generateDeviceDataQuery(deviceId, options));
  return useQueries({
    queries,
  });
};

const convertAlarmLevelToNumber = (alarm: Alarm) => {
  switch (alarm?.alarm_level) {
    case 'HIGH':
      return 3;
    case 'MEDIUM':
      return 2;
    case 'LOW':
      return 1;
    default:
      return 0;
  }
};

export const useDeviceHighestAlarmData = (deviceIds: string[]) => {
  const queryResults = useDeviceDataList(deviceIds, {
    select: (data) => {
      const alarms = data?.alarms;
      if (!alarms || alarms.length === 0) {
        return 0;
      }
      const highestAlarm = Math.max(...alarms.map(convertAlarmLevelToNumber));
      return highestAlarm;
    },
  });

  const alarmDataList = queryResults.map((result) => result.data || 0).flat();
  const highestAlarm = Math.max(...alarmDataList);

  return highestAlarm;
};

// note: old version (use new function: useDeviceDataDetail)
type PdpCurrListQueryOption<TData> = UseQueryOptions<ResPatientDevicePairList, unknown, TData>;
export const usePatientDevicePairDataList = <TData = ResPatientDevicePairList>(
  select?: Pick<PdpCurrListQueryOption<TData>, 'select'>,
) => {
  const targetTopic = process.env.REACT_APP_MQTT_TOPIC;
  const mqtt = useMqttConnection();

  const defaultOptions: PdpCurrListQueryOption<TData> = {
    queryKey: mqttKeys.pdpList,
    queryFn: () => {
      return new Promise<ResPatientDevicePairList>((resolve, reject) => {
        if (mqtt?.client) {
          mqtt.client.on('connect', () => {
            if (targetTopic) {
              mqtt.client.subscribe(targetTopic);
            }
          });
          mqtt.client.on('message', (topic, message) => {
            // Assuming your MQTT data is in JSON format
            try {
              const data = JSON.parse(message.toString());
              resolve(data);
            } catch (err) {
              reject(err);
            }
          });
        }
      });
    },
    staleTime: Infinity,
    ...select,
  };
  // note: for offline/rest-api demo
  const optionsForOfflineAndRestAPI = {
    queryKey: ['pdpList'],
    queryFn: getPatientDevicePairList,
    refetchInterval: 1000,
    ...select,
  };

  const queryOption: PdpCurrListQueryOption<TData> =
    process.env.REACT_APP_MODE === 'demo' ? optionsForOfflineAndRestAPI : defaultOptions;

  return useQuery(queryOption);
};

export const useRoxtrendData = (data: ReqTrendData, isHidden: boolean) => {
  return useQuery<ResRoxTrendData>({
    queryKey: ['rox', 'trend', data.deviceSerial, isHidden],
    queryFn: () => {
      return getRoxTrend(data);
    },
    refetchInterval: 1000 * 60 * 1, // 1min
  });
};

type TTrendData = ResTrendData['data'];
export const useFormattedRoxtrendData = (req: ReqTrendData) => {
  return useQuery<ResRoxTrendData, unknown, { data: TTrendData }>(
    ['rox', 'trend', 'formatted', req.deviceSerial],
    () => {
      return getRoxTrend(req);
    },
    {
      select: (rawData) => {
        const data: TTrendData = rawData.data
          .map((target) => {
            return (
              {
                deviceSerial: rawData.deviceId,
                dateTime: target.dateTime,
                value: target.roxValue,
              } || []
            );
          })
          .flat();
        return { data };
      },
      refetchInterval: 1000 * 60 * 1, // 1min
    },
  );
};

export const useTrendData = (data: ReqTrendData, param: TTrend) => {
  return useQuery<ResTrendData>({
    queryKey: [data, param],
    queryFn: () => {
      return getTrend(data, param);
    },
    refetchInterval: 1000 * 60 * 1, // 1min
  });
};

// Amy: Apply 버튼에서는 처음에 enable 하지 않기 위해
export const useDeviceConnectionList = (data: ReqDeviceConnectionList, refresh: boolean, isEnable = true) => {
  const { connectionInfo, alias } = useMockDataStore(
    (state) => ({ connectionInfo: state.connectionInfo, alias: state.mockDeviceAlias }),
    shallow,
  );

  const demoResponse = postPatCurrListHandler(data, connectionInfo, alias);
  return useQuery<ResDeviceConnectionList>({
    queryKey: [data, refresh],
    queryFn: () => {
      return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.deviceConnectionList(data);
    },
    enabled: isEnable && data.filter !== '',
  });
};

export const usePatientInfoDetail = (pat: string, isOpenEdit: boolean) => {
  const { mockPatient } = useMockDataStore((state) => ({ mockPatient: state.mockPatient }), shallow);
  const patient = mockPatient.find((item) => item.pat === pat);

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
      ...patient,
    }),
  );

  return useQuery<ResPatientInfo>({
    queryKey: [pat, isOpenEdit],
    queryFn: () => {
      return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.patientInfoDetail(pat);
    },
    retry: false,
    enabled: !!pat,
  });
};

export const usePatientList = (data: ReqPatientList, isOpenReg: boolean) => {
  const mockPatient = useMockPatientList(data);
  const demoResponse = new Promise<ResPatientList>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
      totalCount: '0',
      list: mockPatient,
    }),
  );
  return useQuery<ResPatientList>({
    queryKey: [data, isOpenReg],
    queryFn: () => {
      return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.patientList(data);
    },
  });
};

export const usePatientInfoUpdate = (data: ReqConnectionInfos) => {
  return useQuery<ResDeviceConnectionList>({
    queryKey: [data, 'Update'],
    queryFn: () => {
      return patientApis.getConnectionInfos(data);
    },
    refetchInterval: 3 * 1000,
    enabled: process.env.REACT_APP_MODE !== 'demo',
  });
};

export const useAdmitPatient = () => {
  const { admit, mockPatient } = useMockDataStore(
    (state) => ({ admit: state.admitConnectionInfo, mockPatient: state.mockPatient }),
    shallow,
  );

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
    }),
  );
  const mutation = useMutation<any, Error, ReqAdmitPatient>((data: ReqAdmitPatient) => {
    if (process.env.REACT_APP_MODE === 'demo') {
      const patient = mockPatient.find((item) => item.pat === data.pat);
      admit(data, patient);
    }
    return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.admitPatient(data);
  });

  return mutation;
};

export const useDisChargePatient = () => {
  const { discharge } = useMockDataStore((state) => ({ discharge: state.dischargeConnectionInfo }), shallow);

  const demoResponse = new Promise<any>((resolve) =>
    resolve({
      resCode: 'SUCCESS',
      resMsg: '',
    }),
  );
  const mutation = useMutation<any, Error, ReqDischargePatient>((data: ReqDischargePatient) => {
    if (process.env.REACT_APP_MODE === 'demo') {
      discharge(data);
    }
    return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.dischargePatient(data);
  });

  return mutation;
};

const demoResponse = new Promise<any>((resolve) =>
  resolve({
    resCode: 'SUCCESS',
    resMsg: '',
  }),
);

export const usePatientDetailUpdate = () => {
  const { update } = useMockDataStore((state) => ({ update: state.mockPatientUpdate }), shallow);

  const mutation = useMutation<any, Error, ReqPatientInfo>((data: ReqPatientInfo) => {
    if (process.env.REACT_APP_MODE === 'demo') {
      update(data);
    }

    const demoRegResponse = new Promise<any>((resolve) =>
      resolve({
        resCode: 'SUCCESS',
        resMsg: '',
        ...data,
        pat: data.pat,
      }),
    );

    return process.env.REACT_APP_MODE === 'demo' ? demoRegResponse : patientApis.patientInfoUpdate(data);
  });

  return mutation;
};

export const usePatientReg = () => {
  const { reg } = useMockDataStore((state) => ({ reg: state.mockPatientReg }), shallow);

  const mutation = useMutation<any, Error, ReqPatientInfo>((data: ReqPatientInfo) => {
    if (process.env.REACT_APP_MODE === 'demo') {
      reg(data);
    }
    const demoRegResponse = new Promise<any>((resolve) =>
      resolve({
        resCode: 'SUCCESS',
        resMsg: '',
        ...data,
        pat: data.patId,
      }),
    );
    return process.env.REACT_APP_MODE === 'demo' ? demoRegResponse : patientApis.patientInfoReg(data);
  });

  return mutation;
};

export const useDeviceUpdate = () => {
  const { deviceUpdate } = useMockDataStore((state) => ({ deviceUpdate: state.mockDeviceAliasUpdate }), shallow);

  const mutation = useMutation<any, Error, ReqDeviceUpdate>((data: ReqDeviceUpdate) => {
    if (process.env.REACT_APP_MODE === 'demo') {
      deviceUpdate(data);
    }
    return process.env.REACT_APP_MODE === 'demo' ? demoResponse : patientApis.deviceUpdate(data);
  });

  return mutation;
};

// Amy: 분단위로 계산
export const useAllParamsTrend = (from: string, to: string, id: string) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(parseUTC0String(from)));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(parseUTC0String(to)));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  // const req: ReqAllParamsTrend = {
  //   manufacturerCode: '01',
  //   model: 'MP1300',
  //   deviceSerial: 'MPS_03',
  //   start: '2024-08-01T07:31:34.000Z',
  //   end: '2024-08-07T07:31:34.000Z',
  //   sortOrder: 1,
  // };

  return useQuery<ResTotalTrendData<DeviceModel>>({
    queryKey: ['trend', 'allParam', startTime, endTime, id],
    queryFn: () => {
      return getAllParamTrend(req);
    },
  });
};

export const useNibpTrend = (from: string, to: string, id: string) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(parseUTC0String(from)));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(parseUTC0String(to)));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResNibpTrend>({
    queryKey: ['trend', 'nibp', startTime, endTime, id],
    queryFn: () => {
      return getNibpTrend(req);
    },
    staleTime: 60000,
  });
};

export const useNibpTrendByPeriod = (id: string, from: Date, to: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(from));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(to));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResNibpTrend>({
    queryKey: ['trend', 'nibp', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getNibpTrend(req);
    },
    suspense: process.env.REACT_APP_MODE !== 'demo',
  });
};

export const useWaveOverview = (startTime: string, endTime: string, isUseDetailAPI: boolean, id: string) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResWaveOverview>({
    queryKey: ['wave', 'overview', startTime, endTime, isUseDetailAPI, id],
    queryFn: () => {
      return isUseDetailAPI ? getWaveDetail(req) : getWaveOverview(req);
    },
    staleTime: 60000,
  });
};

export const useWaveDetail = (sec: number, id: string, baseDatetime: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);
  const from = baseDatetime;
  const to = addSeconds(new Date(from.getTime()), sec);

  const startTime = makeLocalTimeToUTC0(from);
  const endTime = makeLocalTimeToUTC0(to);
  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResWaveDetail>({
    queryKey: ['wave', 'detail', id, `${startTime}-${endTime}`],
    queryFn: () => {
      return getWaveDetail(req);
    },
    staleTime: 60000,
  });
};

export const useTrendAllByPeriod = (id: string, from: Date, to: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(floorSeconds(from));
  const endTime = makeLocalTimeToUTC0(ceilSeconds(to));

  const req: ReqAllParamsTrend = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
    sortOrder: 1,
  };

  return useQuery<ResTotalTrendData<DeviceModel>>({
    queryKey: ['trend', 'allParam', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getAllParamTrend(req);
    },
    suspense: process.env.REACT_APP_MODE !== 'demo',
  });
};

export const useLastDeviceInfoByPeriod = (id: string, from: Date, to: Date) => {
  const { deviceSerial, manufacturer, model } = parseDataId(id);

  const startTime = makeLocalTimeToUTC0(from);
  const endTime = makeLocalTimeToUTC0(to);

  const req: Omit<ReqAllParamsTrend, 'sortOrder'> = {
    manufacturerCode: manufacturer,
    model,
    deviceSerial,
    start: startTime,
    end: endTime,
  };

  return useQuery<ResLastDeviceInfo>({
    queryKey: ['last-device-info', `${startTime}-${endTime}`, id],
    queryFn: () => {
      return getLastDeviceInfo(req);
    },
  });
};
