export const mv50Data = {
  pressure: [
    2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 4, 5, 7, 9, 10, 11, 12, 13, 14, 14, 15, 15, 16, 16, 17, 17, 18, 18, 18, 19, 19, 19,
    20, 20, 21, 21, 21, 22, 22, 22, 22, 23, 23, 23, 23, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 27, 27, 27, 27, 28,
    28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 30, 30,
    30, 30, 31, 31, 31, 31, 32, 32, 32, 32, 33, 33, 33, 33, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 35, 35, 35, 35, 36,
    36, 36, 37, 37, 38, 38, 39, 39, 37, 35, 36, 35, 34, 35, 34, 34, 34, 29, 24, 18, 14, 11, 9, 7, 6, 5, 4, 4, 3, 3, 3,
    3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
  ],
  flow: [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 100, 220, 300, 360, 430, 870, 1520, 2210, 2770, 3190,
    3480, 3690, 3820, 3940, 3990, 4000, 4010, 4010, 4000, 4010, 4000, 4000, 4020, 4030, 4030, 4050, 4050, 4040, 4040,
    4050, 4060, 4070, 4070, 4070, 4060, 4060, 4050, 4050, 4040, 4020, 4020, 4010, 4010, 4010, 4010, 4010, 4010, 4010,
    4000, 4000, 3990, 4000, 4010, 4020, 4020, 4010, 4010, 4020, 4020, 4020, 4010, 4000, 4000, 4010, 4020, 4020, 4020,
    4030, 4010, 4000, 3990, 3970, 3940, 3920, 3890, 3870, 3850, 3840, 3830, 3820, 3810, 3800, 3790, 3780, 3780, 3790,
    3800, 3800, 3780, 3780, 3770, 3780, 3770, 3770, 3750, 3730, 3710, 3700, 3670, 3650, 3620, 3600, 3600, 3600, 3590,
    3600, 3590, 3590, 3580, 3570, 3560, 3550, 3560, 3550, 3540, 3540, 3540, 3530, 3520, 3400, 2890, 2340, 1870, 1490,
    1190, 950, 760, 610, 1, -280, -3550, -5670, -5990, -6020, -6090, -6050, -5990, -5920, -5810, -5710, -5620, -5540,
    -5460, -5380, -5310, -5250, -5210, -5190, -5170, -5140, -5110, -5070, -5030, -4990, -4960, -4920, -4910, -4870,
    -4810, -4750, -4690, -4640, -4600, -4550, -4530, -4490, -4460, -4410, -4370, -4340, -4310, -4310, -4310, -4310,
    -4270, -4250, -4230, -4200, -4160, -4140, -4110, -4060, -4010, -3990, -3960, -3920, -3880, -3840, -3820, -3780,
    -3750, -3710, -3690, -3640, -3580, -3540, -3490, -3470, -3430, -3380, -3320, -3270, -3220, -3180, -3140, -3100,
    -3060, -3020, -2990, -2950, -2910, -2860, -2810, -2780, -2740, -2690, -2650, -2600, -2560, -2510, -2460, -2410,
    -2370, -2310, -2260, -2220, -2160, -2100, -2050, -2010, -1970, -1930, -1880, -1840, -1790, -1740, -1700, -1650,
    -1610, -1570, -1520, -1470, -1420, -1380, -1330, -1280, -1250, -1200, -1160, -1110, -1070, -1030, -970, -920, -870,
    -810, -750, -690, -640, -590, -550, -510, -480, -450, -420, -380, -350, -320, -300, -280, -250, -230, -210, -190,
    -160, -140, -120, -110, -100, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  volume: [
    25, 24, 24, 24, 24, 24, 23, 23, 22, 22, 21, 21, 21, 20, 20, 19, 19, 18, 18, 18, 17, 12, 12, 12, 12, 12, 12, 12, 1,
    1, 1, 1, 1, 1, 1, 1, 4, 8, 13, 18, 24, 30, 36, 42, 48, 55, 61, 68, 75, 81, 88, 95, 102, 109, 115, 122, 129, 136,
    143, 150, 156, 163, 170, 177, 184, 191, 197, 204, 211, 218, 225, 231, 238, 245, 252, 259, 265, 272, 279, 286, 293,
    300, 306, 313, 320, 327, 334, 341, 347, 354, 361, 368, 375, 382, 388, 395, 402, 409, 416, 423, 429, 436, 442, 449,
    455, 462, 468, 474, 481, 487, 494, 500, 506, 513, 519, 526, 532, 538, 545, 551, 557, 564, 570, 576, 583, 589, 595,
    601, 607, 613, 620, 626, 632, 638, 642, 648, 652, 658, 666, 672, 682, 744, 748, 752, 755, 757, 759, 760, 761, 762,
    763, 760, 752, 742, 731, 721, 711, 700, 690, 680, 671, 661, 652, 642, 633, 624, 615, 607, 598, 589, 581, 572, 564,
    555, 547, 539, 530, 522, 514, 506, 498, 491, 483, 475, 467, 460, 452, 445, 438, 430, 423, 416, 408, 401, 394, 387,
    380, 373, 366, 359, 352, 345, 338, 331, 324, 317, 311, 304, 298, 291, 285, 278, 272, 266, 260, 254, 248, 242, 236,
    230, 224, 219, 213, 207, 202, 197, 191, 186, 181, 176, 171, 166, 161, 156, 151, 147, 142, 138, 133, 129, 125, 120,
    116, 112, 108, 104, 101, 97, 93, 90, 86, 83, 79, 76, 73, 70, 67, 64, 61, 58, 56, 53, 50, 48, 46, 43, 41, 39, 37, 35,
    33, 31, 29, 28, 26, 24, 23, 22, 20, 19, 18, 17, 16, 15, 14, 17, 16, 16, 16, 15, 15, 15, 14, 14, 14, 13, 13, 13, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 12, 12, 12, 12, 12,
  ],
};
