import React, { createContext, useState, useEffect, useCallback, PropsWithChildren } from 'react';

import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { confirm } from '@/components/Tools/ConfirmAlert';
import useLanguage from '@/hooks/useLanguage';
import useStorageEvent from '@/hooks/useStorageEvent';
import useCurrentViewInfo from '@/pages/CentralMain/hooks/useCurrentViewInfo';
import { routes } from '@/router/routes';
import checkBrowser from '@/utils/checkBrowser';

import { useDualMonitorStore } from './useDualMonitorStore';
import useResetStore from '../resetStore/useResetStore';
import useViewControlStore from '../viewControlStore/useViewControlStore';
import useViewSessionStore from '../viewControlStore/useViewSessionStore';

type WindowState = {
  windowInstance: Window | null;
  setWindowInstance: React.Dispatch<React.SetStateAction<Window | null>>;
  closeWindow: () => void;
};

export const WindowContext = createContext<WindowState | undefined>(undefined);

export const WindowProvider = ({ children }: PropsWithChildren) => {
  const [windowInstance, setWindowInstance] = useState<Window | null>(null);

  const { translate } = useLanguage();

  useEffect(() => {
    if (checkBrowser() !== 'CHROME') {
      confirm({
        title: translate('browserCheck'),
        message: translate('recommendChrome'),
        buttons: [
          {
            label: translate('confirm'),
          },
        ],
      });
    }
  }, [translate]);

  // update dual monitor status
  const { isDualMonitor, setIsDualMonitor } = useDualMonitorStore();
  const updateDualMonitorStatus = useCallback(() => {
    const newStatus = windowInstance?.closed === false || false;
    if (isDualMonitor !== newStatus) {
      setIsDualMonitor(newStatus);
    }
  }, [isDualMonitor, setIsDualMonitor, windowInstance?.closed]);
  useEffect(() => {
    const interval = setInterval(updateDualMonitorStatus, 500);
    return () => {
      clearInterval(interval);
    };
  }, [updateDualMonitorStatus]);

  // 창 닫기 함수
  const closeWindow = useCallback(() => {
    if (windowInstance && !windowInstance.closed) {
      windowInstance.close();
      setWindowInstance(null);
    }
  }, [windowInstance]);

  // 컴포넌트 언마운트 시 창 닫기
  useEffect(() => {
    return () => {
      closeWindow();
    };
  }, [closeWindow]);

  const { viewRole } = useCurrentViewInfo();
  const { viewId } = useViewSessionStore();

  const navigate = useNavigate();
  const { setIsReset, resetViewId } = useResetStore(
    (state) => ({
      setIsReset: state.setIsRest,
      resetViewId: state.resetViewId,
    }),
    shallow,
  );
  const { registerLastWindowSetting } = useViewControlStore(
    (state) => ({
      registerLastWindowSetting: state.registerLastWindowSetting,
    }),
    shallow,
  );
  // Amy: primary view 변경 시 (보통 재설정 버튼 클릭 시) 정리하는 부분
  // Amy: hook 안에 있었지만 여러번 발생되는 문제로 provider로 이동
  const handleViewControlId = useCallback(
    (event: StorageEvent) => {
      if (event.key !== 'controller-view-id' || !event.newValue || event.newValue === event.oldValue) {
        return undefined;
      }
      if (viewRole !== 'main') {
        // 화면 조작은 main만 할 수 있음
        return undefined;
      }
      if (resetViewId === null || resetViewId === viewId) {
        // note: reset(계속 진행하기)을 한 화면이 없거나, 본인인 경우에는 실행하지 않는다.
        return undefined;
      }
      try {
        const storageValue = JSON.parse(event.newValue);
        // console.debug(storageValue?.state, viewId);
        if (storageValue?.state?.controllerViewId !== viewId) {
          // 로그인 창으로 이동
          navigate(routes.HOME.path, { replace: true });
          // 화면 정리
          registerLastWindowSetting({
            main: 'open',
            viewOnly: windowInstance && !windowInstance.closed ? 'open' : 'close',
          });

          closeWindow();
          // Amy: reset이 끝났음으로 업데이트
          setIsReset(false, resetViewId);
        }
      } catch {
        console.error('cannot parse storage value');
      }
      return undefined;
    },
    [closeWindow, navigate, registerLastWindowSetting, resetViewId, setIsReset, viewId, viewRole, windowInstance],
  );
  useStorageEvent({ storageEventCallback: handleViewControlId });

  return (
    <WindowContext.Provider value={{ windowInstance, setWindowInstance, closeWindow }}>
      {children}
    </WindowContext.Provider>
  );
};
