import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Textarea: ComponentStyleConfig = {
  variants: {
    outline: {
      borderRadius: 'lg',
      borderColor: '#D0D0D0',
      color: 'black',
      fontSize: '14px',
      rounded: '4px',
      _focus: {
        shadow: 'none',
      },
      _disabled: {
        bg: '#F5F5F6',
        color: 'gray.700',
        opacity: '1',
      },
      _placeholder: {
        color: 'gray.700',
        fontWeight: '300',
        fontSize: 'inherit',
      },
      _invalid: {
        shadow: 'none',
      },
    },
    addon: {
      bg: 'transparent',
    },
    element: {
      h: 'full',
    },
  },
  defaultProps: {
    focusBorderColor: 'brand.500',
    size: 'md',
    errorBorderColor: 'critical.500',
  },
};

export default Textarea;
