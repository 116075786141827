import { useCallback, useState } from 'react';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const useA4Page = ({ scale = 1 }: { scale?: number }) => {
  const pxPerMm = 8;
  const convertMmToPx = useCallback((mm: number) => mm * pxPerMm * scale, [scale]);
  return { convertMmToPx };
};

const defaultOption = {
  size: [210, 297], // A4
  filename: undefined,
};
export const usePdf = (elementIds: string[], option?: { size?: number[]; filename?: string | (() => string) }) => {
  const { size, filename } = option || defaultOption;

  const getFilename = useCallback(() => {
    if (typeof filename === 'function') {
      return filename();
    }
    return filename;
  }, [filename]);

  const [isProcessing, setIsProcessing] = useState(false);

  const makePdf = useCallback(async () => {
    const imgDataList = await Promise.all(
      elementIds.map(async (id) => {
        const element = { current: document.getElementById(id) };
        if (!element.current) {
          return null;
        }

        const canvas = await html2canvas(element.current, {
          scale: 1,
          onclone: (_, element) => {
            element.style.setProperty('zoom', `${(1 / window.devicePixelRatio) * 100}%`);
          },
        });
        const imgData = canvas.toDataURL('image/png');
        return imgData;
      }),
    );
    // eslint-disable-next-line new-cap
    const pdf: jsPDF | null = new jsPDF('portrait', 'mm', size, true);
    const filename = getFilename();
    pdf.setDocumentProperties({ title: filename, creator: 'meerkat', subject: filename });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const validImgDataList = imgDataList.filter((data) => data);
    validImgDataList.forEach((imgData, index) => {
      if (!pdf || !imgData) {
        return;
      }

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      if (validImgDataList.length - 1 > index) {
        pdf.addPage();
      }
    });
    return pdf;
  }, [elementIds, getFilename, size]);

  const downloadPdfDocument = useCallback(async () => {
    setIsProcessing(true);

    const pdf = await makePdf();
    const filename = getFilename();
    pdf.save(`${filename}.pdf`);

    setIsProcessing(false);
  }, [getFilename, makePdf]);

  // Amy: 출력이 끝났음을 의미
  const [isComplete, setIsComplte] = useState(false);
  const openFileInNewWindow = useCallback(async () => {
    setIsProcessing(true);

    const pdf = await makePdf();
    const filename = getFilename();
    pdf.output('dataurlnewwindow', { filename: `${filename}.pdf` });

    setIsProcessing(false);
    setIsComplte(true);
  }, [getFilename, makePdf]);

  return { downloadPdfDocument, openFileInNewWindow, isProcessing, isComplete };
};
