import { useMemo } from 'react';

import { addMinutes, differenceInMinutes, toDate } from 'date-fns';

import { TrendData } from '@/pages/CentralMain/organisms/TrendModal/constants';
import { DeviceModel } from '@/pages/CentralMain/types';
import { makeLocalTimeToUTC0 } from '@/pages/CentralMain/utils/convertAdmissionTime';
import { sliceUtc0StringToMinute } from '@/utils/dateTimeUtils';

// [note]
// 0. "분" 단위의 데이터를 채워넣은 결과를 리턴합니다.
// 1. dateKey - T 객체의 date 를 담고 있는 key입니다.
// 2. date 값은 utc0 string (yyyy-MM-ddTHH:mm:ss.sssZ) 형태여야 합니다.
// 3. from과 to를 포함하는 기간을 채워넣습니다.

// eslint-disable-next-line camelcase
type TrendWithDate<D extends DeviceModel> = TrendData<D> & { date_time: string };

const usePeriodFilledData = <D extends DeviceModel>(
  originalDataList: TrendWithDate<D>[],
  dateKey: keyof TrendWithDate<D>,
  period: { from: Date; to: Date },
) => {
  const filledData: TrendWithDate<D>[] = useMemo(() => {
    const start = toDate(period.from.setSeconds(0, 0));
    const end = toDate(period.to.setSeconds(0, 0));
    const length = Math.abs(differenceInMinutes(start, end)) + 1;

    const filled = Array.from({ length }, (_, index) => {
      const targetDateString = makeLocalTimeToUTC0(addMinutes(start, index));
      const targetData = originalDataList.find((data) => {
        const dateStr = data[dateKey] as string;
        return sliceUtc0StringToMinute(dateStr) === sliceUtc0StringToMinute(targetDateString);
      });
      if (targetData) {
        return { ...targetData, [dateKey]: targetDateString };
      }
      return { [dateKey]: targetDateString } as unknown as TrendWithDate<D>;
    });
    return filled;
  }, [dateKey, originalDataList, period.from, period.to]);
  return { filledData };
};

export default usePeriodFilledData;
