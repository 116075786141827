import { PropsWithChildren, memo } from 'react';

import { Box, VStack, Center } from '@chakra-ui/react';

import { useA4Page } from '@/hooks/usePdf';

interface Props {
  page?: number;
  pageId: string;
}
// report
const A4Container = ({ pageId, children, page = 1 }: PropsWithChildren<Props>) => {
  const { convertMmToPx } = useA4Page({});

  const pageWidthInMm = 210; // mm
  const pageWidthInPx = convertMmToPx(pageWidthInMm); // px
  const pageHeightInMm = 297; // mm
  const pageHeightInPx = convertMmToPx(pageHeightInMm); // px

  return (
    <Box
      bg="white"
      w={pageWidthInPx}
      h={pageHeightInPx}
      fontSize={convertMmToPx(4)}
      id={pageId}
      pos="fixed" // 화면에서 숨기기
      left={0} // 화면에서 숨기기
      top="100vh" // 화면에서 숨기기
    >
      <VStack w={pageWidthInPx} h={pageHeightInPx} pt={`${convertMmToPx(1)}px`}>
        {children}
      </VStack>
      <Center h={`${convertMmToPx(15)}px`} w="full" pos="absolute" bottom={0}>{`- ${page} -`}</Center>
    </Box>
  );
};

export default memo(A4Container);
